import { Namespace, t, TFunction, TOptions } from "i18next";
import * as Yup from "yup";

import { ISiteSurveyQuestions } from "app/components/SiteSurveyPage/SiteSurveyPage.types";
import { TranslationKeys } from "i18nConfig";

export const initialQuestionsAndAnswers: ISiteSurveyQuestions = {
    keyDetails: "",
    excitedFeatures: "",
    uniqueMerchant: "",
    meetingInPerson: "",
    independentServicer: "",
    properSignage: "",
    interiorReflectsProducts: "",
};

export const getValidationSchema = (
    t: TFunction<Namespace, undefined, Namespace>,
) =>
    Yup.object({
        keyDetails: Yup.string(),
        excitedFeatures: Yup.string(),
        uniqueMerchant: Yup.string(),
        meetingInPerson: Yup.string().required(
            t("requiredValidationMessageExtended"),
        ),
        independentServicer: Yup.string().required(
            t("requiredValidationMessageExtended"),
        ),
        properSignage: Yup.string().required(
            t("requiredValidationMessageExtended"),
        ),
        interiorReflectsProducts: Yup.string().required(
            t("requiredValidationMessageExtended"),
        ),
    });

export const getQuestionTranslation = (
    key: keyof ISiteSurveyQuestions,
    options: TOptions = {},
    isPlaceholder?: boolean,
) =>
    t(
        `siteSurveyQuestion${
            key.charAt(0).toUpperCase() +
            key.slice(1) +
            (isPlaceholder ? "Placeholder" : "")
        }` as TranslationKeys,
        options,
    );

export const renderDescription = ({
    formats,
    maxSize,
}: {
    formats?: string[];
    maxSize?: number;
}) => {
    const description = [t("siteSurveyUploadDescription")];

    if (formats?.length || maxSize) {
        const supportedFormats = formats?.length
            ? t("filesSupported", {
                  formats: formats.join(", "),
              })
            : "";
        const maxSizeText = maxSize
            ? t("fileUploadSectionMaxSize", {
                  maxSize: maxSize / 1000000,
              })
            : "";
        const combinedText = [supportedFormats, maxSizeText]
            .filter(Boolean)
            .join(", ");
        description.push(combinedText);
    }

    return description;
};
