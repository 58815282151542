import React, { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import { Form, Formik, FormikProps } from "formik";
import { colors, Text } from "spoton-lib";

import { CardBanner } from "app/icons";
import { ScrollToFieldError } from "features";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { FileUpload } from "../FileUpload";
import { StepName } from "../Stepper";
import { TextField } from "../TextField";
import {
    IManualLinkAccountForm,
    IManualLinkAccountFormData,
} from "./ManualLinkAccountForm.types";
import {
    manualLinkAccountFormikInitialData,
    manualLinkValidationSchema,
} from "./ManualLinkAccountForm.utils";

import styles from "./ManualLinkAccountForm.module.scss";

const UploadDescription = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.FileUploadTexts}>
            <Text
                type="p"
                color={colors.black}
                className={clsx(
                    styles.FileUploadTexts_title,
                    "hidden tablet:block",
                )}
            >
                {t("fileUploadSectionTitle")}
            </Text>
            <Text type="p" color={colors.black}>
                {t("fileUploadSectionSubtitle")}
            </Text>
            <Text
                type="p"
                color={colors.base70}
                className={styles.FileUploadTexts_description}
            >
                <span>{t("fileUploadSectionDescription")}</span>
                <span>{t("fileUploadSectionMaxSize", { maxSize: 10 })}</span>
            </Text>
        </div>
    );
};

export const ManualLinkAccountForm = ({
    formikStore,
    onFormSubmit,
    appStore,
}: IManualLinkAccountForm) => {
    const { t } = useTranslation();
    const ref = useRef<FormikProps<IManualLinkAccountFormData> | null>(null);

    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                billingStep: {
                    billingForm: { voidedCheckFile, fields },
                },
            },
            loading: { isDeleteCheckLoading, isSubmitPhotoCheckLoading },
        }) => {
            return {
                voidedCheckFile: voidedCheckFile,
                fields,
                isLoading: isSubmitPhotoCheckLoading || isDeleteCheckLoading,
            };
        },
    );
    const { voidedCheckFile, fields, isLoading } = getState();

    const onFileUploadChange = async (file: File) => {
        await appStore.asyncDispatch.uploadCapturePhoto(file);
        await appStore.asyncDispatch.submitPhotoCheck(false);
    };

    const onFileUploadDelete = () => {
        appStore.asyncDispatch.deleteCheck();
    };

    useEffect(() => {
        formikStore.dispatch.addRef(StepName.BillingForm, ref);
        return () => {
            formikStore.dispatch.removeRef(StepName.BillingForm);
        };
    }, []);

    const onNumberChange = <T,>(
        e: React.ChangeEvent<HTMLInputElement>,
        field: T extends Record<string, unknown> ? keyof T & string : string,
        pattern: RegExp,
    ) => {
        const value = e.target.value;
        if (value === "" || pattern.test(value)) {
            ref.current?.setFieldValue(field, value);
        } else {
            ref.current?.setFieldValue(
                field,
                ref.current?.values[field as keyof IManualLinkAccountFormData],
            );
        }
    };

    return (
        <div
            className={styles.ManualLinkAccountModal}
            id="manual-link-account-modal"
        >
            <div className={styles.ManualLinkAccountModal_content}>
                <Formik
                    innerRef={ref}
                    initialValues={{
                        ...manualLinkAccountFormikInitialData,
                        ...fields,
                    }}
                    validationSchema={() => manualLinkValidationSchema(t)}
                    onSubmit={async (values) => {
                        await onFormSubmit(values);
                    }}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <ScrollToFieldError formik={formik} />
                                <div
                                    className={
                                        styles.ManualLinkAccountForm_group
                                    }
                                >
                                    {isMobile && (
                                        <div
                                            className={
                                                styles.ManualLinkAccountForm_row
                                            }
                                        >
                                            <CardBanner
                                                className={styles.CardBanner}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className={
                                            styles.ManualLinkAccountForm_row
                                        }
                                    >
                                        <div
                                            className={
                                                styles.ManualLinkAccountForm_row
                                            }
                                        >
                                            <TextField
                                                dataDdPrivacy="mask-user-input"
                                                trim="start"
                                                className="flex-1 text-sm"
                                                formik={formik}
                                                name="routingNumber"
                                                label={t("routingNumberLabel")}
                                                value={
                                                    formik.values.routingNumber
                                                }
                                                placeholder={t(
                                                    "routingNumberPlaceholder",
                                                )}
                                                onChange={(e) =>
                                                    onNumberChange<IManualLinkAccountFormData>(
                                                        e,
                                                        "routingNumber",
                                                        /^\d{0,9}$/,
                                                    )
                                                }
                                                inputMode="numeric"
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.ManualLinkAccountForm_row
                                            }
                                        >
                                            <TextField
                                                dataDdPrivacy="mask-user-input"
                                                trim="start"
                                                className="flex-1 text-sm [&_input]:pr-2"
                                                formik={formik}
                                                name="accountNumber"
                                                label={t("accountNumberLabel")}
                                                value={
                                                    formik.values.accountNumber
                                                }
                                                placeholder={t(
                                                    "accountNumberPlaceholder",
                                                )}
                                                onChange={(e) =>
                                                    onNumberChange<IManualLinkAccountFormData>(
                                                        e,
                                                        "accountNumber",
                                                        /^\d{0,17}$/,
                                                    )
                                                }
                                                inputMode="numeric"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            styles.ManualLinkAccountForm_row
                                        }
                                    >
                                        {!isMobile && (
                                            <CardBanner
                                                className={styles.CardBanner}
                                            />
                                        )}
                                        <div
                                            className={
                                                styles.ManualLinkAccountForm_column
                                            }
                                        >
                                            <TextField
                                                dataDdPrivacy="mask-user-input"
                                                trim="start"
                                                className="flex-1 text-sm"
                                                formik={formik}
                                                name="accountName"
                                                label={t("businessNameLabel")}
                                                value={
                                                    formik.values.accountName
                                                }
                                            />
                                            <TextField
                                                dataDdPrivacy="mask-user-input"
                                                trim="start"
                                                className="flex-1 text-sm"
                                                formik={formik}
                                                name="bankName"
                                                label={t("bankNameLabel")}
                                                value={formik.values.bankName}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
                <div className="tablet:pt-6 pt-10">
                    <FileUpload
                        file={voidedCheckFile}
                        onChange={onFileUploadChange}
                        onDelete={onFileUploadDelete}
                        description={<UploadDescription />}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
};
