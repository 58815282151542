import moment from "moment";

import {
    IApplicantStep,
    IOwnerType,
    OwnerStatus,
} from "features/application-wizard";
import { DateFormat, StepName, StepStatus } from "features/common";
import {
    API_ENDPOINTS,
    convertPhoneNumberToUsFormat,
    httpCore,
} from "features/utils";

import { IAppStateType } from "../AppStore.types";
import { defaultOwner, defaultReducer } from "../defaultState";
import { getStepperState } from "../helpers";

// clean the data for incoming and outgoing ApplicantStep to match format of BE
export function cleanApplicantStep(
    data: IApplicantStep,
    isOutbound = false,
): IApplicantStep {
    const applicantStep = { ...data } as IApplicantStep;
    const applicant = { ...applicantStep.applicant };

    if (applicant.dob) {
        applicant.dob = applicant.dob.replace(/-/g, "/");
        applicant.dob = moment(new Date(`${applicant.dob} GMT-0800`)).format(
            isOutbound ? DateFormat.YYYY_MM_DD : DateFormat.MM_DD_YYYY,
        );
    }
    const applicantPhone = applicant.contact.phone;

    if (applicantPhone) {
        applicant.contact.phone =
            convertPhoneNumberToUsFormat(applicantPhone) || "";
    }

    return {
        ...applicantStep,
        applicant,
    };
}

function resolveOwnerStatus(applicant: IOwnerType): OwnerStatus {
    // determine ownerStatus from applicant data
    if (applicant.isOwner && applicant.ownershipPercentage === 100) {
        return OwnerStatus.Owner;
    } else if (!applicant.isOwner) {
        return OwnerStatus.ResponsibleParty;
    } else {
        return OwnerStatus.PartOwner;
    }
}

export const applicantStepReducers = {
    setApplicantStep(state: IAppStateType, applicantStep: IApplicantStep) {
        if (applicantStep.ownerStatus === OwnerStatus.Owner) {
            applicantStep.applicant.ownershipPercentage = 100;
        }

        return {
            ...state,
            applicantStep: {
                ...state.applicantStep,
                ...applicantStep,
            },
        };
    },
};

export const applicantStepAsyncReducers = {
    async getApplicantStep(currentStep: IAppStateType) {
        // fetch applicant
        const response = await httpCore.get(API_ENDPOINTS.signup.ApplicantStep);
        if (response.status !== 200) return defaultReducer;

        if (response.data) {
            const unformattedApplicant = response.data as IOwnerType;

            const { ssn } = response.data;

            const applicant: IOwnerType = {
                ...unformattedApplicant,
                ssn: ssn,
                ssnNotFormatted: ssn,
                contact: {
                    ...unformattedApplicant.contact,
                    phone: convertPhoneNumberToUsFormat(
                        unformattedApplicant.contact.phone,
                    ),
                },
            };

            return (state: IAppStateType) => ({
                ...state,
                applicantStep: cleanApplicantStep({
                    ...state.applicantStep,
                    ownerStatus: resolveOwnerStatus(applicant),
                    applicant,
                }),
            });
        } else {
            return (state: IAppStateType) => ({
                ...state,
                applicantStep: {
                    ...state.applicantStep,
                    ownerStatus: OwnerStatus.Owner,
                    applicant: defaultOwner(),
                },
            });
        }
    },

    async updateApplicantStep(
        currentState: IAppStateType,
        data: IApplicantStep,
    ) {
        const applicantStepData = cleanApplicantStep({ ...data }, true);

        const { applicant, ownerStatus } = applicantStepData;

        if (applicantStepData.applicant.ssnNotFormatted) {
            delete applicant.ssnNotFormatted;
        }

        // setting isApplicant flag
        applicant.isApplicant = true;

        // setting isOwner flag
        applicant.isOwner =
            ownerStatus === OwnerStatus.Owner ||
            ownerStatus === OwnerStatus.PartOwner;

        const payload = {
            applicant,
            stepName: StepName.Applicant,
            stepStatus: StepStatus.Complete,
        } satisfies IApplicantStep;

        try {
            const response = await httpCore.post(
                API_ENDPOINTS.signup.ApplicantStep,
                payload,
            );
            const { stepper } = response.data;

            return (state: IAppStateType) => ({
                ...state,
                ...getStepperState({ state, stepper }),
                applicantStep: cleanApplicantStep({
                    ...state.applicantStep,
                    ...applicantStepData,
                    stepStatus: StepStatus.Complete,
                }),
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
};
