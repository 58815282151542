import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

export const useScrollTracker = (
    isTermsScrolled: boolean,
    setIsTermsScrolled: (v: boolean) => void,
) => {
    const termsRef = useRef<HTMLDivElement>(null);
    const [gradientOpacity, setGradientOpacity] = useState(0.06);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { ref, inView } = useInView({
        threshold: 1.0,
    });

    useEffect(() => {
        const handleScroll = () => {
            if (termsRef.current) {
                const { scrollTop, clientHeight, scrollHeight } =
                    termsRef.current;
                const isScrolled = scrollTop + clientHeight + 30 > scrollHeight;
                const scrollProgress =
                    (scrollTop + clientHeight) / scrollHeight;
                const newGradientOpacity = Math.max(
                    0,
                    Math.min(1, scrollProgress * 0.1),
                );
                if (isScrolled) {
                    setIsTermsScrolled(isScrolled);
                }
                setGradientOpacity(newGradientOpacity);
            }
        };

        if (termsRef.current) {
            termsRef.current.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (termsRef.current) {
                termsRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, [termsRef]);

    return {
        termsRef,
        isTermsScrolled,
        gradientOpacity,
        inView,
        ref,
    };
};
