import React, { useEffect, useState } from "react";

import { StepPageLayout } from "app/components";
import { IPageComponentProps } from "app/types";
import { API_ENDPOINTS, Breakpoint, httpCore, useBreakpoint } from "features";
import {
    FasterFundingEnroll,
    FasterFundingSuccess,
} from "features/application-wizard";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

export const FasterFundingPage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();

    const getState = useDerivedState(
        appStore,
        ({
            data: { fasterFundingStep },
            loading: { isGetFasterFundingStepLoading },
        }) => ({
            isLoading: isGetFasterFundingStepLoading,
            fasterFundingStep,
        }),
    )[0];
    const { isLoading, fasterFundingStep } = getState();
    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);
    const [isAstraModalOpen, setIsAstraModalOpen] = useState(false);
    const [isEnrollButtonLoading, setIsEnrollButtonLoading] = useState(false);
    const [astraAddCardLink, setAstraAddCardLink] = useState("");
    const [shouldShowSkipModal, setShouldShowSkipModal] =
        useState<boolean>(false);

    useEffect(() => {
        appStore.asyncDispatch.getFasterFundingStep();
    }, []);

    const fetchAstraCardLink = async () => {
        try {
            const response = await httpCore.post(
                API_ENDPOINTS.signup.FasterFunding,
            );
            const link = response.data.link;
            setAstraAddCardLink(link);
            return link;
        } catch (err) {
            console.error(err);
            setIsEnrollButtonLoading(false);
            throw err;
        }
    };

    const enrollMerchantAndDisplayAddCardModal = async () => {
        setIsEnrollButtonLoading(true);
        const link = await fetchAstraCardLink();
        // until better way to display Astra iframe in modal is found
        // we open Astra in new tab. In order to restore modal solution
        // remove the line below and uncomment code below it
        window.location.href = link;
        // setIsAstraModalOpen(true);
        // setIsEnrollButtonLoading(false);
    };

    return fasterFundingStep.card ? (
        <StepPageLayout
            appStore={appStore}
            id="faster-funding-step"
            title={t("fasterFundingAlreadyEnrolledStepHeader")}
            isLoading={isLoading}
            formikStore={formikStore}
            width="narrow"
            className="[&>*>.StepContentContainer>.StepWrapper>.TitlesAndContent>.StepContent]:pb-2"
            customTitleStyles="text-3xl leading-[45px]"
        >
            <FasterFundingSuccess />
        </StepPageLayout>
    ) : (
        <StepPageLayout
            appStore={appStore}
            id="faster-funding-step"
            title={t("fasterFundingStepHeader")}
            isLoading={isLoading}
            customPrimaryFooterButton={{
                label: t("fasterFundingEnrollButton"),
                onClick: () => enrollMerchantAndDisplayAddCardModal(),
                isLoading: isEnrollButtonLoading,
            }}
            customSecondaryFooterButton={{
                label: t("fasterFundingSkipButton"),
                onClick: () => setShouldShowSkipModal(true),
            }}
            formikStore={formikStore}
            width="wide"
            isFooterHidden={!isLowerThanTablet}
            className="[&>*>.StepContentContainer>.StepWrapper>.TitlesAndContent>.StepContent]:pb-2"
            customTitleStyles="text-3xl tablet:text-5xl leading-10 tablet:leading-[72px] mr-0 tablet:mr-[20rem] desktop:mr-[40rem]"
        >
            <FasterFundingEnroll
                appStore={appStore}
                formikStore={formikStore}
                isAstraModalOpen={isAstraModalOpen}
                setIsAstraModalOpen={setIsAstraModalOpen}
                isEnrollButtonLoading={isEnrollButtonLoading}
                astraLink={astraAddCardLink}
                onEnrollMerchant={enrollMerchantAndDisplayAddCardModal}
                setShouldShowSkipModal={setShouldShowSkipModal}
                shouldShowSkipModal={shouldShowSkipModal}
            />
        </StepPageLayout>
    );
};
