import { useEffect, useRef, useState } from "react";

import {
    AnyAsyncReducersType,
    AnyReducersType,
    AnyStateType,
    Store,
} from "store/store";

export function shouldUpdate<S>(currentState: S, newState: Partial<S>) {
    return Object.keys(newState).some((key) => {
        const prop = key as keyof typeof newState;
        return currentState[prop] !== newState[prop];
    });
}

// Takes a store and a function which returns a derived object from that store's state.
// Provides two functions to component which listen to changes from the store
// * getState() - get the most current value of the derived object
// * setState() - set the current value of the derived object
export function useDerivedState<
    S,
    R extends AnyReducersType<S>,
    A extends AnyAsyncReducersType<S>,
    T,
>(
    appStore: Store<S, R, A>,
    deriveStateFromStore: (
        appState: AnyStateType<S, A>,
        componentState: Partial<T>,
    ) => T,
): [() => T, (newState: Partial<T>) => void] {
    const defaultState = deriveStateFromStore(appStore.state, {});
    const [state, setState] = useState(defaultState);
    const stateRef = useRef(state);
    stateRef.current = state;
    // return functions
    const getDerivedState = () => stateRef.current;
    const setDerivedState = (newState: Partial<T>) => {
        setState((prevState) => ({ ...prevState, ...newState }));
    };

    useEffect(
        () =>
            appStore.addListener((appState: AnyStateType<S, A>) => {
                const derivedState = deriveStateFromStore(
                    appState,
                    stateRef.current,
                );
                // only setState for the component using this hook if its derivedState has changed
                if (shouldUpdate(stateRef.current, derivedState))
                    setDerivedState(derivedState);
            }),
        [],
    );

    return [getDerivedState, setDerivedState];
}
