import React, { useEffect } from "react";

import { PrintIcon } from "app/icons";
import { IPageComponentProps } from "app/types";
import { TermsAndConditions } from "features/application-wizard";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { StepPageLayout } from "../StepPageLayout";

import styles from "./TermsAndConditionsPage.module.scss";

export const TermsAndConditionsPage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();

    const getState = useDerivedState(
        appStore,
        ({
            loading: {
                isGetTCHtmlLoading,
                isGetBusinessStepLoading,
                isGetApplicantStepLoading,
            },
        }) => ({
            isLoading:
                isGetTCHtmlLoading ||
                isGetBusinessStepLoading ||
                isGetApplicantStepLoading,
        }),
    )[0];
    const { isLoading } = getState();
    const printTC = () => {
        const newTab = window.open("", "_blank");
        if (newTab) {
            const { tcHtml } = appStore.getState().data.termsAndConditionsStep;
            newTab.document.write(tcHtml);
        }
    };

    // Get data for Terms and conditions
    useEffect(() => {
        (async () => {
            await Promise.all([
                appStore.asyncDispatch.getTCHtml(),
                appStore.asyncDispatch.getBusinessStep(),
                appStore.asyncDispatch.getApplicantStep(),
            ]);
        })();
    }, []);

    return (
        <StepPageLayout
            appStore={appStore}
            className={styles.ContentWrapper}
            id="terms-and-conditions"
            isLoading={isLoading}
            customPrimaryFooterButton={{ label: t("termsAndConditionsSign") }}
            title={t("termsAndConditionsTitle")}
            width="medium"
            formikStore={formikStore}
            customTitleIcon={<PrintIcon onClick={printTC} />}
        >
            <TermsAndConditions appStore={appStore} formikStore={formikStore} />
        </StepPageLayout>
    );
};
