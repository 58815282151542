import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import { Form, Formik, FormikProps } from "formik";
import { TFunction } from "i18next";
import { colors, Icon, IconButton, showToast, Text, Tooltip } from "spoton-lib";
import * as Yup from "yup";

import { IPageComponentProps } from "app/types";
import {
    RadioField,
    ResponsiblePartyInfoModal,
    ScrollToFieldError,
    StepName,
} from "features/common";
import { useTranslation } from "i18nConfig";
import { Namespace } from "i18nConfig/translation.types";
import { useDerivedState } from "store/hooks";

import { ownerValidationSchema } from "../common/owners.utils";
import { IApplicantStep, OwnerStatus } from "./ApplicantForm.types";
import { ApplicantItem } from "./ApplicantItem";

import styles from "./ApplicantForm.module.scss";

// schema for entire form
export const applicantStepValidationSchema = (
    t: TFunction<Namespace, undefined, Namespace>,
    percentageInOwnersStep: number,
    ownerStatus?: OwnerStatus,
) =>
    Yup.object().shape({
        ownerStatus: Yup.string().required(t("requiredValidationMessage")),
        applicant: ownerValidationSchema({
            t,
            percentageFromOtherStep: percentageInOwnersStep,
            isAdditionalOwners: false,
            ownerStatus,
        }),
    });

export const ApplicantForm = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const [
        shouldShowResponsiblePartyInfoModal,
        setShouldShowResponsiblePartyInfoModal,
    ] = useState<boolean>(false);

    const { t } = useTranslation();

    // create formik ref and add to formikStore
    const formRef = useRef<FormikProps<IApplicantStep> | null>(null);
    useEffect(() => {
        formikStore.dispatch.addRef(StepName.Applicant, formRef);
        return () => {
            formikStore.dispatch.removeRef(StepName.Applicant);
        };
    }, []);

    // get applicantStep from appStore and save to componentState
    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                applicantStep,
                ownersStep,
                profile: { firstName },
            },
        }) => {
            return {
                applicantStep,
                ownersStep,
                firstName,
            };
        },
    );
    const { applicantStep, ownersStep } = getState();
    const { ownerStatus, applicant } = applicantStep;

    const [submitSSNValue, setSubmitSSNValue] = useState<string>(
        applicant?.ssnNotFormatted || "",
    );

    const percentageInOwnersStep = ownersStep.owners
        .filter((owner) => !owner.isDeleted)
        .reduce((acc, obj) => {
            const percentage = obj.ownershipPercentage || 0;
            return acc + percentage;
        }, 0);

    const onOwnerStatusChange = (ownerStatus: OwnerStatus) => () => {
        const applicantStep = {
            ...formRef.current?.values,
            ownerStatus,
        } as IApplicantStep;
        if (ownerStatus === OwnerStatus.ResponsibleParty) {
            applicantStep.applicant.isResponsibleParty = true;
        }
        appStore.dispatch.setApplicantStep(applicantStep);
    };

    const onSubmit = async (values: IApplicantStep) => {
        const { applicant } = values;
        if (
            applicant.ownershipPercentage &&
            applicant.ownershipPercentage > 75 &&
            !applicant.isResponsibleParty
        ) {
            showToast({
                variant: "danger",
                content: t("youMustDesignateAuthorizedPerson"),
            });
            return;
        }
        const updatedValues: IApplicantStep = {
            ...values,
            applicant: { ...applicant, ssn: submitSSNValue },
        };

        await appStore.asyncDispatch.updateApplicantStep(updatedValues);
    };

    // update Formik values when applicantStep is updated,
    // (normally would use enableReinitialize, but this happens too often)
    useEffect(() => {
        formRef.current?.setValues(applicantStep);
    }, [applicantStep]);

    return (
        <div className={clsx(styles.ApplicantForm)}>
            <Formik
                innerRef={formRef}
                initialValues={applicantStep}
                validationSchema={() =>
                    applicantStepValidationSchema(
                        t,
                        percentageInOwnersStep,
                        ownerStatus,
                    )
                }
                onSubmit={async (values: IApplicantStep) => {
                    await onSubmit(values);
                }}
            >
                {(formik: FormikProps<IApplicantStep>) => {
                    return (
                        <Form>
                            <ScrollToFieldError formik={formik} />
                            <div
                                className={clsx(
                                    styles.ApplicantForm_radioSection,
                                )}
                            >
                                <RadioField
                                    formik={formik}
                                    className={clsx(styles.ApplicantForm_radio)}
                                    name="ownerStatus"
                                    label={t("fullOwnerText")}
                                    checked={
                                        formik.values.ownerStatus ===
                                        OwnerStatus.Owner
                                    }
                                    value={OwnerStatus.Owner}
                                    onChange={onOwnerStatusChange(
                                        OwnerStatus.Owner,
                                    )}
                                />
                                <RadioField
                                    formik={formik}
                                    className={clsx(styles.ApplicantForm_radio)}
                                    name="ownerStatus"
                                    label={t("partOwnerText")}
                                    checked={
                                        formik.values.ownerStatus ===
                                        OwnerStatus.PartOwner
                                    }
                                    value={OwnerStatus.PartOwner}
                                    onChange={onOwnerStatusChange(
                                        OwnerStatus.PartOwner,
                                    )}
                                />
                                <div
                                    className={clsx(
                                        styles.ApplicantForm_radioWithTooltip,
                                    )}
                                >
                                    <RadioField
                                        formik={formik}
                                        className={clsx(
                                            styles.ApplicantForm_radio,
                                        )}
                                        name="ownerStatus"
                                        label={t("responsiblePartyText")}
                                        checked={
                                            formik.values.ownerStatus ===
                                            OwnerStatus.ResponsibleParty
                                        }
                                        value={OwnerStatus.ResponsibleParty}
                                        onChange={onOwnerStatusChange(
                                            OwnerStatus.ResponsibleParty,
                                        )}
                                    />
                                    {isMobile ? (
                                        <>
                                            <IconButton
                                                name="InformativeIcon"
                                                size={21}
                                                onClick={() => {
                                                    setShouldShowResponsiblePartyInfoModal(
                                                        true,
                                                    );
                                                }}
                                                disableBorder
                                                variant="secondary"
                                                color={colors.informative50}
                                                className={clsx(
                                                    styles.ApplicantForm_icon,
                                                )}
                                                buttonProps={{
                                                    type: "button",
                                                }}
                                                data-testid="responsible-party-info-btn"
                                            />
                                            <ResponsiblePartyInfoModal
                                                shouldShowResponsiblePartyInfoModal={
                                                    shouldShowResponsiblePartyInfoModal
                                                }
                                                setShouldShowResponsiblePartyInfoModal={
                                                    setShouldShowResponsiblePartyInfoModal
                                                }
                                            />
                                        </>
                                    ) : (
                                        <Tooltip
                                            tooltipContent={t(
                                                "responsiblePartyTooltip",
                                            )}
                                            variant="rightBottom"
                                            data-testid="responsible-party-info-tooltip"
                                            className={clsx(
                                                styles.ApplicantForm_tooltip,
                                            )}
                                        >
                                            <Icon
                                                name="InformativeIcon"
                                                data-testid="responsible-party-info-icon"
                                                size={18}
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                            {ownerStatus && (
                                <div>
                                    <Text
                                        type="h5"
                                        className={clsx(
                                            styles.ApplicantForm_detailsHeader,
                                        )}
                                    >
                                        {t("confirmPersonalDetails")}
                                    </Text>
                                    <ApplicantItem
                                        formik={formik}
                                        showOwnership={[
                                            OwnerStatus.PartOwner,
                                            OwnerStatus.ResponsibleParty,
                                        ].includes(ownerStatus)}
                                        showAuthorizedCheckbox={
                                            ownerStatus !==
                                            OwnerStatus.ResponsibleParty
                                        }
                                        setSubmitSSNValue={setSubmitSSNValue}
                                    />
                                </div>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
