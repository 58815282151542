import React, { useRef, useState } from "react";
import { useAsyncValue } from "react-router-dom";
import { AxiosResponse } from "axios";
import { FormikProps } from "formik";

import { Breakpoint, useBreakpoint } from "features";
import { SiteSurvey } from "features/site-survey/components/SiteSurvey";
import { useTranslation } from "i18nConfig";

import { StatelessPageLayout } from "../PageLayout";
import { StatelessStepPageLayout } from "../StepPageLayout";
import { SiteSurveySuccessPage } from "./components/SiteSurveySuccessPage";
import { SiteSurveyWelcomePage } from "./components/SiteSurveyWelcomePage";
import { ISiteSurveyQuestions, SiteSurveyDTO } from "./SiteSurveyPage.types";
import { SiteSurveyContext } from "./SiteSurveyPage.utils";

export const SiteSurveyPage = () => {
    const { t } = useTranslation();
    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);
    const formRef = useRef<FormikProps<ISiteSurveyQuestions>>(null);

    const siteSurveyResponse = useAsyncValue() as AxiosResponse<SiteSurveyDTO>;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [shouldShowSurveyForm, setShouldShowSurveyForm] = useState(false);
    const [siteSurveyData, setSiteSurveyData] = useState<SiteSurveyDTO>(
        siteSurveyResponse.data,
    );

    const setSurveyCompleted = () => {
        setSiteSurveyData({ ...siteSurveyData, surveyCompleted: true });
    };

    const handleSubmit = async () => {
        if (formRef.current) {
            await formRef.current.submitForm();
        }
    };

    return (
        <SiteSurveyContext.Provider value={siteSurveyResponse.data}>
            <StatelessPageLayout rightText={siteSurveyData.businessName}>
                {siteSurveyData.surveyCompleted ? (
                    <SiteSurveySuccessPage />
                ) : (
                    <StatelessStepPageLayout
                        title={
                            shouldShowSurveyForm
                                ? t("siteSurveyTitle")
                                : undefined
                        }
                        width={shouldShowSurveyForm ? "narrow" : "wide"}
                        isFooterHidden={
                            !shouldShowSurveyForm && !isLowerThanTablet
                        }
                        customPrimaryFooterButton={{
                            onClick: shouldShowSurveyForm
                                ? handleSubmit
                                : () => setShouldShowSurveyForm(true),
                            isLoading: isSubmitting,
                            label: shouldShowSurveyForm
                                ? t("complete")
                                : t("getStartedButton"),
                        }}
                    >
                        {shouldShowSurveyForm ? (
                            <SiteSurvey
                                ref={formRef}
                                onSurveyCompleted={setSurveyCompleted}
                                onSubmitting={setIsSubmitting}
                            />
                        ) : (
                            <SiteSurveyWelcomePage
                                onContinue={() => setShouldShowSurveyForm(true)}
                            />
                        )}
                    </StatelessStepPageLayout>
                )}
            </StatelessPageLayout>
        </SiteSurveyContext.Provider>
    );
};
