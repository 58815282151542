import React from "react";

import { WarningIcon } from "app/icons";
import { useTranslation } from "i18nConfig";

export const NoAccessInfo = () => {
    const { t } = useTranslation();
    return (
        <div className="text-white flex flex-col gap-2 px-[38px] text-center">
            <h2 className="font-semibold">{t("pleaseAllowAccessToCamera")}</h2>
            <span className="text-sm">{t("openBrowserMessage")}</span>
        </div>
    );
};

export const MissingCameraInfo = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center gap-2 w-[328px] px-4 text-center">
            <span className="text-spoton-warning-50 font-semibold flex items-center gap-2">
                <WarningIcon />
                <span>{t("cantFindYourCamera")}</span>
            </span>
            <span className="text-white text-sm">
                {t("checkYourSystemSettings")}
            </span>
        </div>
    );
};

export const PlaceCameraInfo = () => {
    const { t } = useTranslation();
    return (
        <span className="text-white font-semibold animate-fade-out opacity-0">
            {t("takeAPhotoInfo")}
        </span>
    );
};
