/* eslint-disable react/display-name */
import axios from "axios";

export type TupleRest<T extends unknown[]> = T extends [any, ...infer U]
    ? U
    : never;

export type AnyReducersType<S> = {
    [key: string]: (state: S, ...args: any[]) => S;
};

export type AnyAsyncReducersType<S> = {
    [key: string]: (state: S, ...args: any[]) => Promise<(s: S) => S>;
};

export type AnyDispatch<S, R extends AnyReducersType<S>> = {
    [K in keyof R]: (...args: TupleRest<Parameters<R[K]>>) => S;
};

export type AnyAsyncDispatch<S, A extends AnyAsyncReducersType<S>> = {
    [K in keyof A]: (...args: TupleRest<Parameters<A[K]>>) => Promise<S>;
};

type WithErrorKeys<T> = {
    [P in keyof T & string as `${P}Error`]: T[P];
};
type WithLoadingKeys<T> = {
    [P in keyof T & string as `is${Capitalize<P>}Loading`]: T[P];
};

export type AnyError<A> = { [K in keyof WithErrorKeys<A>]: any };

export type AnyLoading<A> = { [K in keyof WithLoadingKeys<A>]: boolean };

export type AnyStateType<S, A extends AnyAsyncReducersType<S>> = {
    data: S;
    loading: AnyLoading<A>;
    error: AnyError<A>;
};

function getLoadingKey<A>(key: keyof A): keyof WithLoadingKeys<A> {
    const k = (String(key)[0].toUpperCase() +
        String(key).slice(1)) as Capitalize<Extract<keyof A, string>>;
    return `is${k}Loading` as keyof WithLoadingKeys<A>;
}

function getErrorKey<A>(key: keyof A): keyof WithErrorKeys<A> {
    const k = String(key) as keyof A & string;
    return `${k}Error` as keyof WithErrorKeys<A>;
}

export class Store<
    S,
    R extends AnyReducersType<S>,
    A extends AnyAsyncReducersType<S>,
> {
    state = {} as AnyStateType<S, A>;
    dispatch = {} as AnyDispatch<S, R>;
    asyncDispatch = {} as AnyAsyncDispatch<S, A>;

    constructor(
        private _initialData: S,
        private _reducers: R,
        private _asyncReducers: A,
    ) {
        this._buildState();
        this._buildReducers();
        this._buildAsyncReducers();
    }

    private _buildState = () => {
        const data = this._initialData;
        const loading = {} as AnyLoading<A>;
        const error = {} as AnyError<A>;
        let key: keyof A;
        for (key in this._asyncReducers) {
            loading[getLoadingKey(key)] = false;
            error[getErrorKey(key)] = null;
        }

        this.state = {
            data,
            error,
            loading,
        };
    };

    private _buildReducers = () => {
        let key: keyof R;
        for (key in this._reducers) {
            const k = key;
            this.dispatch[k] = (...args: any[]): S => {
                const reducer = this._reducers[k];
                if (reducer) {
                    this.state = {
                        ...this.state,
                        data: reducer.call(
                            this._reducers,
                            this.state.data,
                            ...args,
                        ),
                    };
                    this._emit();
                }
                return this.state.data;
            };
        }
    };

    private _buildAsyncReducers() {
        let key: keyof A;
        for (key in this._asyncReducers) {
            const k = key;
            this.asyncDispatch[k] = async (...args: any[]): Promise<S> => {
                const asyncReducer = this._asyncReducers[k];
                const loadingKey = getLoadingKey(k);
                const errorKey = getErrorKey(k);

                if (asyncReducer) {
                    // reset loading/error and emit to listeners
                    this.state.loading[loadingKey] = true;
                    this.state.error[errorKey] = null;
                    this._emit();

                    // perform async action
                    try {
                        // we return a transform function from the reducer
                        // since it is async, this allows us to merge the result
                        // with the most recent version of this.state
                        const transformFn = await asyncReducer.call(
                            this._asyncReducers,
                            this.state.data,
                            ...args,
                        );
                        this.state = {
                            ...this.state,
                            data: transformFn(this.state.data),
                        };
                    } catch (err) {
                        if (axios.isAxiosError(err)) {
                            this.state.error[errorKey] = err.response?.data;
                        } else {
                            this.state.error[errorKey] = err;
                        }
                    }

                    // emit to listeners again after result
                    this.state.loading[loadingKey] = false;
                    this._emit();

                    // if error occurred, reject the promise for .catch chains
                    if (this.state.error[errorKey] !== null)
                        return Promise.reject(this.state.error[errorKey]);
                }

                // return the data to be used in .then chains
                return Promise.resolve(this.state.data);
            };
        }
    }

    // if you don't want to use reducers, call setState directly on the store
    public setState = (nextState: AnyStateType<S, A>) => {
        this.state = nextState;
        this._emit();
    };

    public getState = () => {
        return this.state;
    };

    private _listeners = new Set<(state: AnyStateType<S, A>) => void>();

    public addListener(fn: (state: AnyStateType<S, A>) => void): () => void {
        this._listeners.add(fn);
        return () => {
            this._listeners.delete(fn);
            return null;
        };
    }

    private _emit() {
        this._listeners.forEach((fn) => fn(this.state));
    }
}
