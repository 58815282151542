import axios from "axios";

import { IConfigDTO } from "features/common";
import { API_ENDPOINTS } from "features/utils/apiEndpoints";
import { httpCore } from "features/utils/axios.utils";

export const fetchConfig = async (
    token?: string | null,
): Promise<IConfigDTO> => {
    try {
        const params = token ? { token } : undefined;
        const { data } = await httpCore.get<IConfigDTO>(
            API_ENDPOINTS.common.Config,
            {
                params: params,
            },
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        }
        throw error;
    }
};
