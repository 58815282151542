/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import clsx from "clsx";
import { useField } from "formik";
import { NumberFormat } from "spoton-lib";

import { INumberFieldProps } from "./NumberField.types";

import styles from "./NumberField.module.scss";

export function NumberField<T>(props: INumberFieldProps<T>) {
    const {
        name,
        label,
        className,
        formik: { handleBlur, setFieldValue },
        value,
        onChange = () => ({}),
        onBlur = () => ({}),
        onFocus = () => ({}),
        isDisabled,
        isValid,
        isClearable,
        primaryCondition,
        errorMessage,
        placeholder,
        id,
        allowNegative,
        allowLeadingZeros = false,
        decimalScale,
        minLength,
        maxLength,
        prefix,
        suffix,
        hideErrorMessage,
        parseToFloat = true,
        allowedDecimalSeparators = [".", ","],
    } = props;

    const [field, meta] = useField(name);

    const onFocusField = (e: React.SyntheticEvent<Element, Event>) => {
        onFocus(e);
    };

    const onBlurField = (e: React.SyntheticEvent<Element, Event>) => {
        handleBlur(e);
        onBlur(e);
    };

    const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e);
    };

    /**
     *  Clearable button does not trigger onChange which means form value is not updated.
     *  It triggers onValueChange so we need to set the form value by ourselves.
     *  Hopefully, it is just a temporary solution as all the other
     *  fields from spoton-lib do trigger onChange on clear.
     * */
    const onValueChangeField = async ({
        value,
        floatValue,
    }: {
        value: string;
        floatValue: number | undefined;
    }) => {
        if (parseToFloat) {
            const newValue = value ? floatValue : "";
            await setFieldValue(name, newValue);
        } else {
            await setFieldValue(name, value);
        }
    };

    return (
        <NumberFormat
            {...field}
            id={id || `${name}-field`}
            value={value}
            allowedDecimalSeparators={allowedDecimalSeparators}
            onChange={onChangeField}
            onValueChange={onValueChangeField}
            onBlur={onBlurField}
            onFocus={onFocusField}
            className={clsx(className, styles.NumberField)}
            label={label}
            suffix={suffix || ""}
            isValid={
                isValid !== undefined
                    ? isValid
                    : meta.touched
                    ? !meta.error && meta.error !== ""
                    : true
            }
            disabled={isDisabled}
            primaryCondition={primaryCondition}
            secondaryCondition={
                !hideErrorMessage
                    ? errorMessage
                        ? errorMessage
                        : (meta.touched && meta.error) || ""
                    : ""
            }
            secondaryConditionClassName={styles.NumberField_errorMessage}
            isMultilineSecondaryCondition={true}
            clearable={isClearable}
            placeholder={placeholder}
            allowNegative={allowNegative}
            allowLeadingZeros={allowLeadingZeros}
            decimalScale={decimalScale}
            minLength={minLength}
            maxLength={maxLength}
            prefix={prefix || ""}
            data-testid={id || name}
        />
    );
}

export default NumberField;
