export const TextAreaFields = [
    "keyDetails",
    "excitedFeatures",
    "uniqueMerchant",
] as const;

export const YesNoFields = [
    "meetingInPerson",
    "independentServicer",
    "properSignage",
    "interiorReflectsProducts",
] as const;
