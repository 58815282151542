import { TFunction } from "i18next";
import * as Yup from "yup";

import { Namespace } from "i18nConfig";

export const getTermsAndConditionsValidation = (
    t: TFunction<Namespace, undefined, Namespace>,
    shouldValidate: boolean,
    isTermsScrolled: boolean,
) => {
    return Yup.object().shape({
        agreeToTermsAndConditions: Yup.boolean().oneOf(
            [true],
            isTermsScrolled
                ? t("termsAndConditionsAgreeError")
                : t("termsAndConditionsScrollAndReadError"),
        ),
        trimmedDataURL: Yup.boolean().oneOf(
            [true],
            t("termsAndConditionsSignatureError"),
        ),
    });
};
