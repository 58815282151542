import React from "react";
import clsx from "clsx";

const Corner = (props: React.HTMLAttributes<SVGSVGElement>) => (
    <svg
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <line
            x1="3"
            y1="3"
            x2="3"
            y2="49"
            stroke="white"
            strokeWidth="6"
            strokeLinecap="round"
        />
        <line
            x1="49"
            y1="3"
            x2="3"
            y2="3"
            stroke="white"
            strokeWidth="6"
            strokeLinecap="round"
        />
    </svg>
);

export const Frame = ({
    // eslint-disable-next-line react/prop-types
    className,
    children,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <div
            {...props}
            className={clsx(
                className,
                "grid grid-cols-2 grid-rows-2 w-full h-full relative",
            )}
        >
            <div className="flex w-full">
                <Corner className="" />
            </div>
            <div className="flex justify-end w-full">
                <Corner className="rotate-90" />
            </div>
            <div className="flex w-full items-end">
                <Corner className="-rotate-90" />
            </div>
            <div className="flex justify-end w-full items-end">
                <Corner className="rotate-180" />
            </div>
            <div className="absolute w-full h-full left-0 top-0 flex items-center justify-center">
                {children}
            </div>
        </div>
    );
};
