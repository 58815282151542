import { AxiosRequestConfig } from "axios";

import { httpFileCore } from "features/utils";

export const srcToFile = async (
    src?: string,
    config?: AxiosRequestConfig,
    id?: string,
) => {
    if (src) {
        try {
            const response = await httpFileCore.get(src, {
                responseType: "blob",
                ...config,
            });
            const blob = await response.data;
            const fileExtensionFromType = blob.type.split("/")[1];

            if (response.status === 204) {
                return null;
            }

            const filename = id
                ? `check-${id}.${fileExtensionFromType}`
                : `check.${fileExtensionFromType}`;
            return new File([blob], filename, {
                type: blob.type,
            });
        } catch (error) {
            return null;
        }
    }
    return null;
};
