import React, { useEffect } from "react";
import clsx from "clsx";

// types
import { IPageComponentProps } from "app/types";
import { useStepNavigate } from "features";
import {
    HelloSignContainer,
    SignatureSubmitStatus,
} from "features/application-wizard";
// translation
import { useTranslation } from "i18nConfig";
// hooks
import { useDerivedState } from "store/hooks";

import { StepPageLayout } from "../StepPageLayout";

// styles
import styles from "./SignaturePage.module.scss";

export const SignaturePage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();
    const getState = useDerivedState(
        appStore,
        ({
            error: { completeSignatureStepError },
            loading: {
                isGetNewSignatureRequestLoading,
                isCompleteSignatureStepLoading,
                isSetConfigLoading,
            },
            data: {
                signatureStep: { signUrl, status },
            },
        }) => ({
            isLoading:
                !signUrl ||
                isGetNewSignatureRequestLoading ||
                isCompleteSignatureStepLoading ||
                isSetConfigLoading,
            isCompleteSignatureStepLoading,
            error: completeSignatureStepError,
            status,
        }),
    )[0];
    const { isLoading, error, status } = getState();
    const isSubmitting = status === SignatureSubmitStatus.Submitting;

    useEffect(() => {
        appStore.asyncDispatch.getNewSignatureRequest();
    }, []);

    const { goForward } = useStepNavigate({ appStore });
    const onComplete = async () => {
        await goForward({ formikStore });
    };

    return (
        <StepPageLayout
            className={clsx(
                styles.ContentWrapper,
                "[&>*>.StepContentContainer>.StepWrapper]:pb-2",
                "[&>*>.StepContentContainer>.StepWrapper>.TitlesAndContent>.StepContent]:pb-0",
                "[&>*>.StepContentContainer>.StepWrapper>.TitlesAndContent>.StepContent]:h-full",
            )}
            appStore={appStore}
            id="signature-step"
            title={t("signatureStepHeader")}
            isLoading={isSubmitting || isLoading}
            error={error}
            formikStore={formikStore}
            width="wide"
            isFooterHidden
            customGoBackButton={{
                disabled: isSubmitting,
            }}
        >
            <div className={styles.SignatureWrapper}>
                <div className={styles.SignatureContent}>
                    <HelloSignContainer
                        onCompleted={onComplete}
                        appStore={appStore}
                    />
                </div>
            </div>
        </StepPageLayout>
    );
};
