import { StepName, StepStatus } from "features/common";
import { API_ENDPOINTS, httpCore } from "features/utils";

import { IAppStateType } from "../AppStore.types";
import { getStepperState } from "../helpers";

export const almostDoneStepReducers = {};

export const almostDoneStepAsyncReducers = {
    async completeAlmostDoneStep(currentState: IAppStateType) {
        try {
            const response = await httpCore.put(
                API_ENDPOINTS.signup.AlmostDoneStep,
                {
                    stepName: StepName.AlmostDone,
                    stepStatus: StepStatus.Complete,
                },
            );
            const { stepper } = response.data;

            return (state: IAppStateType) => ({
                ...state,
                ...getStepperState({ state, stepper }),
                almostDoneStep: {
                    ...state.almostDoneStep,
                    stepStatus: StepStatus.Complete,
                },
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
};
