import React, { useRef } from "react";

import { useDerivedState } from "store/hooks";

import { IAuthIconComponentProps } from "./AuthIcon.types";

import styles from "./AuthIcon.module.scss";

export function AuthIcon({ appStore }: IAuthIconComponentProps) {
    const listRef = useRef<HTMLDivElement>(null);
    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                profile,
                config: { businessName },
            },
            loading: { isSetConfigLoading },
        }) => ({
            isLoading: isSetConfigLoading,
            businessName,
            ...profile,
        }),
    );

    const { businessName } = getState();

    return (
        <div className={styles.AuthIcon} ref={listRef}>
            <div className={styles.AuthIcon_email}>{businessName}</div>
        </div>
    );
}
