import { SuggestedTaxes } from "../../ShippingInformationForm.types";

export const formatPercent = (
    number: number | null | undefined,
    minDecimals = 1,
    maxDecimals = 3,
): string => {
    const percentage = (number ?? 0) * 100;
    return `${percentage.toLocaleString("en-US", {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals,
    })}%`;
};

export const areSuggestedTaxesFound = (
    taxRates: SuggestedTaxes | null,
): boolean => {
    return taxRates?.totalRate !== undefined && taxRates?.totalRate !== null;
};
