import axios from "axios";
import { TFunction } from "i18next";
import _ from "lodash";

import { Namespace, TranslationKeys } from "i18nConfig";

export function getFilePathFromUrl(url: string) {
    const { pathname } = new URL(url);
    const filePath = pathname.replace(/^\//, "");
    return decodeURI(filePath);
}

export function getNameFromUrl(url: string) {
    const filePath = getFilePathFromUrl(url);
    const paths = filePath.split("/");
    const nameWithIds = paths[paths.length - 1];
    const parts = nameWithIds.split("-");
    const name = parts.slice(2).join("");
    return decodeURI(name);
}

export async function getFileFromUrl(url: string) {
    const res = await axios.get(url, {
        responseType: "arraybuffer",
    });
    const name = getNameFromUrl(url);
    const type = res.headers["content-type"] || "";
    return new File([res.data], name, { type });
}

export const getTranslationFromEnumValues = (
    enumObject: Record<string, string>,
    t: TFunction<Namespace, undefined, Namespace>,
) => {
    return Object.entries(enumObject).map(([key, value]) => ({
        value,
        label: t(`${_.camelCase(key)}Label` as TranslationKeys),
    }));
};

export const dataURLtoFile = (dataurl: string, filename: string): File => {
    const [header, base64Data] = dataurl.split(",");
    if (!header || !base64Data) {
        throw new Error("Invalid data URL");
    }

    const [, mime] = header.match(/:(.*?);/) ?? [];
    if (!mime) {
        throw new Error("Invalid MIME type");
    }

    const bstr = atob(base64Data);
    const u8arr = new Uint8Array(bstr.length);
    for (let i = 0; i < bstr.length; i++) {
        u8arr[i] = bstr.charCodeAt(i);
    }

    return new File([u8arr], filename, { type: mime });
};
