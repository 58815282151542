/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, SyntheticEvent, useRef } from "react";
import InputMask from "react-input-mask";
import { useField } from "formik";
import { Input } from "spoton-lib";

import { DateFormat } from "features/common/types";

import { IDateInputFieldProps } from "./DateInputField.types";

import styles from "./DateInputField.module.scss";

export function DateInputField<T>(props: IDateInputFieldProps<T>) {
    const {
        name,
        label,
        className,
        formik: { handleChange, handleBlur },
        value,
        onChange = () => ({}),
        onBlur = () => ({}),
        onFocus = () => ({}),
        isValid,
        primaryCondition,
        errorMessage,
        placeholder,
        id,
        inputMode,
        dataDdPrivacy,
        isDisabled = false,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const [field, meta] = useField(name);

    const onFocusField = (e: SyntheticEvent<Element, Event>) => {
        onFocus(e);
    };

    const onBlurField = (e: SyntheticEvent<Element, Event>) => {
        handleBlur(e);
        onBlur(e);
    };

    const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
        handleChange(e);
        onChange(e);
    };

    return (
        <InputMask
            {...field}
            mask="99/99/9999"
            value={value}
            onChange={onChangeField}
            onBlur={onBlurField}
            onFocus={onFocusField}
            id={id || `${name}-field`}
            inputRef={inputRef}
            disabled={isDisabled}
            placeholder={placeholder}
            maskPlaceholder={DateFormat.MM_DD_YYYY}
            inputMode={inputMode || "numeric"}
        >
            <Input
                disabled={isDisabled}
                value={value}
                onChange={onChangeField}
                onBlur={onBlurField}
                onFocus={onFocusField}
                className={className}
                label={label}
                isValid={isValid ? isValid : meta.touched ? !meta.error : true}
                primaryCondition={primaryCondition}
                secondaryCondition={
                    errorMessage
                        ? errorMessage
                        : (meta.touched && meta.error) || ""
                }
                secondaryConditionClassName={styles.TextField_errorMessage}
                isMultilineSecondaryCondition={true}
                placeholder={placeholder}
                data-dd-privacy={dataDdPrivacy}
            />
        </InputMask>
    );
}

export default DateInputField;
