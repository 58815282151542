import {
    initReactI18next,
    useTranslation as defaultUseTranslation,
} from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { namespaceConfig as common } from "./common";
import { Namespace } from "./translation.types";

// we will use the `common` namespace as the default in this case, althought
// that may change.
export const defaultNamespace = Namespace.Common;

// combine all namespaces into a resources object that we know how to read
export const resources = {
    en: {
        [common.namespace]: common.translations.en,
    },
    // es: {}
};

// hook which can be imported by any component that wants to use common translations
export function useTranslation(namespace?: Namespace) {
    return defaultUseTranslation(namespace || defaultNamespace);
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        defaultNS: defaultNamespace,
        // lng: "en", // leave out for browser detection
        fallbackLng: "en",
        debug: typeof jest === undefined, // false for testing environment

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        detection: {
            // order and from where user language should be detected
            order: [
                "navigator",
                "querystring",
                "cookie",
                "localStorage",
                "sessionStorage",
                "htmlTag",
                "path",
                "subdomain",
            ],

            // keys or params to lookup language from
            lookupQuerystring: "lng",
            lookupCookie: "i18next",
            lookupLocalStorage: "i18nextLng",
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language
            caches: [],
            excludeCacheFor: [],
        },
    });

export const t = i18n.t.bind(i18n);
export default i18n;
