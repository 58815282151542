import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import { Button, Text } from "spoton-lib";

import { SuccessImages } from "app/components/SuccessPage/SuccessImages";
import { useTranslation } from "i18nConfig";

import { SiteSurveyContext } from "../../SiteSurveyPage.utils";

import styles from "./SiteSurveySuccessPage.module.scss";

export const SiteSurveySuccessPage = () => {
    const { t } = useTranslation();
    const { isRestaurant, opportunityLink } =
        useContext(SiteSurveyContext) || {};

    useEffect(() => {
        document.getElementById("content")?.scrollTo(0, 0);
    }, []);

    const handleRedirectToSalesforce = () => {
        if (opportunityLink) window.location.href = opportunityLink;
    };

    return (
        <div className={styles.ContentWrapper}>
            <div className={styles.Page}>
                <div className={styles.Content}>
                    <Text className={styles.PageTitle} type="h3">
                        {t("siteSurveySuccessTitle")}
                    </Text>
                    <Text
                        className={clsx(
                            styles.PageSubTitle,
                            "pb-4 whitespace-pre-line",
                        )}
                        type="sub1"
                    >
                        {t("siteSurveySuccessDescription")}
                    </Text>
                    <Button
                        variant="secondary"
                        className="tablet:mt-5"
                        onClick={handleRedirectToSalesforce}
                    >
                        {t("goToSalesforce")}
                    </Button>
                </div>
                <SuccessImages isRestaurant={!!isRestaurant} />
            </div>
        </div>
    );
};
