interface IPosition {
    value: string;
    label: string;
}

export const positionTitles: IPosition[] = [
    { label: "Accountant", value: "Accountant" },
    { label: "CEO", value: "CEO" },
    { label: "CFO", value: "CFO" },
    { label: "Controller/Treasurer", value: "Controller/Treasurer" },
    { label: "Co-Owner", value: "Co-Owner" },
    { label: "Director of Operations", value: "Director of Operations" },
    { label: "General Manager", value: "General Manager" },
    { label: "Other C-Level", value: "Other C-Level" },
    { label: "Owner", value: "Owner" },
];
