/* eslint-disable */
import { StepName } from "features/common";
import { camelCaseToHyphen } from "./utils";

export type AnalyticProps = {
    "data-analytics-object": string;
    [key: string]: string | undefined;
};

type GetAnalyticPropsFunction = (
    object: AnalyticElements,
    tags: {
        step?: StepName;
        context?: string;
        deviceType?: "mobile" | "desktop";
    } & Record<string, string | undefined>,
) => AnalyticProps;

export enum AnalyticElements {
    SecondaryButton = "cancelButton",
    ContinueButton = "continueButton",
    GoToTakePhotoButton = "goToTakePhotoButton",
    DeleteLinkedAccountButton = "deleteLinkedAccountButton",
    GoBackButton = "goBackButton",
    AddAdditionalOwnerButton = "addAdditionalOwnerButton",
    CloseUploadModalButton = "closeUploadModalButton",
    UploadImageButton = "uploadImageButton",
    OpenUploadImageModalButton = "openUploadImageModalButton",
    TakeAPhotoButton = "takeAPhotoButton",
    SwitchCameraButton = "switchCameraButton",
    WebcamElement = "webcamElement",
    CancelConfirmationButton = "cancelConfirmationButton",
    ApproveConfirmationButton = "approveConfirmationButton",
    ReplaceImageButton = "replaceImageButton",
    DeleteImageButton = "deleteImageButton",
    PlaidLink = "plaidLink",
}

export enum AnalyticEvents {
    BillingSelectorRootView = "BillingSelectorRootView",
    StepView = "StepView",
    BillingSelectorCameraView = "BillingSelectorCameraView",
}

export const getAnalyticProps: GetAnalyticPropsFunction = (object, tags) => {
    return {
        "data-analytics-object": object,
        ...Object.fromEntries(
            Object.entries(tags || {}).map(([key, value]) => [
                value ? `data-analytics-${camelCaseToHyphen(key)}` : "",
                value,
            ]),
        ),
    };
};
