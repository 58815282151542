import { useEffect } from "react";
import { FormikErrors } from "formik";

import { IScrollToFieldErrorProps } from "./ScrollToFieldError.types";

export const getFirstFieldErrorName = (formikErrors: FormikErrors<any>) => {
    const transformObjectToDotNotation = (
        obj: any,
        prefix = "",
        result: string[] = [],
    ) => {
        const firstKey = Object.keys(obj)[0];
        if (Array.isArray(obj)) {
            const firstElemWithErrorIndex = obj.findIndex(
                (elem) => elem !== undefined,
            );
            transformObjectToDotNotation(
                obj[firstElemWithErrorIndex],
                `${prefix}[${firstElemWithErrorIndex}]`,
                result,
            );
        } else if (typeof obj === "object") {
            transformObjectToDotNotation(
                obj[firstKey],
                prefix ? `${prefix}.${firstKey}` : firstKey,
                result,
            );
        } else {
            result.push(prefix ? prefix : firstKey);
        }
        return result;
    };

    return transformObjectToDotNotation(formikErrors)[0];
};

export function ScrollToFieldError<T>({ formik }: IScrollToFieldErrorProps<T>) {
    const { isSubmitting, isValid, errors } = formik;

    useEffect(() => {
        if (isSubmitting && !isValid) {
            const firstErrorFieldName = getFirstFieldErrorName(errors);
            const inputElement = document.querySelector<HTMLInputElement>(
                `input[name='${firstErrorFieldName}']`,
            );

            inputElement?.focus({ preventScroll: true });
            const finalElement =
                inputElement?.type === "hidden"
                    ? inputElement?.parentElement
                    : inputElement;

            finalElement?.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [isSubmitting, isValid]);
    return null;
}
