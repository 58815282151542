import { IPlatformStep, IRatesType } from "features/application-wizard";
import {
    InactiveStepName,
    IPaginatedResponse,
    StepStatus,
} from "features/common";
import { API_ENDPOINTS, httpCore } from "features/utils";

import { IAppStateType } from "../AppStore.types";
import { defaultRates, defaultReducer } from "../defaultState";
import { getStepperState } from "../helpers";

export const platformStepReducers = {};

export const platformStepAsyncReducers = {
    async getPlatformStepLineItems(currentState: IAppStateType) {
        const response = await httpCore.get(
            API_ENDPOINTS.signup.PlatformStepLineItems,
        );
        if (response.status !== 200) return defaultReducer;
        const platformStep = response.data as IPlatformStep;

        return (state: IAppStateType) => ({
            ...state,
            platformStep: {
                ...state.platformStep,
                ...platformStep,
            },
        });
    },

    async getPlatformStepProcessingItems(currentState: IAppStateType) {
        const response = await httpCore.get(
            API_ENDPOINTS.signup.PlatformStepProcessingItems,
        );
        if (response.status !== 200) return defaultReducer;
        const processingItems = response.data as IPaginatedResponse<IRatesType>;
        const rates = processingItems.results?.[0] || defaultRates();

        return (state: IAppStateType) => ({
            ...state,
            platformStep: {
                ...state.platformStep,
                rates,
            },
        });
    },

    // TODO figure out if we should use this at all, should be readonly
    async setPlatformStepComplete(currentState: IAppStateType) {
        try {
            const response = await httpCore.put(
                API_ENDPOINTS.signup.PlatformStepLineItems,
                {
                    stepName: InactiveStepName.Platform,
                    stepStatus: StepStatus.Complete,
                },
            );
            const { stepper } = response.data;

            return (state: IAppStateType) => ({
                ...state,
                ...getStepperState({ state, stepper }),
                platformStep: {
                    ...state.platformStep,
                    stepStatus: StepStatus.Complete,
                },
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
};
