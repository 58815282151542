import { IGenericStep } from "features/store";

export enum SignatureSubmitStatus {
    NotInitialized = "notInitialized",
    Initialized = "initialized",
    Submitting = "submitting",
    Failed = "failed",
}

export enum SignatureErrorCodes {
    NothingToSign = "nothing_to_sign",
}

export interface ISignatureStep extends IGenericStep {
    signatureRequestId: string;
    signatureId: string;
    signUrl: string;
    wasSigned: boolean;
    status: SignatureSubmitStatus;
}
