import React, { RefObject, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Text } from "spoton-lib";

import { Breakpoint, useBreakpoint } from "features/hooks";
import { useTranslation } from "i18nConfig";

interface ITermsSignatureCanvas {
    signatureRef: RefObject<SignatureCanvas>;
    trimmedDataURL: string;
    setTrimmedDataURL: (v: string) => void;
    onStartDrawing: () => void;
    onClearDrawing: () => void;
}

export const TermsSignatureCanvas = (props: ITermsSignatureCanvas) => {
    const {
        signatureRef,
        trimmedDataURL,
        setTrimmedDataURL,
        onStartDrawing,
        onClearDrawing,
    } = props;

    const [hasStartedSigning, setHasStartedSigning] = useState<boolean>(false);
    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);

    const { t } = useTranslation();

    const onTrim = () => {
        const trimmedData = signatureRef.current
            ?.getTrimmedCanvas()
            ?.toDataURL("image/png");
        if (trimmedData) {
            setTrimmedDataURL(trimmedData);
        }
    };

    const onBegin = () => {
        setHasStartedSigning(true);
        onStartDrawing();
    };

    const onClear = () => {
        signatureRef?.current?.clear();
        setTrimmedDataURL("");
        setHasStartedSigning(false);
        onClearDrawing();
    };

    return (
        <>
            <div className="flex flex-col gap-8 relative">
                {!isLowerThanTablet && (
                    <SignatureCanvas
                        canvasProps={{
                            width: 665,
                            height: 200,
                            className: "sigCanvas",
                        }}
                        onBegin={onBegin}
                        ref={signatureRef}
                        onEnd={onTrim}
                    />
                )}
                {trimmedDataURL && (
                    <Text
                        onClick={onClear}
                        className="absolute z-20 right-6 top-0 text-base text-primary-primary-60 font-medium cursor-pointer"
                    >
                        {t("clearLabel")}
                    </Text>
                )}
                {!hasStartedSigning && (
                    <Text className="absolute z-20 left-0 right-0 top-20 text-neutral-60 pointer-events-none text-center">
                        {t("holdToDraw")}
                    </Text>
                )}
                <div className="absolute z-20 left-1/2 transform -translate-x-1/2 bottom-12 h-[1px] bg-gray-200 w-[327px] tablet:w-[500px]" />
            </div>
        </>
    );
};
