export const base64ToWebpFile = (base64: string, fileName: string) => {
    const byteString = atob(base64);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/webp" });
    const file = new File([blob], fileName, { type: "image/webp" });
    return file;
};
