export enum FileType {
    JPEG = "image/jpeg",
    PNG = "image/png",
    WEBP = "image/webp",
    BMP = "image/bmp",
    TIFF = "image/tiff",
    HEIC = "image/heic",
    PDF = "application/pdf",
}

export const acceptedFileTypes = [
    FileType.JPEG,
    FileType.PNG,
    FileType.WEBP,
    FileType.BMP,
    FileType.TIFF,
    FileType.HEIC,
    FileType.PDF,
] as const;
