import { IGenericStep } from "features/store";

import { IOwnerType } from "../common";

export interface IOwnersStep extends IGenericStep {
    owners: IOwnerType[];
    isMoreThan25?: boolean;
}

export enum MoreThan25 {
    Yes = "yes",
    No = "no",
}

export type ISubmitValues = {
    value: string;
    index: number;
};
