import React, { useEffect } from "react";

import { IPageComponentProps } from "app/types";
import { LegalBusinessForm } from "features/application-wizard";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { StepPageLayout } from "../StepPageLayout";

import styles from "./LegalBusinessPage.module.scss";

export const LegalBusinessPage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();

    const getState = useDerivedState(
        appStore,
        ({ loading: { isGetBusinessStepLoading } }) => ({
            isLoading: isGetBusinessStepLoading,
        }),
    )[0];
    const { isLoading } = getState();

    useEffect(() => {
        appStore.asyncDispatch.getBusinessStep();
    }, []);

    return (
        <StepPageLayout
            appStore={appStore}
            className={styles.ContentWrapper}
            id="legal-business-step"
            isLoading={isLoading}
            title={t("legalBusinessTitle")}
            subtitle={t("legalBusinessSubtitle")}
            formikStore={formikStore}
        >
            <LegalBusinessForm appStore={appStore} formikStore={formikStore} />
        </StepPageLayout>
    );
};
