import axiosDefault, {
    AxiosHeaders,
    AxiosRequestConfig,
    AxiosRequestTransformer,
    AxiosResponseTransformer,
} from "axios";
import qs from "qs";
import { v4 as uuid } from "uuid";

import { coreErrorRegistry } from "./axiosErrorRegistries/axiosDefaultErrorRegistry";
import { onFileChangeSuccess } from "./axiosSuccessFeedbacks/axiosFileChangeSuccessFeedback";
import { fileUploadErrorRegistry } from "./axiosErrorRegistries";
import {
    getAxiosTransformers,
    toCamelCaseResponse,
    toSnakeCaseRequest,
} from "./axiosTransformers.utils";
import { getConfigVar } from "./config.utils";
import { serializeToSnakeCase } from "./serializers.utils";

const transformResponse: AxiosResponseTransformer[] =
    getAxiosTransformers(toCamelCaseResponse);

const transformRequest: AxiosRequestTransformer[] =
    getAxiosTransformers(toSnakeCaseRequest);

const defaultHttpSettings: AxiosRequestConfig = {
    baseURL: getConfigVar("REACT_APP_BASE_URL") as string,
    withCredentials: true,
    transformResponse,
    transformRequest,
    paramsSerializer: (params: any) => {
        return qs.stringify(params ? serializeToSnakeCase(params) : params);
    },
    headers: {
        "Content-Type": "application/json",
    },
};

export const httpCore = axiosDefault.create({
    ...defaultHttpSettings,
});

export const httpFileCore = axiosDefault.create({
    ...defaultHttpSettings,
    transformRequest: undefined,
});

export const uploadCore = axiosDefault.create({
    ...defaultHttpSettings,
    transformRequest: undefined,
    headers: {
        "Content-Type": "multipart/form-data; boundary=???",
    },
});

const setRequestId = (
    config: AxiosRequestConfig & { headers: AxiosHeaders },
) => {
    config.headers["X-Request-ID"] = uuid();
    return config;
};

httpCore.interceptors.request.use(setRequestId);

httpCore.interceptors.response.use(
    (value) => value,
    coreErrorRegistry.resposeErrorHandler(httpCore),
);

httpFileCore.interceptors.request.use(setRequestId);

httpFileCore.interceptors.response.use(
    onFileChangeSuccess,
    fileUploadErrorRegistry.resposeErrorHandler(httpFileCore),
);

uploadCore.interceptors.request.use(setRequestId);

uploadCore.interceptors.response.use(
    (value) => value,
    fileUploadErrorRegistry.resposeErrorHandler(uploadCore),
);

export type { AxiosResponse } from "axios";

export function transformServiceResponseSimple(
    transformer: AxiosResponseTransformer,
): AxiosResponseTransformer[] {
    return getAxiosTransformers(...transformResponse, transformer);
}
