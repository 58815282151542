import { StepName, StepStatus } from "features/common";
import { API_ENDPOINTS, httpCore } from "features/utils";

import { IAppStateType } from "../AppStore.types";
import { defaultReducer } from "../defaultState";
import { getStepperState } from "../helpers";

export const fasterFundingStepReducers = {};

export const fasterFundingStepAsyncReducers = {
    async getFasterFundingStep(currentState: IAppStateType) {
        const response = await httpCore.get(API_ENDPOINTS.signup.FasterFunding);
        if (response.status !== 200) return defaultReducer;

        return (state: IAppStateType) => ({
            ...state,
            fasterFundingStep: {
                ...state.fasterFundingStep,
                ...response.data,
            },
        });
    },

    async completeFasterFundingStep(currentState: IAppStateType) {
        try {
            const response = await httpCore.put(
                API_ENDPOINTS.signup.FasterFunding,
                {
                    stepName: StepName.FasterFunding,
                    stepStatus: StepStatus.Complete,
                },
            );
            const { stepper } = response.data;

            return (state: IAppStateType) => ({
                ...state,
                ...getStepperState({ state, stepper }),
                fasterFundingStep: {
                    ...state.fasterFundingStep,
                    stepStatus: StepStatus.Complete,
                },
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
};
