import React, { useEffect } from "react";

// types
import { IPageComponentProps } from "app/types";
import { ShippingInformationForm } from "features/application-wizard";
// translation
import { useTranslation } from "i18nConfig";
// hooks
import { useDerivedState } from "store/hooks";

import { StepPageLayout } from "../StepPageLayout";

// styles
import styles from "./ShippingPage.module.scss";

export const ShippingPage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();
    const getState = useDerivedState(
        appStore,
        ({
            data: { applicantStep, businessStep },
            loading: {
                isGetShippingStepLoading,
                isGetBusinessStepLoading,
                isGetApplicantStepLoading,
            },
        }) => ({
            businessStep,
            applicantStep,
            isLoading:
                isGetShippingStepLoading ||
                isGetBusinessStepLoading ||
                isGetApplicantStepLoading,
        }),
    )[0];
    const { businessStep, applicantStep, isLoading } = getState();

    useEffect(() => {
        appStore.asyncDispatch.getShippingStep();
        if (!businessStep.businessContact.businessName)
            appStore.asyncDispatch.getBusinessStep();
        if (!applicantStep.applicant.contact.firstName) {
            appStore.asyncDispatch.getApplicantStep();
        }
    }, []);

    return (
        <StepPageLayout
            appStore={appStore}
            className={styles.ContentWrapper}
            id="shipping-step"
            title={t("shippingInformationTitle")}
            isLoading={isLoading}
            formikStore={formikStore}
        >
            <ShippingInformationForm
                appStore={appStore}
                formikStore={formikStore}
            />
        </StepPageLayout>
    );
};
