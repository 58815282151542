import React, { useEffect } from "react";

import { IPageComponentProps } from "app/types";
import { OwnersForm } from "features/application-wizard";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { StepPageLayout } from "../StepPageLayout";

import styles from "./OwnersPage.module.scss";

export const OwnersPage = ({ appStore, formikStore }: IPageComponentProps) => {
    const { t } = useTranslation();
    const getState = useDerivedState(
        appStore,
        ({
            data: {
                config: { businessName },
                applicantStep,
            },
            loading: { isGetApplicantStepLoading, isGetOwnersStepLoading },
        }) => ({
            applicantStep,
            businessName,
            isLoading: isGetApplicantStepLoading || isGetOwnersStepLoading,
        }),
    )[0];
    const {
        applicantStep: { applicant },
        businessName,
        isLoading,
    } = getState();
    const isAdditionalOwnersRequired =
        applicant.ownershipPercentage !== undefined &&
        applicant.ownershipPercentage < 25 &&
        applicant.isResponsibleParty;

    useEffect(() => {
        (async () => {
            await Promise.all([
                appStore.asyncDispatch.getOwnersStep(),
                appStore.asyncDispatch.getApplicantStep(),
            ]);
        })();
    }, []);

    return (
        <StepPageLayout
            appStore={appStore}
            className={styles.ContentWrapper}
            id="owners-step"
            title={
                isAdditionalOwnersRequired
                    ? t("addEachPersonWithMoreThan25")
                    : t("ownersPageTitle", {
                          businessName,
                      })
            }
            isLoading={isLoading}
            formikStore={formikStore}
        >
            <OwnersForm appStore={appStore} formikStore={formikStore} />
        </StepPageLayout>
    );
};
