/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import Webcam from "react-webcam";
import clsx from "clsx";

import { AnalyticElements, getAnalyticProps } from "app/utils";
import { useTranslation } from "i18nConfig";

import { StepName } from "../Stepper";
import { ModifiedWebcamProps } from "./CheckPhotoCamera.types";

export const CustomWebcam = forwardRef<Webcam, ModifiedWebcamProps>(
    (
        {
            className,
            isLoading,
            imageOnLoading,
            deviceId,
            isVideoAllowed,
        }: ModifiedWebcamProps,
        ref,
    ) => {
        const showScreenshot = imageOnLoading && isLoading;
        const { t } = useTranslation();
        return (
            <>
                {showScreenshot && (
                    <img
                        src={imageOnLoading}
                        alt={t("loading")}
                        className={clsx(className)}
                    />
                )}
                {!showScreenshot && (!deviceId || !isVideoAllowed) ? (
                    <div
                        className={clsx(
                            className,
                            "w-full min-h-[18rem] bg-[#0D0A30]",
                        )}
                    />
                ) : (
                    <Webcam
                        muted={false}
                        screenshotQuality={1}
                        audio={false}
                        videoConstraints={{
                            deviceId: {
                                exact: deviceId,
                            },
                        }}
                        placeholder={t("loading")}
                        className={clsx(className, {
                            hidden: showScreenshot,
                        })}
                        screenshotFormat="image/png"
                        ref={ref}
                        {...getAnalyticProps(AnalyticElements.WebcamElement, {
                            step: StepName.BillingSelector,
                        })}
                    />
                )}
            </>
        );
    },
);
