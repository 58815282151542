import React, { ReactNode } from "react";
import clsx from "clsx";
import { LoadingIndicator, Text } from "spoton-lib";

import { AnalyticElements, getAnalyticProps } from "app/utils";
import { Breakpoint, useBreakpoint } from "features";

import { StepName } from "../Stepper";

import styles from "./BillingSelectorRoot.module.scss";

export const BillingSelectorButton = (props: {
    icon: ReactNode;
    title: string;
    description: string;
    onClick: () => void;
    isLoading?: boolean;
}) => {
    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);
    const { icon, title, description, onClick, isLoading } = props;
    return (
        <button
            className={styles.BillingView_photoSection}
            onClick={onClick}
            {...getAnalyticProps(AnalyticElements.GoToTakePhotoButton, {
                step: StepName.BillingSelector,
            })}
        >
            {isLoading && (
                <div className="h-full w-full absolute left-0 top-0 tablet:p-12">
                    <LoadingIndicator
                        className="w-full h-full flex items-center justify-center [&>div>#lottie]:h-[118px] tablet:[&>div>#lottie]:h-full"
                        size="sm"
                        greyAnimation={false}
                        hasBackground={false}
                    />
                </div>
            )}
            {!isLowerThanTablet && !isLoading && icon}
            <div
                className={clsx("flex flex-col gap-0 tablet:gap-2", {
                    invisible: isLoading,
                })}
            >
                <Text className={styles.BillingView_photoTitle} type="h6">
                    {isLowerThanTablet && (
                        <div className="h-10 w-10">{icon}</div>
                    )}
                    {title}
                </Text>
                <Text className={styles.BillingView_photoDescription} type="p">
                    {description}
                </Text>
            </div>
        </button>
    );
};
