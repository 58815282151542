/* eslint-disable react/prop-types */
import React from "react";
import { ErrorPage } from "spoton-lib";

import { StatelessPageLayout } from "app/components/PageLayout";

import styles from "./ErrorPage.module.scss";

interface IProps {
    className?: string;
    children?: React.ReactNode;
}

interface IState {
    error: Error | null;
}

// NOTE: using class based component ONLY because we need support for error boundaries
export class ErrorBoundaryGeneric extends React.Component<IProps> {
    state: IState = { error: null };

    componentDidCatch(error: Error): void {
        this.setState({ error });
    }

    renderGenericError = () => {
        return (
            <StatelessPageLayout>
                <ErrorPage errorCode="500" className={styles.ErrorPage} />
            </StatelessPageLayout>
        );
    };

    render(): React.ReactNode {
        if (this.state.error) {
            return this.renderGenericError();
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundaryGeneric;
