/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import InputMask from "react-input-mask";
import { parsePhoneNumber } from "react-phone-number-input";
import { useField } from "formik";
import { Input } from "spoton-lib";

import { IPhoneInputFieldProps } from "./PhoneInputField.types";

import styles from "./PhoneInputField.module.scss";

export function PhoneInputField<T>(props: IPhoneInputFieldProps<T>) {
    const {
        name,
        label,
        className,
        formik: { handleBlur, setFieldValue },
        onChange = () => ({}),
        onBlur = () => ({}),
        onFocus = () => ({}),
        isValid,
        primaryCondition,
        errorMessage,
        id,
    } = props;

    const [field, meta] = useField(name);
    const { value } = field;

    const phoneFieldData = value
        ? {
              ...parsePhoneNumber(value),
              isComplete: true,
          }
        : {
              nationalNumber: value,
              isComplete: false,
          };
    let { nationalNumber } = phoneFieldData;

    if (!nationalNumber) {
        const wrongFormatRegex = /[()\s-]/;
        if (wrongFormatRegex.test(value)) {
            nationalNumber = value.replace(/[()\s-]/g, "");
        } else {
            nationalNumber = value;
        }
    }

    const onFocusField = (e: React.SyntheticEvent<Element, Event>) => {
        onFocus(e);
    };

    const onBlurField = (e: React.SyntheticEvent<Element, Event>) => {
        handleBlur(e);
        onBlur(e);
    };

    const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = e.target.value.replace(/["'()_-\s]/g, "");
        setFieldValue(name, phoneNumber);
        onChange(e);
    };

    return (
        <InputMask
            {...field}
            type="tel"
            mask={"(999) 999-9999"}
            value={nationalNumber}
            onChange={onChangeField}
            onBlur={onBlurField}
            onFocus={onFocusField}
            id={id || `${name}-field`}
            disabled={false}
            inputMode="tel"
        >
            <Input
                disabled={false}
                className={className}
                label={label}
                isValid={isValid ? isValid : meta.touched ? !meta.error : true}
                primaryCondition={primaryCondition}
                primaryConditionClassName={styles.PhoneField_primaryCondition}
                secondaryCondition={
                    errorMessage
                        ? errorMessage
                        : (meta.touched && meta.error) || ""
                }
                secondaryConditionClassName={styles.PhoneField_errorMessage}
                isMultilineSecondaryCondition={true}
            />
        </InputMask>
    );
}

export default PhoneInputField;
