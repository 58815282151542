/* eslint-disable @typescript-eslint/naming-convention */
import { ReactNode } from "react";
import { Accept, DropzoneOptions } from "react-dropzone";

export enum ErrorCodes {
    FileInvalidType = "file-invalid-type",
    FileTooLarge = "file-too-large",
    FileTooSmall = "file-too-small",
    TooManyFiles = "too-many-files",
    FileNameTooLong = "file-name-too-long",
}

export type FileUploadProps = {
    className?: string;
    file: File | null | undefined;
    onDelete: () => void;
    onChange: (file: File) => void;
    description?: ReactNode;
    maxFileNameLength?: number;
    maxSize?: number;
    accept?: Accept;
    isLoading?: boolean;
};

export type FileDropzoneProps = {
    file: File | null | undefined;
    onChange: (file: File) => void;
    isDescription: boolean;
    renderPreview: ({
        file,
        open,
    }: {
        file: File;
        open: () => void;
    }) => ReactNode;
    dropzoneProps?: Omit<DropzoneOptions, "multiple">;
};

export type ButtonsOverlayProps = {
    className?: string;
    onDelete: () => void;
    onEdit: () => void;
};

export type FileIconButtonProps = {
    id: string;
    onClick: () => void;
    label: string;
    className?: string;
    iconName?: string;
};

export type FileUploadPreviewProps = {
    isDescription?: boolean;
    onEdit: () => void;
    onDelete: () => void;
    file: File;
};
