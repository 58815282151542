import React from "react";
import { Skeleton } from "@spotoninc/nexus-react";

import { SuggestedTaxes } from "features/application-wizard";

import { TaxItem } from "../../TaxItem.component";

export const TaxItemsList = ({
    taxRates,
    isLoading,
}: {
    taxRates: SuggestedTaxes | null;
    isLoading?: boolean;
}) => {
    const { rates } = taxRates || {};
    return (
        <>
            {!isLoading
                ? rates?.map((item) => (
                      <TaxItem
                          key={`${item.name}_${item.rate}_${item.type}`}
                          tax={item}
                      />
                  ))
                : [1, 2, 3].map((item) => (
                      <Skeleton key={item} height="1.5rem" />
                  ))}
        </>
    );
};
