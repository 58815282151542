/**
 * Collection of available logging levels
 */
export enum ELogLevels {
    /** warning messages only */
    Warn = "warn",

    /** error messages only */
    Error = "error",

    /** show info messages only */
    Info = "info",

    /** show logs, warnings and errors */
    Verbose = "verbose",

    /** show logs, info, warnings, and errors */
    Debug = "debug",
}
