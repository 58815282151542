import { SignatureSubmitStatus } from "features/application-wizard";
import { API_ENDPOINTS, httpCore } from "features/utils";

import { IAppStateType } from "../AppStore.types";
import { defaultReducer } from "../defaultState";
import { getStepperState } from "../helpers";
export const signatureStepReducers = {};

export const signatureStepAsyncReducers = {
    async setSignatureStatus(
        state: IAppStateType,
        status: SignatureSubmitStatus,
    ) {
        return (state: IAppStateType) => ({
            ...state,
            signatureStep: {
                ...state.signatureStep,
                status,
            },
        });
    },
    async getNewSignatureRequest(
        state: IAppStateType,
    ): Promise<typeof defaultReducer> {
        try {
            const response = await httpCore.post(
                API_ENDPOINTS.signup.SignatureStep,
            );
            return (state: IAppStateType) => ({
                ...state,
                signatureStep: {
                    ...state.signatureStep,
                    ...response.data,
                },
            });
        } catch {
            return defaultReducer;
        }
    },

    async completeSignatureStep(
        currentState: IAppStateType,
    ): Promise<typeof defaultReducer> {
        try {
            const response = await httpCore.put(
                API_ENDPOINTS.signup.SignatureStep,
            );
            const { stepper } = response.data;

            return (state: IAppStateType) => ({
                ...state,
                ...getStepperState({ state, stepper }),
                signatureStep: {
                    ...state.signatureStep,
                    wasSigned: true,
                },
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
};
