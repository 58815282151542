import { AppRoutes } from "app/routes";

import { StepName } from "./Stepper.types";

export const stepToRouteMap = () => ({
    // [StepName.Platform]: AppRoutes.Platform,
    [StepName.Business]: AppRoutes.BusinessInformation,
    [StepName.BusinessLegal]: AppRoutes.LegalBusiness,
    [StepName.Shipping]: AppRoutes.Shipping,
    [StepName.Applicant]: AppRoutes.Applicant,
    [StepName.Owners]: AppRoutes.Owners,
    [StepName.AlmostDone]: AppRoutes.AlmostDone,
    [StepName.BillingForm]: AppRoutes.BillingForm,
    [StepName.BillingSelector]: AppRoutes.BillingSelector,
    [StepName.BillingSuccess]: AppRoutes.BillingSuccess,
    [StepName.FasterFunding]: AppRoutes.FasterFunding,
    [StepName.Signature]: AppRoutes.Signature,
});

export const stepToRoute = (step: StepName) => stepToRouteMap()[step];

export const routeToStep = (route: string) => {
    const step = Object.entries(stepToRouteMap()).find(
        ([, value]) => value === route,
    )?.[0];
    return step as StepName;
};
