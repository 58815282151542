import { RefObject } from "react";
import { FormikProps } from "formik";

import { defaultReducer } from "../defaultState";
import { IFormikStateType } from "../FormikStore.types";

export const asyncReducers = {
    async submit(currentState: IFormikStateType) {
        const { formRefs } = currentState;
        Object.keys(formRefs).forEach((key) => {
            formRefs[key].current?.handleSubmit();
        });
        return defaultReducer;
    },
    async submitForm(currentState: IFormikStateType, key: string) {
        const { formRefs } = currentState;
        await formRefs[key]?.current?.submitForm();
        return defaultReducer;
    },
};

export const reducers = {
    addRef(
        state: IFormikStateType,
        value: string,
        ref: RefObject<FormikProps<any>>,
    ) {
        let { formRefs } = state;
        formRefs = { ...formRefs };
        formRefs[value] = ref;
        return { ...state, formRefs };
    },
    removeRef(state: IFormikStateType, value: string) {
        let { formRefs } = state;
        formRefs = { ...formRefs };
        delete formRefs[value];
        return { ...state, formRefs };
    },
};
