import { useMemo } from "react";
import {
    NavigateOptions,
    To,
    useLocation,
    useNavigate,
} from "react-router-dom";

import { AppRoutes } from "app/routes";
import { stepToRoute, stepToRouteMap } from "features/common";
import { AppStore, FormikStore } from "features/store";
import { useDerivedState } from "store/hooks";

export enum Direction {
    Forward = 1,
    Backward = -1,
}

type ChangeStepAndNavigateProps = {
    navigate: (to: To, options?: NavigateOptions) => void;
    appStore: AppStore;
    formikStore?: FormikStore;
    direction: Direction;
};

export const changeStepAndNavigate = async ({
    navigate,
    appStore,
    formikStore,
    direction,
}: ChangeStepAndNavigateProps) => {
    if (direction === Direction.Forward) {
        const state = await appStore.asyncDispatch.submitAndGoForward(
            appStore,
            formikStore,
        );

        const currentStep = state.progressBlock.navigation.activeStepName;
        if (currentStep && !state.progressBlock.error) {
            if (state.config.isSubmitted) navigate(AppRoutes.Success);
            navigate(stepToRoute(currentStep));
        }
    } else if (direction === Direction.Backward) {
        const state = await appStore.asyncDispatch.goBack();
        const currentStep = state.progressBlock.navigation.activeStepName;

        if (currentStep) {
            navigate(stepToRoute(currentStep));
        }
    }
};

export const useStepNavigate = ({ appStore }: { appStore: AppStore }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                progressBlock: {
                    navigation: { next, previous, latestStepName },
                    view: { activeStep },
                },
            },
        }) => ({
            next,
            previous,
            latestStepName,
            activeStep,
        }),
    );

    const allowedGoBackSteps = useMemo(() => {
        return Object.values(stepToRouteMap()) as string[];
    }, []);

    const { next, previous, activeStep } = getState();

    return {
        goBack: () =>
            changeStepAndNavigate({
                navigate,
                appStore,
                direction: Direction.Backward,
            }),
        goForward: ({ formikStore }: { formikStore: FormikStore }) =>
            changeStepAndNavigate({
                navigate,
                appStore,
                formikStore,
                direction: Direction.Forward,
            }),
        canGoBack: !!previous && allowedGoBackSteps.includes(location.pathname),
        canGoForward: !!next,
        goToTheActiveStep: (args?: { shouldReplace?: boolean }) => {
            const { shouldReplace } = args || { shouldReplace: false };
            const { activeStepName } =
                appStore.state.data.progressBlock.navigation;
            navigate(stepToRoute(activeStepName), {
                replace: shouldReplace,
            });
        },
        activeStepName: activeStep?.name,
    };
};
