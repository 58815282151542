import { IGenericStep } from "features/store";

import { IOwnerType } from "../common";

export enum OwnerStatus {
    Owner = "owner",
    PartOwner = "partOwner",
    ResponsibleParty = "responsibleParty",
}

export interface IApplicantStep extends IGenericStep {
    ownerStatus?: OwnerStatus;
    applicant: IOwnerType;
}
