import { SuggestedTaxes } from "../application-wizard";
import { API_ENDPOINTS } from "./apiEndpoints";
import { httpCore } from "./axios.utils";

export const getSuggestedLocalTaxes = async (zipCode: string) => {
    return (
        await httpCore.get<SuggestedTaxes>(
            `${API_ENDPOINTS.signup.TaxRatesByZip}/?zipcode=${zipCode}`,
        )
    ).data;
};
