import { AxiosRequestTransformer, AxiosResponseTransformer } from "axios";
import _ from "lodash";

import {
    serializeToCamelCase,
    serializeToSnakeCase,
} from "./serializers.utils";

export const getAxiosTransformers = (
    ...concatWithItems: AxiosResponseTransformer[]
): AxiosResponseTransformer[] => {
    const transformResponse = [] as AxiosResponseTransformer[];
    return transformResponse.concat(concatWithItems);
};

// RESPONSE TRANSFORMERS
export const toCamelCaseResponse: AxiosResponseTransformer = (
    data,
    headers,
) => {
    return data && headers["content-type"] === "application/json"
        ? serializeToCamelCase(JSON.parse(data))
        : data;
};

export const removeNullableValues = (data: object) =>
    _.mapValues(data, (v) => (v === null ? "" : v));

// REQUEST TRANSFORMERS
export const toSnakeCaseRequest: AxiosRequestTransformer = (data: any): any => {
    return JSON.stringify(data ? serializeToSnakeCase(data) : data);
};
