import { Store } from "store/store";

import { IAppStateType } from "./AppStore.types";
import { defaultState } from "./defaultState";
import { asyncReducers, reducers } from "./reducers";

export class AppStore extends Store<
    IAppStateType,
    typeof reducers,
    typeof asyncReducers
> {
    constructor() {
        super(defaultState(), reducers, asyncReducers);
    }
}
