// add more namespaces as needed
import { translationEN } from "./common/config";

export enum Namespace {
    Common = "common",
}

export interface ITranslation<T> {
    namespace: Namespace;
    translations: { [key: string]: T };
}

export type TranslationKeys = keyof typeof translationEN;
