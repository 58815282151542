export enum AppRoutes {
    Home = "/",
    // Platform = "/platform",
    BusinessInformation = "/business-information",
    LegalBusiness = "/legal-business",
    Applicant = "/applicant",
    Owners = "/owners",
    Success = "/success",
    Shipping = "/shipping",
    Signature = "/signup",
    Error = "/error",
    AlmostDone = "/almost-done",
    BillingForm = "/billing-form",
    BillingSelector = "/billing-selector",
    BillingSuccess = "/billing-success",
    FasterFunding = "/faster-funding",
    SiteSurvey = "/site-survey",
}
