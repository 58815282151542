import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { Logo_White_96 } from "app/icons";
import { AuthIcon } from "features/auth";
import { useStepNavigate } from "features/utils";

import { ProgressBlock } from "../ProgressBlock";
import { IHeaderProps } from "./Header.types";

import styles from "./Header.module.scss";

export const Header = ({
    appStore,
    formikStore,
    shouldShowProgressBlock,
    shouldShowAuthIcon = !shouldShowProgressBlock,
}: IHeaderProps) => {
    const shouldShowLogo = true;

    const { canGoBack } = useStepNavigate({ appStore });

    return (
        <div
            className={clsx(styles.Header, {
                [styles.MobileHeaderWithoutStepper]: !shouldShowProgressBlock,
            })}
            id="header"
        >
            <div className={styles.Header_logoContainer}>
                {shouldShowLogo && (
                    <Link
                        className={clsx(styles.Header_logo, {
                            ["translate-x-[40px] tablet:translate-x-0"]:
                                canGoBack,
                        })}
                        to="/"
                        reloadDocument={true}
                    >
                        <Logo_White_96 />
                    </Link>
                )}
            </div>
            <div className={styles.Header_contentContainer}>
                {shouldShowProgressBlock && (
                    <ProgressBlock
                        appStore={appStore}
                        formikStore={formikStore}
                    />
                )}
            </div>
            <div className={styles.Header_authIconContainer}>
                {shouldShowAuthIcon && <AuthIcon appStore={appStore} />}
            </div>
        </div>
    );
};

export const StatelessHeader = ({ rightText }: { rightText?: string }) => {
    return (
        <div
            className={clsx(styles.Header, styles.MobileHeaderWithoutStepper)}
            id="header"
        >
            <div className={styles.Header_logoContainer}>
                <a className={styles.Header_logo} href="/">
                    <Logo_White_96 />
                </a>
            </div>
            {rightText && (
                <p className={styles.Header_rightText}>{rightText}</p>
            )}
        </div>
    );
};

export default Header;
