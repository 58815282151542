/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HelloSign from "hellosign-embedded";

import {
    SignatureErrorCodes,
    SignatureSubmitStatus,
} from "features/application-wizard";
import { AppStore } from "features/store";
import { getConfigVar } from "features/utils";
import { useDerivedState } from "store/hooks";

import helloSignStyles from "./HelloSignContainer.module.scss";

const client = new HelloSign({
    clientId: getConfigVar("REACT_APP_DROPBOX_SIGN_CLIENT_ID") as string,
    allowCancel: false,
});
export const HelloSignContainer = ({
    appStore,
    onCompleted,
}: {
    appStore: AppStore;
    onCompleted: () => void;
}) => {
    const navigate = useNavigate();
    useEffect(() => {
        const onReady = () => {
            appStore.asyncDispatch.setSignatureStatus(
                SignatureSubmitStatus.Initialized,
            );
        };

        const onError = ({ code }: { code: string }) => {
            if (code === SignatureErrorCodes.NothingToSign) {
                navigate("/", { replace: true });
            }
            appStore.asyncDispatch.setSignatureStatus(
                SignatureSubmitStatus.Failed,
            );
        };
        const onFinished = async () => {
            await appStore.asyncDispatch.setSignatureStatus(
                SignatureSubmitStatus.Submitting,
            );
            onCompleted();
        };

        client.on("ready", onReady);
        client.on("error", onError);
        client.on("sign", onFinished);

        return () => {
            client.off("ready", onReady);
            client.off("error", onError);
            client.off("sign", onFinished);
        };
    }, []);

    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                signatureStep: { signUrl, wasSigned },
            },
        }) => {
            return {
                wasSigned,
                signUrl,
            };
        },
    );
    const { signUrl, wasSigned } = getState();

    useEffect(() => {
        if (signUrl && !wasSigned) {
            client.open(signUrl, {
                skipDomainVerification: true,
                testMode: true,
                whiteLabeling: {
                    primary_button_color: "#1769ff",
                    header_background_color: "#0D0A2F",
                },
                container:
                    document.getElementById("hello-sign-container") ||
                    undefined,
            });
        }
    }, [signUrl]);

    return (
        <div className={helloSignStyles.Container}>
            <div
                id="hello-sign-container"
                className={helloSignStyles.HelloSign}
            />
        </div>
    );
};
