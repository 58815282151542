import { TFunction } from "i18next";
import * as Yup from "yup";

import {
    maxLengthValidation,
    validateEmail,
    validatePhone,
    validateZip,
} from "features/common/utils";
import { Namespace } from "i18nConfig/translation.types";

export const shippingInformationValidationSchema = (
    t: TFunction<Namespace, undefined, Namespace>,
    isShippingSameAsBusiness: boolean,
) => {
    const requiredConditionalValidation = Yup.string().when([], {
        is: () => !isShippingSameAsBusiness,
        then: Yup.string()
            .required(t("requiredValidationMessage"))
            .trim()
            .concat(maxLengthValidation(100, t)),
    });

    return Yup.object().shape({
        isShippingSameAsBusiness: Yup.bool().required(),
        shippingAddress: Yup.object().shape({
            address1: requiredConditionalValidation,
            address2: Yup.string()
                .nullable()
                .when([], {
                    is: () => !isShippingSameAsBusiness,
                    then: Yup.string()
                        .nullable()
                        .trim()
                        .concat(maxLengthValidation(100, t)),
                }),
            city: requiredConditionalValidation,
            zip: Yup.string().when([], {
                is: () => !isShippingSameAsBusiness,
                then: validateZip(
                    t("invalidZipValidationMessage"),
                    t("requiredValidationMessage"),
                ),
            }),
            state: requiredConditionalValidation,
            country: requiredConditionalValidation,
        }),
        installationTimeframe: Yup.string().required(),
        installationContact: Yup.object().shape({
            firstName: Yup.string()
                .required(
                    `${t("firstNameLabel")} ${t(
                        "requiredValidationPrefixMessage",
                    )}`,
                )
                .trim()
                .concat(maxLengthValidation(100, t)),
            lastName: Yup.string()
                .required(
                    `${t("lastNameLabel")} ${t(
                        "requiredValidationPrefixMessage",
                    )}`,
                )
                .trim()
                .concat(maxLengthValidation(100, t)),
            email: validateEmail(
                t("invalidEmailValidationMessage"),
                `${t("emailLabel")} ${t("requiredValidationPrefixMessage")}`,
            ).concat(maxLengthValidation(254, t)),
            phone: validatePhone(
                t("invalidPhoneNumberValidationMessage"),
                `${t("phoneLabel")} ${t("requiredValidationPrefixMessage")}`,
                false,
            ),
        }),
        salesTaxRate: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(t("requiredTaxValidation"))
            .max(20, t("maxTaxValidation", { value: 20 })),
    });
};
