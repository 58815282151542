import React, { useState } from "react";
import clsx from "clsx";

import photoRestaurant1 from "./images/almostDonePage_restaurant_photo1.webp";
import photoRestaurant2 from "./images/almostDonePage_restaurant_photo2.webp";
import photoRestaurant3 from "./images/almostDonePage_restaurant_photo3.webp";
import photoRetail1 from "./images/almostDonePage_retail_photo1.webp";
import photoRetail2 from "./images/almostDonePage_retail_photo2.webp";
import photoRetail3 from "./images/almostDonePage_retail_photo3.webp";

import styles from "./AlmostDoneImages.module.scss";

interface IImageProps {
    src: string;
    alt: string;
    className: string;
}

const Image: React.FC<IImageProps & Record<string, unknown>> = ({
    src,
    alt,
    className,
    ...restProps
}) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    return (
        <img
            src={src}
            alt={alt}
            className={className}
            style={{ display: isLoaded ? "block" : "none" }}
            onLoad={handleImageLoad}
            {...restProps}
        />
    );
};

export const AlmostDoneImages = ({
    isRestaurant,
}: {
    isRestaurant: boolean;
}) => {
    const [photo1, photo2, photo3] = isRestaurant
        ? [photoRestaurant1, photoRestaurant2, photoRestaurant3]
        : [photoRetail1, photoRetail2, photoRetail3];

    return (
        <div className={styles.ImagesWrapper}>
            <Image
                src={photo2}
                className={clsx(
                    styles.ImageLeft,
                    styles.ImagesWrapper_leftImage,
                )}
                alt="photo2"
            />
            <div className={styles.ImagesWrapper_verticalImages}>
                <Image
                    src={photo1}
                    className={clsx(styles.ImageTop)}
                    alt="photo1"
                />
                <Image
                    src={photo3}
                    className={clsx(styles.ImageBottom)}
                    alt="photo3"
                />
            </div>
        </div>
    );
};
