import React, { RefObject, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, Icon, Text } from "spoton-lib";

import { useTranslation } from "i18nConfig";

interface ITermsSignatureCanvas {
    mobileSignatureRef: RefObject<SignatureCanvas>;
    setTrimmedDataURL: (v: string) => void;
    setIsSigning: (v: boolean) => void;
    onStartDrawing: () => void;
    onClearDrawing: () => void;
}

export const TermsMobileSign = (props: ITermsSignatureCanvas) => {
    const {
        mobileSignatureRef,
        setTrimmedDataURL,
        setIsSigning,
        onStartDrawing,
        onClearDrawing,
    } = props;

    const [hasStartedSigning, setHasStartedSigning] = useState<boolean>(false);

    const { t } = useTranslation();

    const onTrim = () => {
        const trimmedData = mobileSignatureRef.current
            ?.getTrimmedCanvas()
            ?.toDataURL("image/png");
        if (trimmedData) {
            setTrimmedDataURL(trimmedData);
            setIsSigning(false);
            setHasStartedSigning(false);
        }
    };

    const handleClose = () => {
        setIsSigning(false);
    };

    const onBegin = () => {
        setHasStartedSigning(true);
        onStartDrawing();
    };

    const onClear = () => {
        mobileSignatureRef?.current?.clear();
        setTrimmedDataURL("");
        setHasStartedSigning(false);
        onClearDrawing();
    };

    return (
        <div className="h-[100dvh] w-screen overflow-hidden bg-white fixed top-0 left-0 z-[99]">
            <div className="h-full w-full flex flex-col justify-between">
                <div className="px-4 py-5 flex gap-4 items-center">
                    <button
                        type="button"
                        className="pt-1 h-fit"
                        onClick={handleClose}
                    >
                        <Icon size={20} name="CloseIcon" />
                    </button>
                    <Text className="font-semibold text-base leading-9 flex-grow">
                        {t("termsAndConditionsSignature")}
                    </Text>
                    {hasStartedSigning && (
                        <Text
                            onClick={onClear}
                            className="text-base text-primary-primary-60 font-semibold cursor-pointer"
                        >
                            {t("clearLabel")}
                        </Text>
                    )}
                </div>
                <div className="flex-grow flex flex-center justify-center">
                    <div className="relative flex flex-col items-center justify-center">
                        <SignatureCanvas
                            canvasProps={{
                                width: window.innerWidth - 32,
                                height: 240,
                                className: "absolute top-40",
                            }}
                            onBegin={onBegin}
                            ref={mobileSignatureRef}
                        />
                        {!hasStartedSigning && (
                            <div className="absolute top-64 w-40 h-full">
                                <Text className="text-neutral-60 pointer-events-none text-center">
                                    {t("holdToDraw")}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
                <div className="absolute z-20 p-4 bottom-0 left-0 w-full flex items-center justify-center">
                    <Button
                        disabled={!hasStartedSigning}
                        variant="primary"
                        className="w-full h-[44px]"
                        onClick={onTrim}
                    >
                        {t("saveLabel")}
                    </Button>
                </div>
            </div>
            <div className="absolute z-50 left-1/2 transform -translate-x-1/2 top-96 h-[1px] bg-gray-200 w-[327px] tablet:w-[500px]" />
        </div>
    );
};
