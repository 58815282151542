import { AxiosResponse } from "axios";

import {
    NewTaxDTO,
    SuggestedTaxes,
    TaxPreview,
} from "features/application-wizard";
import { API_ENDPOINTS, httpCore } from "features/utils";

import { IAppStateType } from "../AppStore.types";
import { defaultReducer } from "../defaultState";

export const taxesAsyncReducers = {
    async setSuggestedLocalTaxes(
        currentState: IAppStateType,
        suggestedTaxes: SuggestedTaxes,
        applyToSalesTax: boolean,
    ): Promise<typeof defaultReducer> {
        try {
            return (state: IAppStateType) => ({
                ...state,
                shippingStep: {
                    ...state.shippingStep,
                    suggestedTaxRate: suggestedTaxes,
                    salesTaxRate: applyToSalesTax
                        ? suggestedTaxes.totalRate
                        : state.shippingStep.salesTaxRate,
                },
            });
        } catch (e) {
            console.error(e);
            return defaultReducer;
        }
    },

    async getAllAdditionalTaxes(
        currentState: IAppStateType,
    ): Promise<typeof defaultReducer> {
        try {
            const { data } = await httpCore.get<TaxPreview[]>(
                API_ENDPOINTS.signup.AdditionalTaxRates,
            );
            return (state: IAppStateType) => ({
                ...state,
                shippingStep: {
                    ...state.shippingStep,
                    additionalTaxes: data,
                },
            });
        } catch (e) {
            return defaultReducer;
        }
    },
    async setAdditionalTax(
        currentState: IAppStateType,
        newTax: NewTaxDTO,
    ): Promise<typeof defaultReducer> {
        try {
            await httpCore.post<NewTaxDTO, AxiosResponse<TaxPreview>>(
                API_ENDPOINTS.signup.AdditionalTaxRates,
                newTax,
            );
            return taxesAsyncReducers.getAllAdditionalTaxes(currentState);
        } catch (e) {
            return defaultReducer;
        }
    },
    async removeAdditionalTax(
        currentState: IAppStateType,
        taxId: string,
        onCompleted: () => void,
    ): Promise<typeof defaultReducer> {
        try {
            await httpCore.delete(
                `${API_ENDPOINTS.signup.AdditionalTaxRates}${taxId}/`,
            );
            onCompleted();
            return taxesAsyncReducers.getAllAdditionalTaxes(currentState);
        } catch (e) {
            return defaultReducer;
        }
    },
};
