import React from "react";
import { Text } from "spoton-lib";

import { DebitCardSuccess } from "app/icons";
import { useTranslation } from "i18nConfig";

export const FasterFundingSuccess = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col justify-between gap-8 pb-10 tablet:flex-row">
            <div className="flex flex-col items-start gap-8 mr-0">
                <Text className="text-left" type="sub2">
                    {t("fasterFundingSuccessMessage")}
                </Text>
                <DebitCardSuccess className="w-full h-full" />
            </div>
        </div>
    );
};
