import { useEffect } from "react";
import { useFormikContext } from "formik";

export const AutoSubmit = () => {
    /*
      This component is used to automatically submit the form when the form is valid
      and has been changed(dirty).
     */
    const { isValid, values, dirty: isDirty, submitForm } = useFormikContext();

    useEffect(() => {
        if (isValid) {
            void submitForm();
        }
    }, [isValid, values, isDirty, submitForm]);

    return null;
};
