import { ICountry } from "../types";

export const countriesAndStates: ICountry[] = [
    {
        value: "CA",
        label: "CA",
        states: [
            {
                label: "Alberta",
                value: "AB",
                countryCode: "CA",
            },
            {
                label: "British Columbia",
                value: "BC",
                countryCode: "CA",
            },
            {
                label: "Manitoba",
                value: "MB",
                countryCode: "CA",
            },
            {
                label: "New Brunswick",
                value: "NB",
                countryCode: "CA",
            },
            {
                label: "Newfoundland and Labrador",
                value: "NL",
                countryCode: "CA",
            },
            {
                label: "Northwest Territories",
                value: "NT",
                countryCode: "CA",
            },
            {
                label: "Nova Scotia",
                value: "NS",
                countryCode: "CA",
            },
            {
                label: "Nunavut",
                value: "NU",
                countryCode: "CA",
            },
            {
                label: "Ontario",
                value: "ON",
                countryCode: "CA",
            },
            {
                label: "Prince Edward Island",
                value: "PE",
                countryCode: "CA",
            },
            {
                label: "Quebec",
                value: "QC",
                countryCode: "CA",
            },
            {
                label: "Saskatchewan",
                value: "SK",
                countryCode: "CA",
            },
            {
                label: "Yukon",
                value: "YT",
                countryCode: "CA",
            },
        ],
    },
    {
        value: "MX",
        label: "MX",
        states: [
            {
                label: "Aguascalientes",
                value: "AGU",
                countryCode: "MX",
            },
            {
                label: "Baja California",
                value: "BCN",
                countryCode: "MX",
            },
            {
                label: "Baja California Sur",
                value: "BCS",
                countryCode: "MX",
            },
            {
                label: "Campeche",
                value: "CAM",
                countryCode: "MX",
            },
            {
                label: "Chiapas",
                value: "CHP",
                countryCode: "MX",
            },
            {
                label: "Chihuahua",
                value: "CHH",
                countryCode: "MX",
            },
            {
                label: "Coahuila",
                value: "COA",
                countryCode: "MX",
            },
            {
                label: "Colima",
                value: "COL",
                countryCode: "MX",
            },
            {
                label: "Durango",
                value: "DUR",
                countryCode: "MX",
            },
            {
                label: "Guanajuato",
                value: "GUA",
                countryCode: "MX",
            },
            {
                label: "Guerrero",
                value: "GRO",
                countryCode: "MX",
            },
            {
                label: "Hidalgo",
                value: "HID",
                countryCode: "MX",
            },
            {
                label: "Jalisco",
                value: "JAL",
                countryCode: "MX",
            },
            {
                label: "Mexico City",
                value: "CMX",
                countryCode: "MX",
            },
            {
                label: "Michoacán",
                value: "MIC",
                countryCode: "MX",
            },
            {
                label: "Morelos",
                value: "MOR",
                countryCode: "MX",
            },
            {
                label: "México",
                value: "MEX",
                countryCode: "MX",
            },
            {
                label: "Nayarit",
                value: "NAY",
                countryCode: "MX",
            },
            {
                label: "Nuevo León",
                value: "NLE",
                countryCode: "MX",
            },
            {
                label: "Oaxaca",
                value: "OAX",
                countryCode: "MX",
            },
            {
                label: "Puebla",
                value: "PUE",
                countryCode: "MX",
            },
            {
                label: "Querétaro",
                value: "QUE",
                countryCode: "MX",
            },
            {
                label: "Quintana Roo",
                value: "ROO",
                countryCode: "MX",
            },
            {
                label: "San Luis Potosí",
                value: "SLP",
                countryCode: "MX",
            },
            {
                label: "Sinaloa",
                value: "SIN",
                countryCode: "MX",
            },
            {
                label: "Sonora",
                value: "SON",
                countryCode: "MX",
            },
            {
                label: "Tabasco",
                value: "TAB",
                countryCode: "MX",
            },
            {
                label: "Tamaulipas",
                value: "TAM",
                countryCode: "MX",
            },
            {
                label: "Tlaxcala",
                value: "TLA",
                countryCode: "MX",
            },
            {
                label: "Veracruz",
                value: "VER",
                countryCode: "MX",
            },
            {
                label: "Yucatán",
                value: "YUC",
                countryCode: "MX",
            },
            {
                label: "Zacatecas",
                value: "ZAC",
                countryCode: "MX",
            },
        ],
    },
    {
        value: "US",
        label: "US",
        states: [
            {
                label: "Alabama",
                value: "AL",
                countryCode: "US",
            },
            {
                label: "Alaska",
                value: "AK",
                countryCode: "US",
            },
            {
                label: "American Samoa",
                value: "AS",
                countryCode: "US",
            },
            {
                label: "Arizona",
                value: "AZ",
                countryCode: "US",
            },
            {
                label: "Arkansas",
                value: "AR",
                countryCode: "US",
            },
            {
                label: "Baker Island",
                value: "UM-81",
                countryCode: "US",
            },
            {
                label: "California",
                value: "CA",
                countryCode: "US",
            },
            {
                label: "Colorado",
                value: "CO",
                countryCode: "US",
            },
            {
                label: "Connecticut",
                value: "CT",
                countryCode: "US",
            },
            {
                label: "Delaware",
                value: "DE",
                countryCode: "US",
            },
            {
                label: "District of Columbia",
                value: "DC",
                countryCode: "US",
            },
            {
                label: "Florida",
                value: "FL",
                countryCode: "US",
            },
            {
                label: "Georgia",
                value: "GA",
                countryCode: "US",
            },
            {
                label: "Guam",
                value: "GU",
                countryCode: "US",
            },
            {
                label: "Hawaii",
                value: "HI",
                countryCode: "US",
            },
            {
                label: "Howland Island",
                value: "UM-84",
                countryCode: "US",
            },
            {
                label: "Idaho",
                value: "ID",
                countryCode: "US",
            },
            {
                label: "Illinois",
                value: "IL",
                countryCode: "US",
            },
            {
                label: "Indiana",
                value: "IN",
                countryCode: "US",
            },
            {
                label: "Iowa",
                value: "IA",
                countryCode: "US",
            },
            {
                label: "Jarvis Island",
                value: "UM-86",
                countryCode: "US",
            },
            {
                label: "Johnston Atoll",
                value: "UM-67",
                countryCode: "US",
            },
            {
                label: "Kansas",
                value: "KS",
                countryCode: "US",
            },
            {
                label: "Kentucky",
                value: "KY",
                countryCode: "US",
            },
            {
                label: "Kingman Reef",
                value: "UM-89",
                countryCode: "US",
            },
            {
                label: "Louisiana",
                value: "LA",
                countryCode: "US",
            },
            {
                label: "Maine",
                value: "ME",
                countryCode: "US",
            },
            {
                label: "Maryland",
                value: "MD",
                countryCode: "US",
            },
            {
                label: "Massachusetts",
                value: "MA",
                countryCode: "US",
            },
            {
                label: "Michigan",
                value: "MI",
                countryCode: "US",
            },
            {
                label: "Midway Atoll",
                value: "UM-71",
                countryCode: "US",
            },
            {
                label: "Minnesota",
                value: "MN",
                countryCode: "US",
            },
            {
                label: "Mississippi",
                value: "MS",
                countryCode: "US",
            },
            {
                label: "Missouri",
                value: "MO",
                countryCode: "US",
            },
            {
                label: "Montana",
                value: "MT",
                countryCode: "US",
            },
            {
                label: "Navassa Island",
                value: "UM-76",
                countryCode: "US",
            },
            {
                label: "Nebraska",
                value: "NE",
                countryCode: "US",
            },
            {
                label: "Nevada",
                value: "NV",
                countryCode: "US",
            },
            {
                label: "New Hampshire",
                value: "NH",
                countryCode: "US",
            },
            {
                label: "New Jersey",
                value: "NJ",
                countryCode: "US",
            },
            {
                label: "New Mexico",
                value: "NM",
                countryCode: "US",
            },
            {
                label: "New York",
                value: "NY",
                countryCode: "US",
            },
            {
                label: "North Carolina",
                value: "NC",
                countryCode: "US",
            },
            {
                label: "North Dakota",
                value: "ND",
                countryCode: "US",
            },
            {
                label: "Northern Mariana Islands",
                value: "MP",
                countryCode: "US",
            },
            {
                label: "Ohio",
                value: "OH",
                countryCode: "US",
            },
            {
                label: "Oklahoma",
                value: "OK",
                countryCode: "US",
            },
            {
                label: "Oregon",
                value: "OR",
                countryCode: "US",
            },
            {
                label: "Palmyra Atoll",
                value: "UM-95",
                countryCode: "US",
            },
            {
                label: "Pennsylvania",
                value: "PA",
                countryCode: "US",
            },
            {
                label: "Puerto Rico",
                value: "PR",
                countryCode: "US",
            },
            {
                label: "Rhode Island",
                value: "RI",
                countryCode: "US",
            },
            {
                label: "South Carolina",
                value: "SC",
                countryCode: "US",
            },
            {
                label: "South Dakota",
                value: "SD",
                countryCode: "US",
            },
            {
                label: "Tennessee",
                value: "TN",
                countryCode: "US",
            },
            {
                label: "Texas",
                value: "TX",
                countryCode: "US",
            },
            {
                label: "United States Minor Outlying Islands",
                value: "UM",
                countryCode: "US",
            },
            {
                label: "United States Virgin Islands",
                value: "VI",
                countryCode: "US",
            },
            {
                label: "Utah",
                value: "UT",
                countryCode: "US",
            },
            {
                label: "Vermont",
                value: "VT",
                countryCode: "US",
            },
            {
                label: "Virginia",
                value: "VA",
                countryCode: "US",
            },
            {
                label: "Wake Island",
                value: "UM-79",
                countryCode: "US",
            },
            {
                label: "Washington",
                value: "WA",
                countryCode: "US",
            },
            {
                label: "West Virginia",
                value: "WV",
                countryCode: "US",
            },
            {
                label: "Wisconsin",
                value: "WI",
                countryCode: "US",
            },
            {
                label: "Wyoming",
                value: "WY",
                countryCode: "US",
            },
        ],
    },
];
