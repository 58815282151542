import React, { useState } from "react";
import { colors, IconButton, Text } from "spoton-lib";

import { IPageComponentProps } from "app/types";
import { AnalyticElements, getAnalyticProps } from "app/utils";
import { ConfirmationModal, StepName, useStepNavigate } from "features";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { StepPageLayout } from "../StepPageLayout";

import styles from "./BillingSuccessPage.module.scss";

export const BillingSuccessPage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();
    const [shouldShowRemoveAccountModal, setShouldShowRemoveAccountModal] =
        useState<boolean>(false);
    const { goToTheActiveStep } = useStepNavigate({ appStore });

    const [getState] = useDerivedState(
        appStore,
        ({
            loading: {
                isGetLinkedAccountLoading,
                isDeleteLinkedAccountLoading,
                isGetLinkTokenLoading,
                isSetManualAccountLoading,
                isSetStepLoading,
            },
            data: {
                billingStep: { account },
            },
        }) => ({
            isLoading:
                isGetLinkedAccountLoading ||
                isDeleteLinkedAccountLoading ||
                isGetLinkTokenLoading ||
                isSetManualAccountLoading ||
                isSetStepLoading,
            account,
        }),
    );
    const { isLoading, account } = getState();
    const deleteLinkedAccount = async () => {
        await appStore.asyncDispatch.deleteLinkedAccount();
        setShouldShowRemoveAccountModal(false);
        goToTheActiveStep({ shouldReplace: true });
    };

    return (
        <StepPageLayout
            appStore={appStore}
            className={styles.ContentWrapper}
            id="billing-step"
            isLoading={isLoading}
            title={t("billingDepositPageTitle")}
            subtitle={
                <Text
                    type="p"
                    className={styles.BillingView_sectionDescription}
                >
                    {t("paymentconnectBankAccountDescription")}
                </Text>
            }
            formikStore={formikStore}
            width="medium"
        >
            <div className="flex gap-6 flex-col pt-3">
                <div className={styles.BillingView_linkedAccountWrapper}>
                    <ConfirmationModal
                        shouldShowConfirmationModal={
                            shouldShowRemoveAccountModal
                        }
                        setShouldShowConfirmationModal={
                            setShouldShowRemoveAccountModal
                        }
                        onConfirm={deleteLinkedAccount}
                        modalTitle={t("removeAccountModalTitle")}
                        modalDescription={t("removeAccountModalDescription")}
                        confirmButtonLabel={t("deleteButtonLabel")}
                    />
                    <Text
                        type="p"
                        isBold
                        className={styles.BillingView_linkedAccountSubtitle}
                    >
                        {t("linkedAccountSubTitle")}
                    </Text>
                    <div className={styles.BillingView_linkedAccountItem}>
                        <Text type="sub2">{account?.accountName}</Text>
                        <div
                            className={
                                styles.BillingView_linkedAccountItemAction
                            }
                        >
                            <Text type="sub2" color={colors.base80}>
                                xxxxxxxxxxxxx
                                {account?.mask}
                            </Text>
                            <IconButton
                                className={
                                    styles.BillingView_removeLinkedAccountBtn
                                }
                                alt="bin"
                                disableBorder
                                variant="secondary"
                                name="DeleteIcon"
                                onClick={() =>
                                    setShouldShowRemoveAccountModal(true)
                                }
                                selectedName="DeleteAccount"
                                color={colors.danger70}
                                {...getAnalyticProps(
                                    AnalyticElements.DeleteLinkedAccountButton,
                                    { step: StepName.BillingSuccess },
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </StepPageLayout>
    );
};
