import React from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@spotoninc/nexus-react";

import { formatPercent } from "../../helpers";

export const TotalRateSummary = ({
    isLoading,
    totalRate,
}: {
    isLoading?: boolean;
    totalRate: number | undefined;
}) => {
    const { t } = useTranslation();
    return (
        <div className="flex justify-between items-center">
            <span>{t("totalTaxRate")}</span>
            <span>{isLoading ? <Skeleton /> : formatPercent(totalRate)}</span>
        </div>
    );
};
