import React, { ComponentProps, ReactNode } from "react";
import { Modal as SpotonLibModal } from "spoton-lib";
export { ModalFooter, ModalHeader } from "spoton-lib";

// modify props types of the Modal since couple of them are missing
export interface IModalProps extends ComponentProps<typeof SpotonLibModal> {
    children: ReactNode;
    isOpen: boolean;
    onRequestClose?: () => void;
    overlayClassName?: string;
    className?: string;
    ariaHideApp?: boolean;
}

export const Modal = (props: IModalProps) => {
    return <SpotonLibModal {...props} />;
};
