/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useField } from "formik";
import { TextArea } from "spoton-lib";

import { ITextAreaFieldProps } from "./TextAreaField.types";

export function TextAreaField<T>(props: ITextAreaFieldProps<T>) {
    const {
        id,
        name,
        label,
        className,
        textAreaClassName,
        formik: { handleChange, handleBlur },
        value,
        onChange = () => ({}),
        onBlur = () => ({}),
        onFocus = () => ({}),
        isDisabled,
        isValid,
        primaryCondition,
        errorMessage,
        type,
        placeholder,
    } = props;

    const [field, meta] = useField(name);

    const onFocusField = (e: React.SyntheticEvent<Element, Event>) => {
        onFocus(e);
    };

    const onBlurField = (e: React.SyntheticEvent<Element, Event>) => {
        handleBlur(e);
        onBlur(e);
    };

    const onChangeField = (e: any): void => {
        handleChange(e);
        onChange(e);
    };

    return (
        <TextArea
            {...field}
            id={id || `${name}-field`}
            value={value}
            onChange={onChangeField}
            onBlur={onBlurField}
            onFocus={onFocusField}
            className={className}
            textAreaClassName={textAreaClassName}
            label={label}
            isValid={isValid ? isValid : meta.touched ? !meta.error : true}
            disabled={isDisabled}
            primaryCondition={primaryCondition}
            secondaryCondition={
                errorMessage ? errorMessage : (meta.touched && meta.error) || ""
            }
            isMultilineSecondaryCondition={true}
            type={type}
            placeholder={placeholder}
        />
    );
}

export default TextAreaField;
