import heic2any from "heic2any";

export const isPdfByType = (file: File) => {
    return file?.type.includes("pdf");
};

export const convertToCamelCase = (input: string) => {
    return input.replace(/[-_](.)/g, (_, char) => char.toUpperCase());
};

export const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + "...";
};

export const convertHeicToJpg = async (file: File) => {
    const blob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 1,
    });
    return blob;
};
