import React from "react";

import photoRestaurant1 from "./images/022223_bar_7527_with_screen.webp";
import photoRestaurant2 from "./images/022223_casualdining_5399.webp";
import photoRestaurant3 from "./images/022223_finedining_6531_with_screen.webp";
import photoRetail3 from "./images/rectangle_139022.webp";
import photoRetail1 from "./images/rectangle_139916.webp";
import photoRetail2 from "./images/rectangle_139917.webp";

import styles from "./SuccessImages.module.scss";

export const SuccessImages = ({ isRestaurant }: { isRestaurant: boolean }) => {
    const [photo1, photo2, photo3] = isRestaurant
        ? [photoRestaurant1, photoRestaurant2, photoRestaurant3]
        : [photoRetail1, photoRetail2, photoRetail3];
    return (
        <div className={styles.Wrapper}>
            <div className={styles.Images}>
                <img src={photo1} className={styles.TopImage} alt="photo1" />
                <img src={photo2} className={styles.BottomImage} alt="photo2" />
                <img src={photo3} className={styles.MidImage} alt="photo3" />
            </div>
        </div>
    );
};
