import React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { IPageComponentProps } from "app/types";
import { Stepper } from "features/common";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { onClickIndex, prepareStepsViews } from "./ProgressBlock.utils";

import styles from "./ProgressBlock.module.scss";

export const ProgressBlock = ({
    appStore,
    formikStore,
    className,
}: IPageComponentProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [getState] = useDerivedState(appStore, ({ data }) => {
        const {
            progressBlock: {
                view: { steps },
                navigation: { activeStepName: activeStepName },
            },
        } = data;

        const { steps: extendedSteps, activeStep } = prepareStepsViews({
            steps,
            activeStepName,
            onClickIndex: (stepName) =>
                onClickIndex(appStore, formikStore, navigate, stepName),
            t,
        });
        return {
            activeStep,
            steps: extendedSteps,
        };
    });
    const { activeStep, steps } = getState();

    return (
        <div className={clsx(styles.ProgressBlockWrapper, className)}>
            <div className={styles.ProgressBlock}>
                <Stepper activeStep={activeStep} steps={steps} />
            </div>
        </div>
    );
};
