import { IShippingStep } from "../application-wizard";

export enum ShippingStepAdjustMode {
    FormIn = "formIn",
    FormOut = "formOut",
}

export const adjustRate = <T extends number | undefined | null>(
    rate: T,
    mode: ShippingStepAdjustMode,
): T => {
    const props =
        mode === ShippingStepAdjustMode.FormIn
            ? { factor: 100, rounding: 3 }
            : { factor: 0.01, rounding: 5 };
    const { factor, rounding } = props;
    if (rate === undefined || rate === null) return rate;
    return Number((rate * factor).toFixed(rounding)) as T;
};

export const shippingStepFormAdjust = (
    values: IShippingStep,
    mode: ShippingStepAdjustMode,
) => {
    const newRate = adjustRate(values.salesTaxRate, mode);
    return {
        ...values,
        salesTaxRate: newRate,
    };
};
