import { parsePhoneNumber } from "react-phone-number-input";

export const convertPhoneNumberToUsFormat = (
    input: string | undefined,
): string => {
    if (!input) {
        return "";
    }
    try {
        const { number, countryCallingCode } = parsePhoneNumber(input, {
            defaultCountry: "US",
        }) || { number: "" };
        return number && number.length === 12 && countryCallingCode === "1"
            ? number
            : "";
    } catch (error) {
        return "";
    }
};
