import React, { useEffect, useMemo } from "react";
import { RouterProvider } from "react-router-dom";
import { NexusProvider } from "@spotoninc/nexus-react";
import clsx from "clsx";

import { createRouter } from "app/routes";
import { fullstory } from "features";
import { AppStore, FormikStore } from "features/store";
import { useDerivedState } from "store/hooks";

import "i18nConfig/i18n.config";

import styles from "./App.module.scss";

export const App = () => {
    // global store for entire application
    const appStore = useMemo(() => new AppStore(), []);
    const formikStore = useMemo(() => new FormikStore(), []);
    const storeProps = {
        appStore,
        formikStore,
    };

    const getState = useDerivedState(appStore, ({ data: { profile } }) => ({
        profile,
    }))[0];
    const { profile } = getState();

    useEffect(() => {
        if (!profile?.email) {
            return;
        }

        const { firstName, email, lastName } = profile;

        fullstory.identify(String(email));

        fullstory.setUserVars({
            firstName,
            lastName,
            email,
        });
    }, [profile]);

    return (
        <NexusProvider theme={{ name: "light" }}>
            {({ theme }) => (
                <div className={clsx(styles.App, theme.className)}>
                    <div className={styles.AppContent}>
                        <RouterProvider router={createRouter(storeProps)} />
                    </div>
                </div>
            )}
        </NexusProvider>
    );
};
