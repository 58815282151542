import { useEffect } from "react";

import { AnalyticEvents } from "app/utils";

export const useSendEventHeap = (
    eventName: AnalyticEvents,
    properties?: Record<string, string | number | undefined>,
) => {
    useEffect(() => {
        window.heap?.track(eventName, properties);
    }, []);
};
