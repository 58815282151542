import { IContact } from "features/application-wizard";
import { IGenericStep } from "features/store";

export interface IShippingAddress {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    street: string;
}

export type SuggestedTax = {
    rate: number;
    name: string;
    type: string;
};

export type NewTaxDTO = {
    name: string;
    rate: number;
};

export type TaxPreview = NewTaxDTO & {
    id: string;
};

export type SuggestedTaxes = {
    rates: SuggestedTax[];
    totalRate?: number;
    zipCode: string;
};

export interface IShippingStep extends IGenericStep {
    shippingAddress: IShippingAddress;
    isShippingSameAsBusiness: boolean;
    installationTimeframe: InstallationTimeframe;
    installationContact: IContact;
    suggestedTaxRate?: SuggestedTaxes;
    salesTaxRate: number | undefined;
    additionalTaxes: TaxPreview[];
}

export enum InstallationTimeframe {
    Immediately = "immediately",
    WithinMonth = "within month",
    Within2and3Months = "within 2-3 months",
    Within3and6Months = "within 3-6 months",
    Over6Months = "over 6 months",
}
