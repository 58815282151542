import React, { useEffect, useRef } from "react";
import { components, MenuListProps } from "react-select";

import { getCSSVariable } from "./SelectField.utils";

const ReactSelectMenuList = components.MenuList;

type ListMenuProps = MenuListProps;

export const MenuList = (props: ListMenuProps) => {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const footerHeight = parseInt(getCSSVariable("--footer"));
        const { bottom } = ref.current?.getBoundingClientRect() || {
            bottom: 0,
        };
        if (bottom > window.innerHeight - footerHeight && ref.current) {
            ref.current.style.paddingBottom = `${footerHeight}px`;
            ref.current.scrollIntoView(false);
        }
    }, []);
    return (
        <div ref={ref}>
            <ReactSelectMenuList {...props} />
        </div>
    );
};
