import moment from "moment";

import { IOwnersStep, IOwnerType } from "features/application-wizard";
import { DateFormat, StepName, StepStatus } from "features/common";
import {
    API_ENDPOINTS,
    convertPhoneNumberToUsFormat,
    httpCore,
} from "features/utils";

import { IAppStateType } from "../AppStore.types";
import { defaultOwnersStep, defaultReducer } from "../defaultState";
import { getStepperState } from "../helpers";

// clean the data for incoming and outgoing OwnerStep to match format of BE
export function cleanOwnersStep(
    data: IOwnersStep,
    isOutbound = false,
): IOwnersStep {
    const ownersStep = { ...data } as IOwnersStep;
    const owners = ownersStep.owners
        .filter((owner) => !owner.isDeleted)
        .map((owner) => ({ ...owner }));
    for (const owner of owners) {
        if (owner.dob) {
            owner.dob = owner.dob.replace(/-/g, "/");
            owner.dob = moment(new Date(`${owner.dob} GMT-0800`)).format(
                isOutbound ? DateFormat.YYYY_MM_DD : DateFormat.MM_DD_YYYY,
            );
        }
        const ownerPhone = owner.contact.phone;
        if (ownerPhone) {
            owner.contact.phone =
                convertPhoneNumberToUsFormat(ownerPhone) || "";
        }
    }

    return {
        ...ownersStep,
        owners,
    };
}

export function sortById(owners: IOwnerType[]): IOwnerType[] {
    return owners.sort((a, b) => a.id - b.id);
}

export const ownersStepReducers = {
    setOwnersStep(state: IAppStateType, ownersStep: IOwnersStep) {
        return {
            ...state,
            ownersStep: {
                ...state.ownersStep,
                ...ownersStep,
            },
        };
    },

    setOwners(state: IAppStateType, owners: IOwnerType[]) {
        return {
            ...state,
            ownersStep: {
                ...state.ownersStep,
                owners: [...owners],
            },
        };
    },
};

export const ownersStepAsyncReducers = {
    async getOwnersStep(currentState: IAppStateType) {
        // fetch owners
        const response = await httpCore.get(API_ENDPOINTS.signup.OwnersStep);
        if (response.status !== 200) return defaultReducer;

        const owners = sortById(response.data.owners as IOwnerType[]).map(
            (owner) => ({
                ...owner,
                phone: convertPhoneNumberToUsFormat(owner.contact.phone),
            }),
        );

        owners.forEach((item: IOwnerType) => {
            const { ssn } = item;
            item.ssnNotFormatted = ssn;
            item.ssn = ssn;
        });

        const stepStatus = currentState.ownersStep.stepStatus;

        let isMoreThan25: boolean | undefined;
        if (stepStatus === StepStatus.Complete && owners.length === 0) {
            isMoreThan25 = false;
        } else isMoreThan25 = owners.length > 0;

        if (!owners.length) {
            return (state: IAppStateType) => ({
                ...state,
                ownersStep: {
                    ...defaultOwnersStep(),
                    stepStatus,
                    isMoreThan25,
                },
            });
        }

        return (state: IAppStateType) => ({
            ...state,
            ownersStep: cleanOwnersStep({
                ...state.ownersStep,
                owners,
                isMoreThan25,
            }),
        });
    },

    async updateOwnersStep(currentState: IAppStateType, data: IOwnersStep) {
        const ownersStepData = cleanOwnersStep({ ...data }, true);

        ownersStepData.owners.forEach((owner) => {
            if ("ssnNotFormatted" in owner) {
                delete owner.ssnNotFormatted;
            }
            owner.isOwner = true;
        });
        const owners = ownersStepData.isMoreThan25
            ? [...ownersStepData.owners]
            : [];

        const payload = {
            owners,
            stepName: StepName.Owners,
            stepStatus: StepStatus.Complete,
        } as IOwnersStep;

        try {
            const response = await httpCore.post(
                API_ENDPOINTS.signup.OwnersStep,
                payload,
            );
            const { stepper } = response.data;

            return (state: IAppStateType) => ({
                ...state,
                ...getStepperState({ state, stepper }),
                ownersStep: cleanOwnersStep({
                    ...state.ownersStep,
                    ...ownersStepData,
                    owners,
                    stepStatus: StepStatus.Complete,
                }),
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
};
