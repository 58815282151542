import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { TFunction } from "i18next";
import * as Yup from "yup";
import { AnyObject } from "yup/lib/types";

import { Namespace } from "i18nConfig/translation.types";

export const validateEmail = (
    invalidMessage: string,
    requiredMessage: string,
) => {
    return Yup.string()
        .matches(
            /^([\w-+]+(\.[\w-+]+)*)@(([\w-]+\.)+)([a-zA-Z]{2,63})$/,
            invalidMessage,
        )
        .required(requiredMessage)
        .trim()
        .nullable();
};

type CountryCode = Parameters<typeof isValidPhoneNumber>[1];

export const validatePhoneNumber = (
    value: string,
    country: CountryCode = "US",
) => {
    if (!value) return false;
    return (
        isValidPhoneNumber(value, country) &&
        /**
         * It seems that +1 345 678 9854 is a valid phone number for Cayman Islands
         * even though it is not a valid phone number for US.
         * So we need to check if the country code is US.
         */
        parsePhoneNumber(value, country)?.country === country
    );
};

export const getPhoneValidation = (
    isOptional: boolean,
    requiredMessage?: string,
) => {
    return (value: string | undefined, options: AnyObject) => {
        if (value) {
            return validatePhoneNumber(value);
        } else {
            return (
                isOptional ||
                options.createError({
                    path: options.path,
                    message: requiredMessage,
                })
            );
        }
    };
};

export const validatePhone = (
    invalidMessage: string,
    requiredMessage: string,
    isOptional: boolean,
) =>
    Yup.string().test(
        "validNumber",
        invalidMessage,
        getPhoneValidation(isOptional, requiredMessage),
    );

export const validateZip = (
    invalidMessage: string,
    requiredMessage: string,
) => {
    return Yup.string()
        .required(requiredMessage)
        .matches(/^[0-9]+$/, invalidMessage)
        .min(5, invalidMessage)
        .max(5, invalidMessage)
        .notOneOf(["00000"], invalidMessage);
};

export const maxLengthValidation = (
    length: number,
    t: TFunction<Namespace, undefined, Namespace>,
) => Yup.string().max(length, t("validationLength", { length }));
