import {
    IBusinessStep,
    LegalBusinessStepDTO,
} from "features/application-wizard";

export const replaceAndCheckLegalValues = (
    legalValues: LegalBusinessStepDTO,
    businessStep: IBusinessStep,
    isLegalAddressSame: boolean,
    isLegalIrsAddressSame: boolean,
    isProprietor: boolean,
): LegalBusinessStepDTO => {
    const { dbaContact, dbaAddress } = businessStep;

    const { businessContact, businessAddress } = legalValues;

    const { businessName, firstName, lastName } = businessContact;

    const combinedNames = `${firstName} ${lastName}`;

    if (isLegalIrsAddressSame && isLegalAddressSame) {
        const irsName = isProprietor ? combinedNames : businessName;

        return {
            ...legalValues,
            businessContact: {
                ...dbaContact,
                businessName: isProprietor ? irsName : businessName,
                firstName,
                lastName,
            },
            irsName,
            businessAddress: dbaAddress,
            irsAddress: dbaAddress,
        };
    }

    const updatedLegalValues: LegalBusinessStepDTO =
        structuredClone(legalValues);

    if (isLegalAddressSame) {
        updatedLegalValues.businessAddress = { ...dbaAddress };
    }

    if (isLegalIrsAddressSame) {
        updatedLegalValues.irsAddress = { ...businessAddress };
    }

    if (isProprietor) {
        updatedLegalValues.businessContact.businessName = combinedNames;
        updatedLegalValues.irsName = combinedNames;
    } else {
        updatedLegalValues.irsName = businessContact.businessName;
    }

    return updatedLegalValues;
};

export const getValueWithSpacing = (
    names: string[],
    internalSep = " ",
    lastSep = ", ",
) => {
    return names.join(internalSep) + (names.length ? lastSep : "");
};
