import React from "react";
import clsx from "clsx";

import { Header, StatelessHeader } from "../Header";
import { IPageLayoutProps } from "./PageLayout.types";

import styles from "./PageLayout.module.scss";

export const PageLayout = (props: IPageLayoutProps) => {
    const {
        children,
        appStore,
        formikStore,
        shouldShowProgressBlock,
        shouldShowAuthIcon,
        className,
    } = props;

    return (
        <div
            className={clsx(
                styles.PageContainer,
                {
                    [styles.HeaderWithoutStepper]: !shouldShowProgressBlock,
                },
                className,
            )}
        >
            <Header
                appStore={appStore}
                formikStore={formikStore}
                shouldShowProgressBlock={shouldShowProgressBlock}
                shouldShowAuthIcon={shouldShowAuthIcon}
            />
            <div
                id="content"
                className={clsx(styles.ContentContainer, "ContentContainer")}
            >
                <div
                    className={clsx(
                        styles.Content,
                        "Content",
                        "mt-[var(--header)]",
                        "mb-[var(--footer)]",
                        "has-[.withoutFooter]:mb-0",
                    )}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export const StatelessPageLayout = ({
    children,
    rightText,
}: {
    children: React.ReactNode;
    rightText?: string;
}) => {
    return (
        <div
            className={clsx(
                styles.PageContainer,
                styles.HeaderWithoutStepper,
                "h-[100svh]",
            )}
        >
            <StatelessHeader rightText={rightText} />
            <div id="content" className={clsx(styles.ContentContainer)}>
                <div
                    className={clsx(
                        styles.Content,
                        "Content",
                        "mt-[var(--header)]",
                        "mb-[var(--footer)]",
                        "has-[.withoutFooter]:mb-0",
                    )}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
