import { AppStore } from "features/store";

export type UploadDialogProps = {
    onUploaded: (file: File | null) => void;
    onClosed: () => void;
    isOpen: boolean;
    isLoading?: boolean;
};

export type ModifiedWebcamProps = {
    className?: string;
    isLoading?: boolean;
    imageOnLoading?: string | null;
    deviceId: string | undefined;
    isVideoAllowed?: boolean;
};

export type CheckPhotoCameraProps = {
    className?: string;
    appStore: AppStore;
    onCapture: () => void;
    isLoading?: boolean;
    imageOnLoading?: string | null;
    onUploaded: (file: File | undefined) => void;
    deviceId: string | undefined;
    onSwitchCamera?: () => void;
    devices: MediaDeviceInfo[];
    isVideoAllowed?: boolean;
};

export enum MediaDevice {
    VideoInput = "videoinput",
    AudioOutput = "audiooutput",
    AudioInput = "audioinput",
}

export enum PermissionState {
    Denied = "denied",
    Granted = "granted",
    Prompt = "prompt",
}
