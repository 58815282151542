/* eslint-disable react/prop-types */
import React from "react";
import { AxiosError } from "axios";

import { ErrorMessage } from "./ErrorMessage.component";
interface IProps {
    className?: string;
    children?: React.ReactNode;
}

interface IState {
    errorStatus: number | null;
}

// NOTE: using class based component ONLY because we need support for error boundaries
export class ErrorBoundaryRequest extends React.Component<IProps> {
    state: IState = { errorStatus: null };

    componentDidCatch(error: Error): void {
        if (this.isAxiosError(error) && error.response) {
            this.setState({ errorStatus: error.response.status });
        } else {
            throw error;
        }
    }

    isAxiosError = (error: Error): error is AxiosError =>
        "isAxiosError" in error;

    renderNotFound = () => {
        return (
            <ErrorMessage
                className={this.props.className}
                message="oopsNotFoundErrorText"
            />
        );
    };

    renderForbidden = () => {
        return (
            <ErrorMessage
                className={this.props.className}
                message="forbiddenErrorText"
            />
        );
    };

    renderServerError = () => {
        return (
            <ErrorMessage
                className={this.props.className}
                message="oopsTryAgainErrorText"
            />
        );
    };

    render() {
        const { errorStatus } = this.state;

        if (errorStatus && errorStatus >= 500) {
            return this.renderServerError();
        } else if (errorStatus === 403) {
            return this.renderForbidden();
        } else if (errorStatus === 404) {
            return this.renderNotFound();
        }

        return <>{this.props.children}</>;
    }
}

export default ErrorBoundaryRequest;
