import { AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment";

import {
    convertPhoneNumberToUsFormat,
    IStepper,
    removeNullableValues,
} from "features";
import {
    EmployeeRange,
    generalBusinessKeysFromDTO,
    GeneralBusinessStepDTO,
    IBusinessStep,
    legalBusinessKeysFromDTO,
    LegalBusinessStepDTO,
} from "features/application-wizard";
import { DateFormat, StepName, StepStatus } from "features/common";
import {
    API_ENDPOINTS,
    httpCore,
    transformServiceResponseSimple,
} from "features/utils";

import { IAppStateType, IGenericStep } from "../AppStore.types";
import { defaultAddress, defaultReducer } from "../defaultState";
import { getStepperState } from "../helpers";

function parseDollarString(value?: string | number): string | undefined {
    if (value === undefined) return;
    if (typeof value === "number") return value.toString();
    return value.slice(1);
}

// clean the data for incoming and outgoing businessStep to match format of BE
function cleanBusinessStep(
    data: GeneralBusinessStepDTO | LegalBusinessStepDTO,
    isOutbound = false,
): IBusinessStep {
    const businessStep = { ...data } as IBusinessStep;
    let { businessOpenDate } = businessStep;

    let withParsedValues: IBusinessStep = {
        ...businessStep,
        dbaContact: {
            ...businessStep.dbaContact,
            phone:
                convertPhoneNumberToUsFormat(businessStep.dbaContact.phone) ||
                "",
        },
    };

    if (!businessStep.irsAddress) {
        withParsedValues = {
            ...withParsedValues,
            irsAddress: {
                ...defaultAddress,
            },
        };
    }

    if (isOutbound) {
        withParsedValues = {
            ...withParsedValues,
            requestedMonthlySales: parseDollarString(
                businessStep.requestedMonthlySales,
            ),
            averageTicket: parseDollarString(businessStep.averageTicket),
            requestedHighestTicket: parseDollarString(
                businessStep.requestedHighestTicket,
            ),
        };
    }

    if (businessOpenDate) {
        businessOpenDate = businessOpenDate.replace(/-/g, "/");
        businessOpenDate = moment(
            new Date(`${businessOpenDate} GMT-0800`),
        ).format(isOutbound ? DateFormat.YYYY_MM_DD : DateFormat.MM_DD_YYYY);

        return {
            ...withParsedValues,
            businessOpenDate,
        };
    }
    return { ...withParsedValues };
}

const cleanGeneralBusinessPayload = (data: GeneralBusinessStepDTO) => {
    return _.pick(data, generalBusinessKeysFromDTO) as GeneralBusinessStepDTO;
};

const cleanLegalBusinessPayload = (data: LegalBusinessStepDTO) => {
    return _.pick(data, legalBusinessKeysFromDTO) as LegalBusinessStepDTO;
};

export const businessStepReducers = {};

export const businessStepAsyncReducers = {
    async getBusinessStep(currentState: IAppStateType) {
        const response = await httpCore.get(API_ENDPOINTS.signup.BusinessStep, {
            transformResponse:
                transformServiceResponseSimple(removeNullableValues),
        });

        try {
            let businessStep = response.data;

            const { federalTaxId } = businessStep;

            businessStep = {
                ...businessStep,
                employeeCount: businessStep.employeeCount || EmployeeRange.Max5,
                federalTaxIdNotFormatted: federalTaxId,
            };
            return (state: IAppStateType) => ({
                ...state,
                businessStep: {
                    ...state.businessStep,
                    ...cleanBusinessStep(businessStep),
                },
            });
        } catch (error) {
            console.error("An error occurred:", error);
            return defaultReducer;
        }
    },

    async updateBusinessGeneralStep(
        currentState: IAppStateType,
        data: GeneralBusinessStepDTO,
    ) {
        data = cleanBusinessStep(data, true);

        const cleanedGeneralBusinessData = cleanGeneralBusinessPayload(data);

        try {
            const response = await httpCore.patch<
                unknown,
                AxiosResponse<{ stepper: IStepper } & IBusinessStep>,
                IGenericStep & GeneralBusinessStepDTO
            >(API_ENDPOINTS.signup.BusinessStep, {
                ...cleanedGeneralBusinessData,
                stepName: StepName.Business,
                stepStatus: StepStatus.Complete,
            });

            const { stepper, ...businessStep } = response.data;

            return (state: IAppStateType) => ({
                ...state,
                ...getStepperState({ state, stepper }),
                businessStep: {
                    ...state.businessStep,
                    ...cleanBusinessStep(businessStep),
                },
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },

    async updateLegalBusinessStep(
        currentState: IAppStateType,
        data: LegalBusinessStepDTO,
    ) {
        data = cleanBusinessStep(data, true);

        const cleanedLegalBusinessData = cleanLegalBusinessPayload(data);

        try {
            const response = await httpCore.patch<
                unknown,
                AxiosResponse<{ stepper: IStepper } & IBusinessStep>,
                IGenericStep & LegalBusinessStepDTO
            >(API_ENDPOINTS.signup.BusinessStep, {
                ...cleanedLegalBusinessData,
                stepName: StepName.BusinessLegal,
                stepStatus: StepStatus.Complete,
            });

            const { stepper, ...businessStep } = response.data;

            return (state: IAppStateType) => ({
                ...state,
                ...getStepperState({ state, stepper }),
                businessStep: {
                    ...state.businessStep,
                    ...cleanBusinessStep(businessStep),
                },
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
};
