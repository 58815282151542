import { TFunction } from "i18next";
import * as Yup from "yup";

import {
    maxLengthValidation,
    validateEmail,
    validatePhone,
    validateZip,
} from "features/common/utils";
import { Namespace } from "i18nConfig";

export const notNeededInstallation = {
    businessName: "notNeeded",
    email: "notNeeded@notNeeded.com",
    firstName: "notNeeded",
    lastName: "notNeeded",
    phone: "+12345678900",
};

const urlRegex =
    /^(?:(?:https?:\/\/)?(?:www\.)?|www\.)[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(?::\d+)?(?:\/[^/?#]+)*(?:\?[^#]*)?(?:#.*)?$/;

export const getBusinessInformationValidationSchema = (
    t: TFunction<Namespace, undefined, Namespace>,
) =>
    Yup.object().shape({
        // DBA information
        dbaContact: Yup.object().shape({
            businessName: Yup.string()
                .required(
                    `${t("dbaName")} ${t("requiredValidationPrefixMessage")}`,
                )
                .trim()
                .concat(maxLengthValidation(100, t)),
            phone: validatePhone(
                t("invalidPhoneNumberValidationMessage"),
                `${t("businessPhoneLabel")} ${t(
                    "requiredValidationPrefixMessage",
                )}`,
                false,
            ).trim(),
            email: validateEmail(
                t("invalidEmailValidationMessage"),
                `${t("emailLabel")} ${t("requiredValidationPrefixMessage")}`,
            ).concat(maxLengthValidation(254, t)),
        }),
        dbaAddress: Yup.object().shape({
            address1: Yup.string()
                .required(
                    `${t("address1Label")} ${t(
                        "requiredValidationPrefixMessage",
                    )}`,
                )
                .trim()
                .concat(maxLengthValidation(100, t)),
            address2: Yup.string()
                .nullable()
                .concat(maxLengthValidation(100, t)),
            city: Yup.string()
                .required(
                    `${t("cityLabel")} ${t("requiredValidationPrefixMessage")}`,
                )
                .trim()
                .concat(maxLengthValidation(100, t)),
            state: Yup.string().required(
                `${t("stateLabel")} ${t("requiredValidationPrefixMessage")}`,
            ),
            country: Yup.string().required(
                `${t("countryLabel")} ${t("requiredValidationPrefixMessage")}`,
            ),
            zip: validateZip(
                t("invalidZipValidationMessage"),
                t("requiredValidationMessage"),
            ),
        }),
        website: Yup.string()
            .matches(urlRegex, t("invalidUrlValidationMessage"))
            .nullable()
            .concat(maxLengthValidation(300, t)),
        requestedMonthlySales: Yup.string().required(
            t("requiredValidationMessage"),
        ),
        averageTicket: Yup.string().required(t("requiredValidationMessage")),
        requestedHighestTicket: Yup.string().required(
            t("requiredValidationMessage"),
        ),
    });
