import React from "react";
import { Text } from "spoton-lib";

import { AppStore } from "features/store";
// types
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { useScrollTracker } from "../utils";

interface ITermsContentProps {
    appStore: AppStore;
    isTermsScrolled: boolean;
    setIsTermsScrolled: (v: boolean) => void;
}

// Component
export const TermsContent = (props: ITermsContentProps) => {
    const { appStore, isTermsScrolled, setIsTermsScrolled } = props;
    // translations
    const { t } = useTranslation();

    const [getState] = useDerivedState(
        appStore,
        ({ data: { termsAndConditionsStep } }) => ({
            termsAndConditionsStep,
        }),
    );
    const { termsAndConditionsStep } = getState();

    const { tcHtml } = termsAndConditionsStep;

    const { termsRef, gradientOpacity } = useScrollTracker(
        isTermsScrolled,
        setIsTermsScrolled,
    );

    const gradientStyle = {
        maskImage:
            gradientOpacity > 0.09
                ? `linear-gradient(to bottom, transparent 0%, black ${
                      gradientOpacity * 200
                  }%)`
                : `linear-gradient(to bottom, black ${
                      gradientOpacity * 1200
                  }%, transparent 100%)`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        WebkitMaskImage:
            gradientOpacity > 0.09
                ? `linear-gradient(to bottom, transparent 0%, black ${
                      gradientOpacity * 200
                  }%)`
                : `linear-gradient(to bottom, black ${
                      gradientOpacity * 1200
                  }%, transparent 100%)`,
    };

    return (
        <>
            <Text type="h5">{t("termsAndConditionsContract")}</Text>
            <div
                ref={termsRef}
                style={gradientStyle}
                className="relative w-full h-[560px] mt-6 p-4 pt-2 tablet:pt-4 tablet:px-6 overflow-y-auto bg-icon-background rounded-xl"
            >
                {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
                <div dangerouslySetInnerHTML={{ __html: tcHtml }} />
            </div>
        </>
    );
};
