/**
 * A short note on FullStory masking classes/rules from the docs:
 *
 * .fs-mask - use this HTML class name for masked elements containing text,
 *   all text will be replaced by irreversibly-transformed placeholder text,
 *   meant to resemble a wireframe of the original content.
 *
 * .fs-exclude - use this HTML class name for excluded elements,
 *   the element itself (as well as any child elements) will be ignored by recording.
 *   All excluded elements are replaced in playback by rectangles containing diagonal
 *   grey and white stripes. Events that target excluded elements (click or change, for example)
 *   are ignored entirely. If understanding end-user interaction with an element is important,
 *   then using Mask is preferable to Exclude.
 *
 * Read more at:
 * https://help.fullstory.com/hc/en-us/articles/360020623574-How-do-I-protect-my-users-privacy-in-FullStory-#exclude-vs-mask-vs-unmask
 */

import * as FS from "@fullstory/browser";

import { getConfigFlag, getConfigVar } from "./config.utils";

const orgId = getConfigVar("REACT_APP_FULLSTORY_ORG_ID");

let isInitialized = false;
export const fullstory = {
    init: (): void => {
        if (!orgId) {
            return;
        }
        FS.init({
            orgId: String(orgId),
            devMode: getConfigFlag("REACT_APP_FULLSTORY_DEV_MODE"),
            debug: false,
        });
        isInitialized = true;
    },
    identify: (uid: string, customVars?: FS.UserVars): void => {
        if (!isInitialized) {
            return;
        }
        FS.identify(uid, customVars);
    },
    setUserVars: (customVars: FS.UserVars): void => {
        if (!isInitialized) {
            return;
        }
        FS.setUserVars(customVars);
    },
    fireEvent: (
        eventName: string,
        eventData: {
            [key: string]: any;
        },
    ): void => {
        if (!isInitialized) {
            return;
        }
        FS.event(eventName, eventData);
    },
};
export default fullstory;
