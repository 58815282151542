/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { createPortal } from "react-dom";
import Webcam from "react-webcam";
import clsx from "clsx";
import { Button, Icon } from "spoton-lib";

import { FlashIcon, SwitchCamera } from "app/icons";
import { AnalyticElements, getAnalyticProps } from "app/utils";
import { BillingViews } from "features/store";
import { acceptedFileTypes } from "features/utils";
import { useTranslation } from "i18nConfig";

import { StepName } from "../Stepper";
import { BlurredLoader } from "./BlurredLoader.component";
import { CheckPhotoCameraProps } from "./CheckPhotoCamera.types";
import { CustomWebcam } from "./CustomWebcam.component";
import { Frame } from "./Frame.component";
import {
    MissingCameraInfo,
    NoAccessInfo,
    PlaceCameraInfo,
} from "./UserMessages";

import styles from "./CheckPhotoCamera.module.scss";

const CheckPhotoCameraMobile = forwardRef<Webcam, CheckPhotoCameraProps>(
    (
        {
            appStore,
            onCapture,
            isLoading,
            imageOnLoading,
            onUploaded,
            deviceId,
            onSwitchCamera,
            devices,
            isVideoAllowed,
        }: CheckPhotoCameraProps,
        ref,
    ) => {
        const { t } = useTranslation();
        return createPortal(
            <div className="h-[100dvh] w-screen overflow-x-hidden bg-spoton-dark-blue absolute top-0 z-20">
                {isLoading && <BlurredLoader />}
                <div className="relative h-full w-full">
                    <div className="absolute z-10 w-full h-full flex items-center justify-center">
                        <div className="h-[241px] w-full max-w-[90vw]">
                            <Frame className="w-full">
                                {isVideoAllowed === false && <NoAccessInfo />}
                                {isVideoAllowed && devices.length === 0 && (
                                    <MissingCameraInfo />
                                )}
                                {isVideoAllowed && devices.length > 0 && (
                                    <PlaceCameraInfo />
                                )}
                            </Frame>
                        </div>
                    </div>
                    <div className="flex justify-center h-[100dvh] overflow-hidden">
                        <CustomWebcam
                            className="min-h-[100dvh] w-full min-w-screen max-w-[unset] object-cover"
                            ref={ref}
                            isLoading={isLoading}
                            imageOnLoading={imageOnLoading}
                            deviceId={deviceId}
                            isVideoAllowed={isVideoAllowed}
                        />
                    </div>
                    <div
                        className={clsx(
                            "absolute top-0 left-0 w-full h-full z-20",
                        )}
                    >
                        <div className="flex flex-col justify-between items-center h-full w-full">
                            <div className="w-full px-4 py-5 text-white flex gap-4 bg-spoton-dark-blue">
                                <button
                                    className="pt-[6px] h-fit"
                                    onClick={() =>
                                        appStore.asyncDispatch.setCurrentBillingView(
                                            BillingViews.Root,
                                        )
                                    }
                                >
                                    <Icon size={24} name="CloseIcon" />
                                </button>
                                <div
                                    className={clsx(
                                        "flex justify-between w-full h-full",
                                    )}
                                >
                                    <h1 className="font-semibold text-2xl leading-9">
                                        {t("photoOfVoidedCheckTitle")}
                                    </h1>
                                    <div className="flex gap-4 pt-[6px] h-fit">
                                        <div
                                            {...getAnalyticProps(
                                                AnalyticElements.UploadImageButton,
                                                {
                                                    step: StepName.BillingSelector,
                                                    deviceType: "mobile",
                                                },
                                            )}
                                        >
                                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                            <label htmlFor="photo-upload">
                                                <Icon
                                                    size={24}
                                                    name="ImageIcon"
                                                />
                                            </label>
                                            <input
                                                id="photo-upload"
                                                type="file"
                                                accept={acceptedFileTypes.join(
                                                    ",",
                                                )}
                                                disabled={isLoading}
                                                className="hidden"
                                                onChange={(e) =>
                                                    onUploaded(
                                                        e.target?.files?.[0],
                                                    )
                                                }
                                            />
                                        </div>
                                        <FlashIcon />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={clsx(
                                    "pb-[34px] flex-grow items-end flex w-full justify-center",
                                    styles.MobileCameraView,
                                )}
                            >
                                <div className="relative w-full flex h-fit">
                                    <div className="flex-grow" />
                                    <Button
                                        className="w-[201px] max-w-[90vw]"
                                        onClick={onCapture}
                                        disabled={
                                            isLoading ||
                                            !isVideoAllowed ||
                                            !deviceId
                                        }
                                        {...getAnalyticProps(
                                            AnalyticElements.TakeAPhotoButton,
                                            {
                                                step: StepName.BillingSelector,
                                                deviceType: "mobile",
                                            },
                                        )}
                                    >
                                        {t("takeAPhoto")}
                                    </Button>
                                    <div className="flex flex-grow relative">
                                        <div className="absolute left-0 top-0 h-full flex items-center justify-center w-full">
                                            {devices.length > 1 && (
                                                <button
                                                    onClick={() => {
                                                        if (onSwitchCamera) {
                                                            onSwitchCamera();
                                                        }
                                                    }}
                                                    className="flex"
                                                    {...getAnalyticProps(
                                                        AnalyticElements.SwitchCameraButton,
                                                        {
                                                            deviceType:
                                                                "mobile",
                                                            step: StepName.BillingSelector,
                                                        },
                                                    )}
                                                >
                                                    <SwitchCamera />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
            document.body,
        );
    },
);

export default React.memo(CheckPhotoCameraMobile);
