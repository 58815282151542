import { FederalTaxType } from "features/application-wizard";
import { DeepKeys } from "features/common";
import { AppStore, FormikStore, IGenericStep } from "features/store";

// used for mapping to translation
export enum BusinessTypes {
    Autorental = "autorental",
    Ecommerce = "ecommerce",
    Lodging = "lodging",
    Moto = "moto",
    Restaurant = "restaurant",
    Retail = "retail",
}

export enum OwnershipTypes {
    IndividualSoleProprietor = "individual/sole proprietor",
    SingleMemberLLC = "single-member llc",
    Llc = "llc",
    NonProfit = "non-profit corporation",
    PublicCorporation = "public corporation",
    PrivateCorporation = "private corporation",
    Partnership = "partnership",
    GovernmentEntity = "government entity",
}

export enum EmployeeRange {
    Max5 = "1-5",
    Max25 = "6-25",
    Max50 = "26-50",
    Max75 = "51-75",
    MoreThan76 = "76+",
}

export interface IFormProps {
    appStore: AppStore;
    formikStore: FormikStore;
}

export interface IContact {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    businessName: string;
}

export interface IAddress {
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    street: string;
}

export type BusinessStepPreviewDTO = {
    // business
    businessAddress: IAddress;
    businessContact: IContact;
    dbaAddress: IAddress;
    dbaContact: IContact;
    website: string;
    typeOfOwnership?: OwnershipTypes;
    businessType?: BusinessTypes;
    businessOpenDate: string;
    federalTaxType: FederalTaxType;
    federalTaxId: string;
    federalTaxIdNotFormatted: string;
    exemptPurpose?: string;
    serveInternationalRecipients: boolean;
    // employee
    employeeCount: string;
    // sales
    isAcceptingCards: boolean;
    isAmexUnder1Mil: boolean;
    requestedMonthlySales?: string;
    averageTicket?: string;
    requestedHighestTicket?: string;
    // installation contact;
    isLegalSameAsDba: boolean;
    isIrsSameAsLegal: boolean;
    // irs
    irsAddress: IAddress;
    irsName: string;
};

export const generalBusinessKeysFromDTO = [
    "dbaContact",
    "dbaAddress",
    "website",
    "employeeCount",
    "isAcceptingCards",
    "isAmexUnder1Mil",
    "requestedMonthlySales",
    "averageTicket",
    "requestedHighestTicket",
    "isIrsSameAsLegal",
    "isLegalSameAsDba",
] as const satisfies Readonly<DeepKeys<BusinessStepPreviewDTO>[]>;

export type GeneralBusinessStepDTO = Pick<
    BusinessStepPreviewDTO,
    (typeof generalBusinessKeysFromDTO)[number]
>;

export const legalBusinessKeysFromDTO = [
    "federalTaxId",
    "federalTaxType",
    "exemptPurpose",
    "serveInternationalRecipients",
    "businessOpenDate",
    "typeOfOwnership",
    "businessContact",
    "businessAddress",
    "irsAddress",
    "irsName",
    "isLegalSameAsDba",
    "isIrsSameAsLegal",
] as const satisfies Readonly<(keyof BusinessStepPreviewDTO)[]>;

export type LegalBusinessStepDTO = Pick<
    BusinessStepPreviewDTO,
    (typeof legalBusinessKeysFromDTO)[number]
>;

export type IBusinessStep = BusinessStepPreviewDTO & IGenericStep;
