import React from "react";
import { LoaderSizes, LoadingIndicator } from "spoton-lib";

export const BlurredLoader = () => {
    return (
        <>
            <div className="absolute w-full h-full backdrop-blur z-30" />
            <div className="absolute w-full h-full flex items-center justify-center z-30">
                <LoadingIndicator size={LoaderSizes.sm} />
            </div>
        </>
    );
};
