/* eslint-disable react/display-name */
import React, { forwardRef, useState } from "react";
import Webcam from "react-webcam";
import { Button } from "spoton-lib";

import { Modal } from "app/components";
import { CloseIcon, ImageIcon, SwitchCamera } from "app/icons";
import { AnalyticElements, getAnalyticProps } from "app/utils";
import { useTranslation } from "i18nConfig";

import { FileUpload } from "../FileUpload";
import { StepName } from "../Stepper";
import { BlurredLoader } from "./BlurredLoader.component";
import {
    CheckPhotoCameraProps,
    UploadDialogProps,
} from "./CheckPhotoCamera.types";
import { CustomWebcam } from "./CustomWebcam.component";
import { Frame } from "./Frame.component";
import { MissingCameraInfo, NoAccessInfo } from "./UserMessages";

export const UploadDialog = ({
    isLoading,
    onClosed,
    isOpen,
    onUploaded,
}: UploadDialogProps) => {
    const [file, setFile] = useState<File | null>(null);

    const onFileUploadChange = (file: File) => {
        setFile(file);
    };
    const onFileDeleted = () => {
        setFile(null);
    };

    const onModalClosed = () => {
        onClosed();
        setFile(null);
    };
    const { t } = useTranslation();

    return (
        <Modal
            className="h-full w-[656px] max-w-[90vw] max-h-[482px] p-6 flex flex-col"
            isOpen={isOpen}
            headerComponent={
                <div className="flex items-center justify-between">
                    <span className="font-semibold text-xl capitalize">
                        {t("uploadVoidedCheckBtnLabel")}
                    </span>
                    <button
                        onClick={onClosed}
                        {...getAnalyticProps(
                            AnalyticElements.CloseUploadModalButton,
                            {
                                step: StepName.BillingSelector,
                                deviceType: "desktop",
                            },
                        )}
                    >
                        <CloseIcon />
                    </button>
                </div>
            }
            ariaHideApp={false}
        >
            <div className="flex flex-col gap-6 pt-6 tablet:h-full">
                <FileUpload
                    file={file}
                    onChange={onFileUploadChange}
                    onDelete={onFileDeleted}
                    className="tablet:h-[296px] tablet:max-h-[289px] w-full !block"
                    isLoading={isLoading}
                />
                <div className="flex justify-end gap-4">
                    <Button
                        onClick={onModalClosed}
                        variant="secondary"
                        disabled={isLoading}
                        {...getAnalyticProps(
                            AnalyticElements.CloseUploadModalButton,
                            {
                                step: StepName.BillingSelector,
                                deviceType: "desktop",
                            },
                        )}
                    >
                        {t("cancelButtonLabel")}
                    </Button>
                    <Button
                        disabled={!file || isLoading}
                        isLoading={isLoading}
                        onClick={() => {
                            onUploaded(file);
                        }}
                        {...getAnalyticProps(
                            AnalyticElements.UploadImageButton,
                            {
                                step: StepName.BillingSelector,
                                deviceType: "desktop",
                            },
                        )}
                    >
                        {t("continueButton")}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const CheckPhotoCameraDesktop = forwardRef<Webcam, CheckPhotoCameraProps>(
    (
        {
            onCapture,
            isLoading,
            imageOnLoading,
            deviceId,
            devices,
            onSwitchCamera,
            isVideoAllowed,
            onUploaded,
        }: CheckPhotoCameraProps,
        ref,
    ) => {
        const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
        const { t } = useTranslation();
        return (
            <div className="bg-[#F7F7FA] min-h-[401px] p-6 rounded flex flex-col gap-6 mt-9">
                <UploadDialog
                    isOpen={isUploadModalOpen}
                    onClosed={() => setIsUploadModalOpen(false)}
                    onUploaded={(file) => {
                        if (file) {
                            onUploaded(file);
                        }
                    }}
                    isLoading={isLoading}
                />
                <div className="relative h-full w-full">
                    {isLoading && <BlurredLoader />}
                    <CustomWebcam
                        className="w-full h-[18rem] min-w-screen max-w-[unset] object-cover rounded"
                        ref={ref}
                        isLoading={isLoading}
                        imageOnLoading={imageOnLoading}
                        deviceId={deviceId}
                        isVideoAllowed={isVideoAllowed}
                    />
                    {devices.length > 1 && (
                        <div className="z-20 absolute left-0 top-0 w-full h-full p-10 flex items-end justify-end">
                            <button
                                onClick={() => {
                                    if (onSwitchCamera) {
                                        onSwitchCamera();
                                    }
                                }}
                                className="flex rounded-full border border-white p-2"
                            >
                                <SwitchCamera />
                            </button>
                        </div>
                    )}
                    <div className="absolute top-0 left-0 p-6 w-full h-full">
                        <Frame>
                            {isVideoAllowed === false && <NoAccessInfo />}
                            {isVideoAllowed && devices.length === 0 && (
                                <MissingCameraInfo />
                            )}
                        </Frame>
                    </div>
                </div>
                <div className="flex gap-8 w-full">
                    <Button
                        className="w-full items-center flex gap-2"
                        variant="tertiary"
                        onClick={() => setIsUploadModalOpen(true)}
                        disabled={isLoading}
                        {...getAnalyticProps(
                            AnalyticElements.OpenUploadImageModalButton,
                            {
                                step: StepName.BillingSelector,
                            },
                        )}
                    >
                        <ImageIcon />
                        <span className="font-semibold">
                            {t("uploadImage")}
                        </span>
                    </Button>
                    <Button
                        className="w-full"
                        onClick={onCapture}
                        disabled={isLoading || !isVideoAllowed || !deviceId}
                        {...getAnalyticProps(
                            AnalyticElements.TakeAPhotoButton,
                            {
                                step: StepName.BillingSelector,
                                deviceType: "desktop",
                            },
                        )}
                    >
                        {t("takeAPhoto")}
                    </Button>
                </div>
            </div>
        );
    },
);

export default React.memo(CheckPhotoCameraDesktop);
