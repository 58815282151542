import { useEffect, useState } from "react";

import { Breakpoint } from "features/hooks/breakpoint";

export const useBreakpoint = (query: Breakpoint) => {
    const [isMatching, setIsMatching] = useState(false);
    useEffect(
        () => {
            const mediaQuery = window.matchMedia(query);
            setIsMatching(mediaQuery.matches);
            const handler = (event: MediaQueryListEvent) =>
                setIsMatching(event.matches);
            mediaQuery.addEventListener("change", handler);
            return () => mediaQuery.removeEventListener("change", handler);
        },
        [], // Empty array ensures effect is only run on mount and unmount
    );
    return isMatching;
};
