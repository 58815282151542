import React from "react";

import { ManuallyEnterIcon, PhotoIcon } from "app/icons";
import { AnalyticEvents } from "app/utils";
import { StepName } from "features";
import { useSendEventHeap } from "features/hooks";
import { useTranslation } from "i18nConfig";

import { BillingSelectorButton } from "./BillingSelectorButton.component";

import styles from "./BillingSelectorRoot.module.scss";

export const BillingSelectorRoot = (props: {
    open: () => void;
    isManualDataLoading: boolean;
    // isPlaidReady: boolean;
    onManualEnter: () => void;
    onPhotoCapture: () => void;
}) => {
    const { onManualEnter, onPhotoCapture, isManualDataLoading } = props;
    const { t } = useTranslation();

    useSendEventHeap(AnalyticEvents.BillingSelectorRootView, {
        step: StepName.BillingSelector,
    });

    return (
        <div className="flex flex-col pt-3 tablet:pt-[52px]">
            <div className={styles.BillingView_buttonsWrapper}>
                {/*<div>*/}
                {/*    <PlaidConnectAccount open={open} ready={isPlaidReady} />*/}
                {/*</div>*/}
                <BillingSelectorButton
                    icon={<PhotoIcon className="h-full w-full" />}
                    title={t("takeAPhotoTitle")}
                    description={t("takeAPhotoDescription")}
                    onClick={onPhotoCapture}
                />
                <div className={styles.BillingView_middleMarker}>
                    {t("or").toUpperCase()}
                </div>
                <BillingSelectorButton
                    isLoading={isManualDataLoading}
                    icon={<ManuallyEnterIcon className="h-full w-full" />}
                    title={t("manuallyEnterTitle")}
                    description={t("manuallyEnterDescription")}
                    onClick={onManualEnter}
                />
            </div>
        </div>
    );
};
