import { useRef } from "react";

export const getCSSVariable = (variable: string) => {
    return getComputedStyle(document.body).getPropertyValue(variable).trim();
};

// useId to generate random id - useRef for that
export const useId = () => {
    const id = useRef(Math.random().toString(36).substring(7));
    return id.current;
};
