import React from "react";

import { SignaturePage, TermsAndConditionsPage } from "app/components";
import { IPageComponentProps } from "app/types";
import { useDerivedState } from "store/hooks";

// Resolver for Signature, it is needed because when we want to use conditional rendering in app.routes,
// we receive multiple recurring requests

// TODO if possible, make sure to replace this file with plain TermsAndConditions / Signature Pages
// currently we experience multiple requests on the same endpoint when we try to pass clickToSign to router,
// and try to resolve this functionality there
export const SignatureResolverPage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                config: { clickToSign },
            },
            loading: { isSetConfigLoading },
        }) => ({
            isLoading: isSetConfigLoading,
            clickToSign,
        }),
    );
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { isLoading, clickToSign } = getState();

    if (isLoading) {
        return null;
    }

    return clickToSign ? (
        <TermsAndConditionsPage appStore={appStore} formikStore={formikStore} />
    ) : (
        <SignaturePage appStore={appStore} formikStore={formikStore} />
    );
};
