import React from "react";

import { SuggestedTaxes } from "features/application-wizard";

import { areSuggestedTaxesFound } from "../../helpers";
import { TaxItemsList } from "./TaxItemsList.component";
import { TotalRateSummary } from "./TotalRateSummary.component";

export function SuggestedTaxSearchResult(props: {
    taxRates: SuggestedTaxes | null;
    isLoading: boolean;
}) {
    const { taxRates, isLoading } = props;
    return (
        <div>
            <div className="flex flex-col gap-4">
                <div className="px-4 text-neutral-60 flex flex-col gap-0.5 overflow-auto">
                    <TaxItemsList taxRates={taxRates} isLoading={isLoading} />
                </div>
                {(areSuggestedTaxesFound(taxRates) || isLoading) && (
                    <div className="p-3 font-[600] bg-container-neutral-lightest rounded-xl text-neutral-60">
                        <TotalRateSummary
                            totalRate={taxRates?.totalRate}
                            isLoading={isLoading}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
