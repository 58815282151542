import React from "react";
import { FormikProps } from "formik";
import _ from "lodash";

import { DeepKeys, NumberField } from "features/common";

interface IDollarFieldProps<T> {
    formik: FormikProps<T>;
    name: DeepKeys<T>;
}

export const DollarField = <T,>({ formik, name }: IDollarFieldProps<T>) => {
    const value = _.get(formik.values, name) as string | number | undefined;
    return (
        <NumberField
            className="w-[165.5px] min-w-[165.6px] p-0"
            formik={formik}
            name={name}
            value={value}
            label=""
            placeholder="$"
            prefix="$"
            allowNegative={false}
            decimalScale={0}
            maxLength={10}
        />
    );
};
