import { ITranslation, Namespace } from "../translation.types";
import translationEN from "./en.json";
// import translationES from "./es.json";

export type { translationEN };

export const namespaceConfig: ITranslation<typeof translationEN> = {
    namespace: Namespace.Common,
    translations: {
        en: translationEN,
        // es: translationES,
    },
};
