import React from "react";

import { IPageComponentProps } from "app/types";
import { ManualLinkAccountForm } from "features/common";
import { useTranslation } from "i18nConfig";

import { StepPageLayout } from "../StepPageLayout";

import styles from "./BillingFormPage.module.scss";

export const BillingFormPage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();
    return (
        <StepPageLayout
            appStore={appStore}
            className={styles.ContentWrapper}
            id="billing-step"
            title={t("yourAccountInformation")}
            formikStore={formikStore}
            width="narrow"
        >
            <ManualLinkAccountForm
                formikStore={formikStore}
                appStore={appStore}
                onFormSubmit={async (values) => {
                    await appStore.asyncDispatch.setManualAccount(values);
                }}
            />
        </StepPageLayout>
    );
};
