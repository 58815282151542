/* eslint-disable @typescript-eslint/naming-convention */
import { AxiosError, AxiosInstance } from "axios";

import { t } from "i18nConfig/i18n.config";

import { displayErrorCard } from "../axiosDisplayErrorCard.utils";
import { defaultErrorRegistry } from "./axiosDefaultErrorRegistry";
import { ErrorHandlerRegistry } from "./axiosErrorRegistry";

const onFileUploadError = <T>(
    error: AxiosError<T>,
    axios: AxiosInstance,
    allowRetry: boolean,
) => {
    return displayErrorCard(error, axios, {
        allowRetry,
        variant: "danger",
        content: t("oopsErrorText"),
    });
};

export const fileUploadErrorRegistry = new ErrorHandlerRegistry(
    defaultErrorRegistry,
    {
        all: async (e, axios) => axios && onFileUploadError(e, axios, true),
        "401": async (e, axios) => axios && onFileUploadError(e, axios, false),
    },
);
