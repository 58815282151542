import React from "react";
import clsx from "clsx";
import { Button, Icon } from "spoton-lib";

import { CustomButton } from "app/components";
import { AnalyticElements, getAnalyticProps } from "app/utils";
import { useStepNavigate } from "features";
import { AppStore } from "features/store";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import styles from "./SubHeader.module.scss";

export const SubHeader = ({
    appStore,
    isGoBackHidden,
    customGoBackButtonProps,
}: {
    appStore: AppStore;
    isGoBackHidden?: boolean;
    customGoBackButtonProps?: CustomButton;
}) => {
    const { t } = useTranslation();
    const { onClick, className, ...restCustomGoBackButtonProps } =
        customGoBackButtonProps || {};
    const [getState] = useDerivedState(
        appStore,
        ({
            loading: { isGoBackLoading, isSetConfigLoading },
            data: {
                progressBlock: {
                    navigation: { activeStepName },
                },
            },
        }) => ({
            isLoading: isGoBackLoading || isSetConfigLoading,
            activeStepName,
        }),
    );

    const { isLoading, activeStepName } = getState();
    const { canGoBack } = useStepNavigate({ appStore });

    return canGoBack && !isGoBackHidden ? (
        <div className={styles.SubHeader}>
            <div className={styles.ButtonContainer}>
                <Button
                    className={clsx(styles.BackBtn, className)}
                    variant="ghost"
                    data-testid="back-btn"
                    type="button"
                    onClick={() => onClick?.()}
                    isLoading={isLoading}
                    {...restCustomGoBackButtonProps}
                    {...getAnalyticProps(AnalyticElements.GoBackButton, {
                        context: "stepPage",
                        step: activeStepName,
                    })}
                >
                    <Icon
                        className={styles.BackIcon}
                        name="ArrowLeftIcon"
                        data-testid="back-btn-icon"
                        size={24}
                    />
                    <span>{t("backBtn")}</span>
                </Button>
            </div>
        </div>
    ) : null;
};
