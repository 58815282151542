import { stepsToStepViewMap } from "app";
import { AppRoutes } from "app/routes";
import { IStepper, StepStatus, stepToRoute, StepView } from "features/common";

import { IAppStateType } from "../AppStore.types";

export const getStepperState = ({
    state,
    stepper,
}: {
    state: IAppStateType;
    stepper: IStepper;
}) => {
    const { steps, currentStep, nextStep, availableSteps, previousStep } =
        stepper;

    const stepsViews: StepView[] = stepper.steps.map((step, index) => {
        const mappedSteps = stepsToStepViewMap();
        const { subStep, ...rest } = mappedSteps[step.stepName] || {};
        // eslint-disable-next-line @typescript-eslint/naming-convention

        return {
            ...step,
            stepOrder: index,
            status: step.stepStatus,
            route: stepToRoute(step.stepName),
            name: step.stepName,
            subStep:
                subStep ||
                !Object.keys(stepsToStepViewMap()).includes(step.stepName)
                    ? { ...(subStep || { beforeParentStep: true }) }
                    : undefined,
            ...rest,
        };
    });

    const activeStepView = stepsViews.find((step) => step.name === currentStep);

    const isSubmitted = steps.every(
        (step) => step.stepStatus === StepStatus.Complete,
    );

    return {
        config: {
            ...state.config,
            isSubmitted,
        },
        progressBlock: {
            ...state.progressBlock,
            initialRoute: currentStep
                ? stepToRoute(currentStep)
                : AppRoutes.Home,
            view: {
                steps: stepsViews,
                activeStep: activeStepView,
            },
            navigation: {
                ...state.progressBlock.navigation,
                availableStepsNames: availableSteps,
                latestStepName: availableSteps[availableSteps.length - 1],
                activeStepName: currentStep,
                next: nextStep,
                previous: previousStep,
            },
        },
    };
};
