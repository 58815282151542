import React, { HTMLAttributes } from "react";
import clsx from "clsx";

import { CommonTypes } from "features/common";

import { SuggestedTax, TaxPreview } from "../../ShippingInformationForm.types";
import { formatPercent } from "./helpers";

export const TaxItem = ({
    tax,
    ...props
}: HTMLAttributes<HTMLDivElement> & {
    tax: CommonTypes<TaxPreview, SuggestedTax>;
}) => {
    return (
        <div
            className={clsx(
                "flex justify-between items-center",
                props.className,
            )}
            {...props}
        >
            <span>{tax?.name}</span>
            <span>{formatPercent(tax?.rate)}</span>
        </div>
    );
};
