import React from "react";
import { Text } from "spoton-lib";

import { useTranslation } from "i18nConfig";
import { TranslationKeys } from "i18nConfig/translation.types";

interface IProps {
    className?: string;
    message: string & TranslationKeys;
}

// We need to add this to separate file so we can use the i18n hooks.
// We can't have useTranslation in a class component, and right now there
// is no other way to implement ErrorBoundaryGeneric.component.tsx than
// with a class component
// https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
export const ErrorMessage = ({ className, message }: IProps) => {
    const { t } = useTranslation();

    return (
        <Text type="h2" className={className}>
            {t(message)}
        </Text>
    );
};
