import React from "react";
import { Text } from "spoton-lib";

import { IPageComponentProps } from "app/types";
import { useStepNavigate } from "features";
import { CheckPhotoCamera } from "features/common";
import { BillingSelectorRoot } from "features/common";
import { BillingViews } from "features/store";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { StepPageLayout } from "../StepPageLayout";

import styles from "./BillingSelectorPage.module.scss";

export const BillingSelectorPage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();
    const [getState] = useDerivedState(
        appStore,
        ({
            loading: {
                isUploadCapturePhotoLoading,
                isSubmitAndGoForwardLoading,
                // isGetLinkedAccountLoading,
                isSetCurrentBillingViewLoading,
                isGetManualAccountDataLoading,
            },
            data: {
                billingStep,
                progressBlock: {
                    meta: {
                        billingStep: { currentView },
                    },
                },
            },
        }) => ({
            currentView,
            billingStep,
            isManualDataLoading:
                isGetManualAccountDataLoading || isSubmitAndGoForwardLoading,
            isLoading:
                isUploadCapturePhotoLoading ||
                // isGetLinkedAccountLoading ||
                isSetCurrentBillingViewLoading,
        }),
    );
    const { currentView, isLoading, isManualDataLoading } = getState();
    const { goForward } = useStepNavigate({ appStore });

    const onConnected = async () => {
        await goForward({ formikStore });
    };

    // const { open, isReady } = usePlaidAccount({
    //     appStore,
    //     onConnected,
    // });

    switch (currentView) {
        case BillingViews.Root:
            return (
                <StepPageLayout
                    isFooterHidden
                    appStore={appStore}
                    className={styles.ContentWrapper}
                    id="billing-step"
                    title={t("billingDepositPageTitle")}
                    subtitle={
                        <Text type="p" className="text-left">
                            {t("paymentconnectBankAccountDescription")}
                        </Text>
                    }
                    formikStore={formikStore}
                    width="medium"
                    isLoading={isLoading}
                    customPrimaryFooterButton={{
                        hidden: true,
                    }}
                    customSecondaryFooterButton={{
                        hidden: true,
                    }}
                >
                    <BillingSelectorRoot
                        open={open}
                        // isPlaidReady={isReady}
                        onManualEnter={onConnected}
                        isManualDataLoading={isManualDataLoading}
                        onPhotoCapture={() =>
                            appStore.asyncDispatch.setCurrentBillingView(
                                BillingViews.Photo,
                            )
                        }
                    />
                </StepPageLayout>
            );
        case BillingViews.Photo:
            return (
                <StepPageLayout
                    appStore={appStore}
                    className={styles.ContentWrapper}
                    id="billing-step"
                    title={t("photoOfVoidedCheckTitle")}
                    subtitle={
                        <span className="text-sm">
                            <b>{t("howToScan")}</b>
                            <span>{t("howToScanDescription")}</span>
                        </span>
                    }
                    formikStore={formikStore}
                    customPrimaryFooterButton={{
                        hidden: true,
                    }}
                    customGoBackButton={{
                        onClick: () =>
                            appStore.asyncDispatch.setCurrentBillingView(
                                BillingViews.Root,
                            ),
                    }}
                    customSecondaryFooterButton={{
                        isLoading: isManualDataLoading,
                        hidden: false,
                        label: t("addAccountDataManually"),
                        onClick: onConnected,
                    }}
                    width="narrow"
                    modeOnLoading="hide"
                >
                    <CheckPhotoCamera
                        formikStore={formikStore}
                        appStore={appStore}
                    />
                </StepPageLayout>
            );
    }
};
