import React from "react";
import clsx from "clsx";
import { ProgressIndicator } from "spoton-lib";

import { StepName, StepStatus, StepView } from "./Stepper.types";

import styles from "./Stepper.module.scss";

interface IStepperProps {
    steps: StepView[];
    activeStep?: StepView;
}

export const Stepper = (props: IStepperProps) => {
    const { activeStep, steps } = props;
    const stepToBeSelected = activeStep?.subStep
        ? activeStep.subStep.beforeParentStep
            ? steps.slice(activeStep.stepOrder).find((step) => !step.subStep)
                  ?.name
            : steps
                  .slice(0, activeStep.stepOrder + 1)
                  .reverse()
                  .find((step) => !step.subStep)?.name
        : steps[activeStep?.stepOrder || 0]?.name;
    return (
        <div className={styles.StepperWrapper}>
            <ProgressIndicator
                className={clsx(styles.ProgressIndicator, "progressIndicator")}
                activeItem={stepToBeSelected || StepName.Business}
            >
                {steps
                    .filter((step) => !step.subStep)
                    .map((step) => (
                        <ProgressIndicator.ProgressItem
                            key={step.name}
                            name={step.name}
                            completed={step.status === StepStatus.Complete}
                            className={clsx(
                                styles.ProgressItem,
                                "progressItem",
                                `progressItem-${step.name}`,
                            )}
                            title={
                                <span
                                    className={clsx(styles.ProgressItemTitle, {
                                        [styles.ProgressItemTitle_active]:
                                            step.name === stepToBeSelected,
                                    })}
                                >
                                    {step.title}
                                </span>
                            }
                        />
                    ))}
            </ProgressIndicator>
        </div>
    );
};
