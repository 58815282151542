import React, { useEffect } from "react";

import { IPageComponentProps } from "app/types";
import { PaymentsBusinessInformationForm } from "features/application-wizard";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { StepPageLayout } from "../StepPageLayout";

import styles from "./BusinessPage.module.scss";

export const BusinessPage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();

    const getState = useDerivedState(
        appStore,
        ({ loading: { isGetBusinessStepLoading } }) => ({
            isLoading: isGetBusinessStepLoading,
        }),
    )[0];
    const { isLoading } = getState();

    useEffect(() => {
        appStore.asyncDispatch.getBusinessStep();
    }, []);

    return (
        <StepPageLayout
            appStore={appStore}
            className={styles.ContentWrapper}
            id="business-step"
            isLoading={isLoading}
            title={t("businessInformationPageTitle")}
            formikStore={formikStore}
        >
            <PaymentsBusinessInformationForm
                appStore={appStore}
                formikStore={formikStore}
            />
        </StepPageLayout>
    );
};
