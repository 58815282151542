import { getStepperState } from "features/store/AppStore/helpers";
import {
    API_ENDPOINTS,
    dataURLtoFile,
    httpCore,
    httpFileCore,
} from "features/utils";

import { IAppStateType } from "../AppStore.types";
import { defaultReducer } from "../defaultState";

export const termsAndConditionsReducers = {};

export const termsAndConditionsAsyncReducers = {
    async getTCHtml(
        currentState: IAppStateType,
    ): Promise<typeof defaultReducer> {
        try {
            const response = await httpCore.get(
                `${API_ENDPOINTS.signup.TermsAndConditionsStep}/tc-html/`,
            );
            if (response.status !== 200) return defaultReducer;
            const { tcVersion, tcHtml } = response.data;

            return (state: IAppStateType) => ({
                ...state,
                termsAndConditionsStep: {
                    ...state.termsAndConditionsStep,
                    tcHtml,
                    tcVersion,
                },
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },

    async updateTermsAndConditionsStep(
        currentState: IAppStateType,
        signatureImage: string,
    ): Promise<typeof defaultReducer> {
        try {
            const blob = dataURLtoFile(
                signatureImage,
                "signature_image.png",
            ) as File;
            const imageFileData = new FormData();
            imageFileData.append("signature_image", blob);
            imageFileData.append(
                "signed_version",
                currentState.termsAndConditionsStep.tcVersion,
            );
            const response = await httpFileCore.post(
                `${API_ENDPOINTS.signup.TermsAndConditionsStep}/`,
                imageFileData,
                { headers: { "Content-Type": "multipart/form-data" } },
            );

            const { stepper } = response.data;
            return (state: IAppStateType) => ({
                ...state,
                termsAndConditionsStep: {
                    ...state.termsAndConditionsStep,
                },
                ...getStepperState({ state, stepper }),
                signatureStep: {
                    ...state.signatureStep,
                    wasSigned: true,
                },
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
};
