import React, { useEffect } from "react";

import { IPageComponentProps } from "app/types";
import { ApplicantForm } from "features/application-wizard";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { StepPageLayout } from "../StepPageLayout";

import styles from "./ApplicantPage.module.scss";

export const ApplicantPage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();
    const getState = useDerivedState(
        appStore,
        ({
            loading: { isGetApplicantStepLoading, isGetOwnersStepLoading },
        }) => ({
            isLoading: isGetApplicantStepLoading || isGetOwnersStepLoading,
        }),
    )[0];
    const { isLoading } = getState();

    useEffect(() => {
        (async () => {
            await Promise.all([
                appStore.asyncDispatch.getOwnersStep(),
                appStore.asyncDispatch.getApplicantStep(),
            ]);
        })();
    }, []);

    return (
        <StepPageLayout
            appStore={appStore}
            className={styles.ContentWrapper}
            id="applicant-step"
            title={t("applicantPageTitle")}
            isLoading={isLoading}
            formikStore={formikStore}
        >
            <ApplicantForm appStore={appStore} formikStore={formikStore} />
        </StepPageLayout>
    );
};
