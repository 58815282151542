import React, { HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";

export const GreyTaxContainer = ({
    children,
    header,
    ...props
}: HTMLAttributes<HTMLDivElement> & { header: ReactNode }) => (
    <div
        className={clsx(
            "flex font-[500] text-sm flex-col gap-4 p-4 rounded-xl bg-container-neutral-lightest text-left text-neutral-60",
            props.className,
        )}
        {...props}
    >
        <div className="text-left">
            <span>{header}</span>
        </div>
        {children}
    </div>
);
