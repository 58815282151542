import React from "react";
import { LoadingIndicator, Text } from "spoton-lib";

import { SuccessImages } from "app/components/SuccessPage/SuccessImages";
import { IPageComponentProps } from "app/types";
import { BusinessRebookActionType } from "features/store";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import styles from "./SuccessPage.module.scss";

export const SuccessPage = ({ appStore }: IPageComponentProps) => {
    const { t } = useTranslation();
    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                config: { businessRebookAction, isRestaurant },
            },
            loading: { isCompleteSignatureStepLoading },
        }) => ({
            isLoading: isCompleteSignatureStepLoading,
            businessRebookAction,
            isRestaurant,
        }),
    );
    const { isLoading, businessRebookAction, isRestaurant } = getState();

    const resolveHeader = (businessRebookAction: BusinessRebookActionType) => {
        if (businessRebookAction) {
            return (
                <>
                    <Text type="h1" className={styles.ContentWrapper_title}>
                        {t("successPageBusiness")}
                    </Text>
                    <Text type="sub2">
                        {t("successPageBusinessDescription")}
                    </Text>
                </>
            );
        }

        return (
            <>
                <Text className={styles.PageTitle} type="h3">
                    {t("successPagePageTitle")}
                </Text>
                <Text className={styles.PageSubTitle} type="sub1">
                    {t("successPageDescription")}
                </Text>
            </>
        );
    };

    return (
        <div className={styles.ContentWrapper}>
            {isLoading ? (
                <div className={styles.LoadingContainer}>
                    <LoadingIndicator greyAnimation={true} />
                </div>
            ) : (
                <div className={styles.Page}>
                    <div className={styles.Content}>
                        {resolveHeader(businessRebookAction)}
                    </div>
                    <SuccessImages isRestaurant={!!isRestaurant} />
                </div>
            )}
        </div>
    );
};
