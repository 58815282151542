import { PermissionState } from "./CheckPhotoCamera.types";

export const verifyCameraAccess = async ({
    cbGranted,
    cbDenied,
    cbPrompt,
}: {
    cbGranted: (devices: MediaDeviceInfo[]) => void;
    cbDenied: () => void;
    cbPrompt: () => void;
}) => {
    const data = await navigator.permissions.query({
        name: "camera" as PermissionName,
    });

    const onGranted = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        cbGranted(devices);
    };

    const onRejected = () => {
        cbDenied();
    };

    const onPrompt = () => {
        cbPrompt();
    };

    switch (data.state) {
        case PermissionState.Granted:
            await onGranted();
            break;
        case PermissionState.Prompt:
            onPrompt();
            break;
        case PermissionState.Denied:
            onPrompt();
            break;
        default:
            onRejected();
            break;
    }

    const listener = async () => {
        const newData = await navigator.permissions.query({
            name: "camera" as PermissionName,
        });
        switch (newData.state) {
            case PermissionState.Granted:
                await onGranted();
                break;
            case PermissionState.Prompt:
                onPrompt();
                break;
            case PermissionState.Denied:
                onPrompt();
                break;
            default:
                onRejected();
                break;
        }
    };

    data.addEventListener("change", listener);
    return () => {
        data.removeEventListener("change", listener);
    };
};
