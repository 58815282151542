import { API_ENDPOINTS, httpCore, uploadCore } from "features/utils";

import { IFileWithId } from "./SiteSurvey.types";

export const uploadFile = async (siteSurveyId: string, file: File) => {
    const formData = new FormData();
    formData.append("photo", file);

    try {
        const { data } = await uploadCore.post(
            `${API_ENDPOINTS.siteSurvey.SiteSurvey}/${siteSurveyId}/photo`,
            formData,
        );
        const fileWithId = file as IFileWithId;
        fileWithId.id = data.id;
        return fileWithId;
    } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
    }
};

export const removeFile = async (siteSurveyId: string, photoId: number) => {
    try {
        await httpCore.delete(
            `${API_ENDPOINTS.siteSurvey.SiteSurvey}/${siteSurveyId}/photo/${photoId}`,
        );
    } catch (error) {
        console.error("Error removing file:", error);
    }
};

export const postSiteSurvey = async (
    siteSurveyId: string,
    questionsAndAnswers: any[],
): Promise<void> => {
    await httpCore.post(
        `${API_ENDPOINTS.siteSurvey.SiteSurvey}/${siteSurveyId}`,
        {
            questionsAndAnswers,
        },
    );
};

export const getSiteSurveyPromise = (siteSurveyId: string) =>
    httpCore.get(`${API_ENDPOINTS.siteSurvey.SiteSurvey}/${siteSurveyId}`);
