/* eslint-disable react/display-name */
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { isIOS, isMobile, isSafari } from "react-device-detect";
import Webcam from "react-webcam";

import { AnalyticEvents } from "app/utils";
import { Breakpoint, useBreakpoint, useSendEventHeap } from "features/hooks";
import { AppStore, FormikStore } from "features/store";
import { useStepNavigate } from "features/utils";
import { useDerivedState } from "store/hooks";

import { StepName } from "../Stepper";
import { MediaDevice } from "./CheckPhotoCamera.types";
import { verifyCameraAccess } from "./checkPhotoCamera.utils";
import CheckPhotoCameraDesktop from "./CheckPhotoCameraDesktop.component";
import CheckPhotoCameraMobile from "./CheckPhotoCameraMobile.component";

export const CheckPhotoCamera = ({
    appStore,
    formikStore,
}: {
    appStore: AppStore;
    formikStore: FormikStore;
}) => {
    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);
    const { goForward } = useStepNavigate({ appStore });
    const [getState] = useDerivedState(
        appStore,
        ({
            loading: { isSubmitPhotoCheckLoading, isUploadCapturePhotoLoading },
        }) => ({
            isLoading: isSubmitPhotoCheckLoading || isUploadCapturePhotoLoading,
        }),
    );
    const { isLoading } = getState();
    const [imageSrc, setImageSrc] = useState<string | null | undefined>(null);
    const webcamRef = useRef<Webcam>(null);

    useSendEventHeap(AnalyticEvents.BillingSelectorCameraView, {
        step: StepName.BillingSelector,
    });

    const onCapture = useCallback(async () => {
        const imageSrc = webcamRef?.current?.getScreenshot();
        setImageSrc(imageSrc);
        if (imageSrc) {
            const base64Response = await fetch(imageSrc);
            const blob = await base64Response.blob();
            const imageFile = new File([blob], "check.png", {
                type: "image/png",
            });
            await appStore.asyncDispatch.uploadCapturePhoto(imageFile);
            await goForward({ formikStore });
        }
    }, [webcamRef]);

    const onUploaded = async (file?: File) => {
        if (file) {
            await appStore.asyncDispatch.uploadCapturePhoto(file);
            await goForward({ formikStore });
        }
    };

    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [deviceId, setDeviceId] = useState<string | undefined>(undefined);
    const [isVideoAllowed, setIsVideoAllowed] = useState<boolean | undefined>();
    const handleDevices = useCallback(
        async (mediaDevices: MediaDeviceInfo[]) => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        facingMode: "environment",
                    },
                });
                const videoDevices = mediaDevices.filter(
                    ({ kind }) => kind === MediaDevice.VideoInput,
                );

                if (videoDevices.length) {
                    const defaultDevice = stream
                        .getVideoTracks()[0]
                        .getSettings().deviceId;
                    setDeviceId(defaultDevice || videoDevices[0]?.deviceId);
                }
                setDevices(videoDevices);
            } catch (e) {
                /* empty */
            }
        },
        [setDevices, setDeviceId],
    );

    const resetVideo = () => {
        setIsVideoAllowed(false);
        setDevices([]);
        setDeviceId(undefined);
    };

    const cameraVerificationProps = useMemo(
        () => ({
            cbGranted: async (devices: MediaDeviceInfo[]) => {
                await handleDevices(devices);
                setIsVideoAllowed(true);
            },
            cbDenied: () => {
                resetVideo();
            },
            cbPrompt: async () => {
                resetVideo();
                await handleDevices(devices);
            },
        }),
        [resetVideo, setIsVideoAllowed, isVideoAllowed, handleDevices],
    );

    useEffect(() => {
        /** Currently Safari seems not to support permission change events
         * @todo Once available we should switch to event based approach.
         * */
        if (isSafari || (isMobile && isIOS)) {
            // keep checking status each second
            const interval = setInterval(async () => {
                await verifyCameraAccess({
                    ...cameraVerificationProps,
                    cbGranted: (devices) => {
                        if (!isVideoAllowed) {
                            cameraVerificationProps.cbGranted(devices);
                        }
                    },
                });
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        } else {
            let removeListener: (() => void) | undefined;
            (async () => {
                removeListener = await verifyCameraAccess(
                    cameraVerificationProps,
                );
            })();
            return () => {
                removeListener && removeListener();
            };
        }
    }, [isVideoAllowed]);

    const onSwitchCamera = () => {
        const index = devices.findIndex(
            (device) => device.deviceId === deviceId,
        );
        const nextIndex = (index + 1) % devices.length;
        setDeviceId(devices[nextIndex].deviceId);
    };
    return isLowerThanTablet ? (
        <CheckPhotoCameraMobile
            appStore={appStore}
            onCapture={onCapture}
            ref={webcamRef}
            isLoading={isLoading}
            imageOnLoading={imageSrc}
            onUploaded={onUploaded}
            deviceId={deviceId}
            onSwitchCamera={onSwitchCamera}
            devices={devices}
            isVideoAllowed={isVideoAllowed}
        />
    ) : (
        <CheckPhotoCameraDesktop
            appStore={appStore}
            onCapture={onCapture}
            ref={webcamRef}
            isLoading={isLoading}
            imageOnLoading={imageSrc}
            onUploaded={onUploaded}
            deviceId={deviceId}
            onSwitchCamera={onSwitchCamera}
            devices={devices}
            isVideoAllowed={isVideoAllowed}
        />
    );
};
