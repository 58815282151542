import { Store } from "store/store";

import { defaultState } from "./defaultState";
import { IFormikStateType } from "./FormikStore.types";
import { asyncReducers, reducers } from "./reducers";

const getNestedTouched = (obj: object) => {
    // returns nested object with same schema as obj,
    // with all values set to "true"
    const newObj: { [key in string]: any } = {};
    for (const [key, val] of Object.entries(obj)) {
        if (typeof val === "object" && val !== null) {
            newObj[key] = getNestedTouched(val);
        } else {
            newObj[key] = true;
        }
    }
    return newObj;
};

// Store
export class FormikStore extends Store<
    IFormikStateType,
    typeof reducers,
    typeof asyncReducers
> {
    constructor() {
        super(defaultState(), reducers, asyncReducers);
    }

    // submit a specific formRef by key, and return the Promise
    async submitFormRef(key: string) {
        const ref = this.state.data.formRefs[key];
        await ref.current?.setSubmitting(true);
        await ref.current?.validateForm();
        if (!ref?.current?.isValid) {
            // if form is inValid, set the touched values so errors can be shown.
            await ref.current?.setTouched(getNestedTouched(ref.current.values));
            await ref.current?.setSubmitting(false);
            return Promise.reject(ref.current);
        }
        await this.asyncDispatch.submitForm(key);
        ref.current?.setTouched({});
        return Promise.resolve(ref.current);
    }

    async validateFormRef(key: string) {
        const ref = this.state.data.formRefs[key];
        await ref.current?.validateForm();
    }
}
