import React from "react";
import clsx from "clsx";
import { FormikProps } from "formik";

import {
    einRegex,
    FederalTaxType,
    formatEin,
    formatSSNAndItin,
    IBusinessStep,
    itinRegex,
    ssnRegex,
} from "features/application-wizard";
import { RadioField, TextField } from "features/common";
import { einFormat, ssnAndItinFormat } from "features/utils";
import { useTranslation } from "i18nConfig";

import styles from "../../BusinessInformationForm/BusinessInformationForm.module.scss";

type FederalTaxSubType = Pick<IBusinessStep, "federalTaxType" | "federalTaxId">;

interface IFederalTaxProps<T> {
    formik: T extends FederalTaxSubType ? FormikProps<T> : never;
    formRef: React.MutableRefObject<FormikProps<T> | null>;
    setCurrentFederalTaxType: (federalTaxType: FederalTaxType) => void;
    setFederalTaxSubmitValue: (federalTaxSubmitValue: string) => void;
    federalType?: FederalTaxType;
}

export const FederalTax = <T extends FederalTaxSubType>(
    props: IFederalTaxProps<T>,
) => {
    const {
        formik,
        formRef,
        setCurrentFederalTaxType,
        setFederalTaxSubmitValue,
        federalType,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <div className={styles.BusinessInformationForm_radioSectionTaxId}>
                <RadioField
                    formik={formik}
                    className={clsx(
                        styles.BusinessInformationForm_radio,
                        styles.BusinessInformationForm_radioSpacing,
                    )}
                    name="federalTaxType"
                    label={t("legalBusinessEin")}
                    checked={
                        formik.values.federalTaxType === FederalTaxType.Ein
                    }
                    onChange={() => {
                        formRef.current?.setFieldValue(
                            "federalTaxType",
                            FederalTaxType.Ein,
                        );
                        formik.setFieldValue("federalTaxId", "");
                        setCurrentFederalTaxType(FederalTaxType.Ein);
                    }}
                />
                <RadioField
                    formik={formik}
                    className={clsx(
                        styles.BusinessInformationForm_radio,
                        styles.BusinessInformationForm_radioSpacing,
                    )}
                    name="federalTaxType"
                    label={t("legalBusinessSsn")}
                    checked={
                        formik.values.federalTaxType === FederalTaxType.Ssn
                    }
                    onChange={() => {
                        formRef.current?.setFieldValue(
                            "federalTaxType",
                            FederalTaxType.Ssn,
                        );
                        formik.setFieldValue("federalTaxId", "");
                        setCurrentFederalTaxType(FederalTaxType.Ssn);
                    }}
                />
                <RadioField
                    formik={formik}
                    className={clsx(
                        styles.BusinessInformationForm_radio,
                        styles.BusinessInformationForm_radioSpacing,
                    )}
                    name="federalTaxType"
                    label={t("legalBusinessItin")}
                    checked={
                        formik.values.federalTaxType === FederalTaxType.Itin
                    }
                    onChange={() => {
                        formRef.current?.setFieldValue(
                            "federalTaxType",
                            FederalTaxType.Itin,
                        );
                        formik.setFieldValue("federalTaxId", "");
                        setCurrentFederalTaxType(FederalTaxType.Itin);
                    }}
                />
            </div>
            <div className={styles.BusinessInformationForm_row}>
                <TextField
                    dataDdPrivacy="mask-user-input"
                    className={styles.BusinessInformationForm_input}
                    formik={formik}
                    name="federalTaxId"
                    data-testid="federalTaxId"
                    placeholder={
                        formik.values.federalTaxType === FederalTaxType.Ein
                            ? "00-0000000"
                            : "000-00-0000"
                    }
                    label={t("einLabel")}
                    value={formik.values.federalTaxId}
                    isClearable={formik.values.federalTaxId.includes("*")}
                    inputMode="numeric"
                    onBlur={() => {
                        const federalTaxId = formik.values.federalTaxId;

                        if (federalTaxId.includes("*")) {
                            return;
                        }

                        setFederalTaxSubmitValue(federalTaxId);

                        switch (federalType || formik.values.federalTaxType) {
                            case FederalTaxType.Ssn: {
                                if (federalTaxId.match(ssnRegex)) {
                                    const formattedValue =
                                        formatSSNAndItin(federalTaxId);

                                    formik.setFieldValue(
                                        "federalTaxId",
                                        ssnAndItinFormat(formattedValue),
                                    );
                                }
                                break;
                            }
                            case FederalTaxType.Itin: {
                                if (federalTaxId.match(itinRegex)) {
                                    const formattedValue =
                                        formatSSNAndItin(federalTaxId);

                                    formik.setFieldValue(
                                        "federalTaxId",
                                        ssnAndItinFormat(formattedValue),
                                    );
                                }
                                break;
                            }
                            case FederalTaxType.Ein: {
                                if (federalTaxId.match(einRegex)) {
                                    const formattedValue =
                                        formatEin(federalTaxId);

                                    formik.setFieldValue(
                                        "federalTaxId",
                                        einFormat(formattedValue),
                                    );
                                }
                                break;
                            }
                        }
                    }}
                    onChange={(event) => {
                        const value = event.target?.value;
                        switch (federalType || formik.values.federalTaxType) {
                            case FederalTaxType.Ssn:
                            case FederalTaxType.Itin: {
                                formik.setFieldValue(
                                    "federalTaxId",
                                    ssnAndItinFormat(value),
                                );
                                break;
                            }
                            case FederalTaxType.Ein: {
                                formik.setFieldValue(
                                    "federalTaxId",
                                    einFormat(value),
                                );
                                break;
                            }
                        }
                        if (value.includes("*")) {
                            formik.setFieldValue("federalTaxId", "");
                        }
                    }}
                />
            </div>
        </>
    );
};
