import React, { SyntheticEvent } from "react";
import clsx from "clsx";
import { FormikProps } from "formik";

import { formatSSNAndItin, ssnRegex } from "features/application-wizard";
import {
    DateFormat,
    DateInputField,
    ISelectValue,
    NumberField,
    SelectField,
    TextField,
    ZipCodeField,
} from "features/common";
import { PhoneInputField } from "features/common";
import { CheckboxField } from "features/common/components/CheckboxField";
import { countriesAndStates, positionTitles } from "features/common/constants";
import { useCountrySelect } from "features/common/hooks";
import { ssnAndItinFormat } from "features/utils";
import { useTranslation } from "i18nConfig";

import { IApplicantStep } from "../ApplicantForm.types";

import styles from "./ApplicantItem.module.scss";

interface IApplicantItemProps {
    formik: FormikProps<IApplicantStep>;
    showOwnership?: boolean;
    showAuthorizedCheckbox?: boolean;
    setSubmitSSNValue: (submitSSNValue: string) => void;
}

export const ApplicantItem = ({
    formik,
    showOwnership,
    showAuthorizedCheckbox,
    setSubmitSSNValue,
}: IApplicantItemProps) => {
    const { t } = useTranslation();
    const [stateOptions, onCountryChange] = useCountrySelect({
        value: formik.values.applicant?.address?.country || "US",
        label: "",
    });

    return (
        <>
            <div className={clsx(styles.ApplicantItem_row)}>
                <TextField
                    className={clsx(styles.ApplicantItem_input)}
                    formik={formik}
                    name={
                        // @todo improve the typing below so that we don't need to cast
                        `applicant.contact.firstName` as keyof IApplicantStep
                        // `applicant.firstName` as keyof IApplicantStep
                    }
                    label={t("firstNameLabel")}
                    value={formik.values.applicant?.contact?.firstName}
                />
                <TextField
                    className={clsx(styles.ApplicantItem_input)}
                    formik={formik}
                    name={
                        // @todo improve the typing below so that we don't need to cast
                        `applicant.contact.lastName` as keyof IApplicantStep
                    }
                    label={t("lastNameLabel")}
                    value={formik.values.applicant?.contact?.lastName}
                />
            </div>
            <div className={clsx(styles.ApplicantItem_row)}>
                {showOwnership && (
                    <NumberField
                        className={clsx(styles.ApplicantItem_input)}
                        formik={formik}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `applicant.ownershipPercentage` as keyof IApplicantStep
                        }
                        label={t("ownershipPercentageLabel")}
                        value={formik.values.applicant?.ownershipPercentage}
                        placeholder="%"
                        allowNegative={false}
                        decimalScale={0}
                    />
                )}
                <SelectField
                    formik={formik}
                    className={clsx(styles.ApplicantItem_input)}
                    name={
                        // @todo improve the typing below so that we don't need to cast
                        `applicant.title` as keyof IApplicantStep
                    }
                    label={t("titleLabel")}
                    options={positionTitles}
                    value={formik.values.applicant?.title || ""}
                    onChange={(e: ISelectValue) => {
                        formik.setFieldValue(`applicant.title`, e.value);
                    }}
                    placeholder="Select title"
                />
            </div>
            <div className={clsx(styles.ApplicantItem_row)}>
                <TextField
                    dataDdPrivacy="mask-user-input"
                    className={clsx(styles.ApplicantItem_input)}
                    formik={formik}
                    name={
                        // @todo improve the typing below so that we don't need to cast
                        `applicant.ssn` as keyof IApplicantStep
                    }
                    label={t("ssnLabel")}
                    value={formik.values.applicant?.ssn}
                    placeholder="XXX-XX-XXXX"
                    onChange={(event) => {
                        const value = event.target?.value;
                        formik.setFieldValue(
                            `applicant.ssn`,
                            ssnAndItinFormat(value),
                        );
                        setSubmitSSNValue(value);
                        if (value.includes("*")) {
                            formik.setFieldValue("applicant.ssn", "");
                        }
                    }}
                    isClearable={formik.values.applicant.ssn.includes("*")}
                    onBlur={() => {
                        const formattedValueRegex = /^\*{3}-\*{2}-[0-9]{4}$/;
                        const ssn = formik.values.applicant.ssn;
                        if (!ssn.includes("*")) {
                            setSubmitSSNValue(ssn);
                        }

                        if (ssn.match(formattedValueRegex)) {
                            return;
                        }

                        if (ssn.match(ssnRegex)) {
                            const formattedSSN = formatSSNAndItin(ssn);

                            formik.setFieldValue(
                                `applicant.ssn`,
                                ssnAndItinFormat(formattedSSN),
                            );
                        }
                    }}
                    inputMode="numeric"
                />
                <DateInputField
                    dataDdPrivacy="mask-user-input"
                    className={clsx(styles.ApplicantItem_input)}
                    formik={formik}
                    name={`applicant.dob` as keyof IApplicantStep}
                    label={t("dateOfBirthLabel")}
                    value={formik.values.applicant?.dob}
                    onChange={(e) => {
                        formik.setFieldValue(`applicant.dob`, e.target.value);
                    }}
                    placeholder={DateFormat.MM_DD_YYYY}
                />
            </div>
            <div className={clsx(styles.ApplicantItem_row)}>
                <TextField
                    className={clsx(styles.ApplicantItem_input)}
                    formik={formik}
                    name={
                        // @todo improve the typing below so that we don't need to cast
                        `applicant.address.address1` as keyof IApplicantStep
                    }
                    label={t("address1Label")}
                    value={formik.values.applicant?.address?.address1}
                />
                <TextField
                    className={clsx(styles.ApplicantItem_input)}
                    formik={formik}
                    name={
                        // @todo improve the typing below so that we don't need to cast
                        `applicant.address.address2` as keyof IApplicantStep
                    }
                    label={t("address2Label")}
                    value={formik.values.applicant?.address?.address2 || ""}
                    primaryCondition={`(${t("optionalLabel")})`}
                />
            </div>
            <div className={clsx(styles.ApplicantItem_row)}>
                <div
                    className={clsx(
                        styles.ApplicantItem_row,
                        styles.ApplicantItem_row___subRow,
                        styles.ApplicantItem_row___fullWidthM,
                    )}
                >
                    <TextField
                        className={clsx(
                            styles.ApplicantItem_input,
                            styles.ApplicantItem_input___subRow,
                            styles.ApplicantItem_input___fullWidthM,
                        )}
                        formik={formik}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `applicant.address.city` as keyof IApplicantStep
                        }
                        label={t("cityLabel")}
                        value={formik.values.applicant?.address?.city}
                    />
                    <SelectField
                        formik={formik}
                        className={clsx(
                            styles.ApplicantItem_input,
                            styles.ApplicantItem_input___subRow,
                            styles.ApplicantItem_input___fullWidthM,
                        )}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `applicant.address.state` as keyof IApplicantStep
                        }
                        label={t("stateLabel")}
                        options={stateOptions}
                        value={formik.values.applicant?.address?.state || ""}
                        onChange={(e: ISelectValue) => {
                            formik.setFieldValue(`applicant.state`, e.value);
                        }}
                        placeholder={t("statePlaceholder")}
                    />
                </div>
                <div
                    className={clsx(
                        styles.ApplicantItem_row,
                        styles.ApplicantItem_row___smallSubRow,
                    )}
                >
                    <ZipCodeField
                        formik={formik}
                        className={clsx(
                            styles.ApplicantItem_input,
                            styles.ApplicantItem_input___subRow,
                        )}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `applicant.address.zip` as keyof IApplicantStep
                        }
                        label={t("zipLabel")}
                        value={formik.values.applicant?.address?.zip}
                    />
                    <SelectField
                        formik={formik}
                        className={clsx(
                            styles.ApplicantItem_input,
                            styles.ApplicantItem_input___subRow,
                        )}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `applicant.address.country` as keyof IApplicantStep
                        }
                        label={t("countryLabel")}
                        options={countriesAndStates}
                        value={
                            formik.values.applicant?.address?.country || "US"
                        }
                        onChange={(e: ISelectValue) => {
                            onCountryChange(e);
                            formik.setFieldValue(
                                `applicant.address.country`,
                                e.value,
                            );
                            if (
                                e.value !==
                                formik.values.applicant?.address?.country
                            ) {
                                formik.setFieldValue(
                                    `applicant.address.state`,
                                    "",
                                );
                            }
                        }}
                        isDisabled={true}
                    />
                </div>
            </div>
            <div className={clsx(styles.ApplicantItem_row)}>
                <TextField
                    className={clsx(styles.ApplicantItem_input)}
                    formik={formik}
                    name={
                        // @todo improve the typing below so that we don't need to cast
                        `applicant.contact.email` as keyof IApplicantStep
                    }
                    label={t("emailLabel")}
                    value={formik.values.applicant?.contact?.email}
                    inputMode="email"
                    autoCapitalize="off"
                />
                <PhoneInputField
                    className={clsx(styles.ApplicantItem_input)}
                    formik={formik}
                    name={
                        // @todo improve the typing below so that we don't need to cast
                        `applicant.contact.phone` as keyof IApplicantStep
                    }
                    label={t("phoneLabel")}
                    value={formik.values.applicant?.contact?.phone}
                />
            </div>
            <div className={clsx(styles.ApplicantItem_row)}>
                {showAuthorizedCheckbox && (
                    <CheckboxField
                        className="text-left text-sm pl-1 !pt-2 pb-3 [&>label]:items-start [&>label>.^CheckboxLabel_text]: pt-1"
                        formik={formik}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `applicant.isResponsibleParty` as keyof IApplicantStep
                        }
                        label={t("isResponsiblePartyLabelApplicant")}
                        value={
                            formik.values.applicant.isResponsibleParty
                                ? "yes"
                                : "no"
                        }
                        checked={!!formik.values.applicant.isResponsibleParty}
                        onChange={(
                            event: SyntheticEvent<HTMLInputElement, Event>,
                        ) =>
                            formik.setFieldValue(
                                `applicant.isResponsibleParty`,
                                event.currentTarget.checked,
                            )
                        }
                    />
                )}
            </div>
        </>
    );
};
