// builds a field which is not required if ownerStatus is not "owner".
// We only want to validate additionalOwners if they are visible.
import moment from "moment/moment";
import * as Yup from "yup";

import { itinOrSsnRegex } from "features/application-wizard";
import { DateFormat } from "features/common/types";
import {
    maxLengthValidation,
    validateEmail,
    validatePhone,
    validateZip,
} from "features/common/utils";

import { OwnerStatus } from "../ApplicantForm";
import {
    IOwnerType,
    IOwnerValidationSchema,
    ITestContextExtended,
} from "./owners.types";

export const formatSSNAndItin = (value: string) => {
    const numeric = value.replace(/\D/g, "");

    let masked = numeric.replace(/^(\d{3})(\d{2})/, "***-**-");

    masked = masked.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
    return masked;
};

export const formatEin = (value: string) => {
    const numeric = value.replace(/\D/g, "");

    let masked = numeric.replace(/^(\d{2})(\d{3})/, "**-***");

    masked = masked.replace(/(\d{4})/, "$1");
    return masked;
};

// schema for singer owner form

const resolveDeletedValidation = <T extends Yup.AnySchema>(schema: T) => {
    let validationSchema: Yup.AnySchema;
    switch (schema.type) {
        case "string":
            validationSchema = Yup.string();
            break;
        case "number":
            validationSchema = Yup.number();
            break;
        case "boolean":
            validationSchema = Yup.boolean();
            break;
        case "object":
            validationSchema = Yup.object();
            break;
        default:
            validationSchema = Yup.mixed();
            break;
    }

    return validationSchema.when("isDeleted", {
        is: true,
        then: validationSchema.notRequired(),
        otherwise: schema,
    });
};

export const ownerValidationSchema = ({
    t,
    percentageFromOtherStep,
    isAdditionalOwners,
    ownerStatus,
}: IOwnerValidationSchema) => {
    return Yup.object().shape({
        isDeleted: Yup.boolean().nullable(),
        contact: resolveDeletedValidation(
            Yup.object().shape({
                firstName: resolveDeletedValidation(
                    Yup.string()
                        .required(t("requiredValidationMessage"))
                        .trim()
                        .concat(maxLengthValidation(100, t)),
                ),
                lastName: resolveDeletedValidation(
                    Yup.string()
                        .required(t("requiredValidationMessage"))
                        .trim()
                        .concat(maxLengthValidation(100, t)),
                ),
                email: resolveDeletedValidation(
                    validateEmail(
                        t("invalidEmailValidationMessage"),
                        t("requiredValidationMessage"),
                    ).concat(maxLengthValidation(254, t)),
                ),
                phone: resolveDeletedValidation(
                    validatePhone(
                        t("invalidPhoneNumberValidationMessage"),
                        t("requiredValidationMessage"),
                        false,
                    ),
                ),
            }),
        ),
        ownershipPercentage: resolveDeletedValidation(
            Yup.number()
                .max(
                    100,
                    `${t(
                        "invalidValueCantBeGreaterThanValidationMessage",
                    )} 100%`,
                )
                .when([], {
                    is: () => ownerStatus === OwnerStatus.ResponsibleParty,
                    // 0% of ownership is acceptable for responsible party
                    then: (ownershipPercentage) => ownershipPercentage.min(0),
                    // must be >0% otherwise
                    otherwise: (ownershipPercentage) =>
                        ownershipPercentage.positive(
                            t("invalidValueMustBePositiveValidationMessage"),
                        ),
                })
                .when([], {
                    is: () => ownerStatus === OwnerStatus.PartOwner,
                    then: (ownershipPercentage) =>
                        ownershipPercentage.min(
                            25,
                            t("invalidValueMustBeGreatedThan25Message"),
                        ),
                })
                .when([], {
                    is: () => ownerStatus !== OwnerStatus.Owner,
                    then: Yup.number()
                        .typeError(t("requiredValidationMessage"))
                        .required(t("requiredValidationMessage")),
                })
                .when([], {
                    is: () => isAdditionalOwners,
                    then: Yup.number()
                        .min(25, t("invalidValueMustBeGreatedThan25Message"))
                        .typeError(t("requiredValidationMessage")),
                })
                .test({
                    name: "sum-of-ownership-percentage",
                    test: function (val, meta) {
                        const value: number = val || 0;
                        if (isAdditionalOwners) {
                            const { from } = meta as Yup.TestContext &
                                ITestContextExtended;
                            const totalOwnershipPercentage =
                                from[1].value.owners
                                    .filter((owner) => !owner.isDeleted)
                                    .reduce((acc: number, obj: IOwnerType) => {
                                        const percentage =
                                            obj.ownershipPercentage || 0;
                                        return acc + Number(percentage);
                                    }, percentageFromOtherStep);
                            return totalOwnershipPercentage > 100
                                ? this.createError({
                                      message:
                                          percentageFromOtherStep > 0
                                              ? t(
                                                    "invalidValueTotalOwnershipCantBeGreaterThan100OtherStep",
                                                    {
                                                        maxOwnershipAllowed:
                                                            100 -
                                                            totalOwnershipPercentage +
                                                            value,
                                                    },
                                                )
                                              : t(
                                                    "invalidValueTotalOwnershipCantBeGreaterThan100",
                                                ),
                                      path: meta.path,
                                  })
                                : true;
                        } else {
                            return percentageFromOtherStep + value > 100 &&
                                value <= 75
                                ? this.createError({
                                      message: t(
                                          "invalidValueTotalOwnershipCantBeGreaterThan100OtherStep",
                                          {
                                              maxOwnershipAllowed:
                                                  100 - percentageFromOtherStep,
                                          },
                                      ),
                                      path: meta.path,
                                  })
                                : true;
                        }
                    },
                }),
        ),
        title: resolveDeletedValidation(
            Yup.string().required(t("requiredValidationMessage")),
        ),
        ssn: resolveDeletedValidation(
            Yup.string()
                .required(t("requiredValidationMessage"))
                .matches(
                    itinOrSsnRegex,
                    t("invalidSSNNumberValidationMessage"),
                ),
        ),
        dob: resolveDeletedValidation(
            Yup.string()
                .nullable()
                .test("valid", t("invalidDateValidationMessage"), (val) => {
                    return moment(val, DateFormat.MM_DD_YYYY, true).isValid();
                })
                .test("length", t("invalidDateValidationMessage"), (val) => {
                    const valueWithoutMask = val?.replace(/[-._/]/g, "").length;
                    return valueWithoutMask === 8;
                })
                .test(
                    "DOB",
                    t("invalidBirthDateTooYoungValidationMessage"),
                    (value) => {
                        return (
                            moment().diff(
                                moment(value, DateFormat.MM_DD_YYYY),
                                "years",
                            ) >= 18
                        );
                    },
                )
                .test(
                    "DOB",
                    t("invalidBirthDateTooOldValidationMessage"),
                    (value) => {
                        return (
                            moment().diff(
                                moment(value, DateFormat.MM_DD_YYYY),
                                "years",
                            ) < 120
                        );
                    },
                )
                .required(t("requiredValidationMessage")),
        ),
        address: resolveDeletedValidation(
            Yup.object().shape({
                address1: resolveDeletedValidation(
                    Yup.string()
                        .required(t("requiredValidationMessage"))
                        .trim()
                        .concat(maxLengthValidation(100, t)),
                ),
                address2: resolveDeletedValidation(
                    Yup.string().concat(maxLengthValidation(100, t)),
                ),
                city: resolveDeletedValidation(
                    Yup.string()
                        .required(t("requiredValidationMessage"))
                        .trim()
                        .concat(maxLengthValidation(100, t)),
                ),
                state: resolveDeletedValidation(
                    Yup.string().required(t("requiredValidationMessage")),
                ),
                zip: resolveDeletedValidation(
                    validateZip(
                        t("invalidZipValidationMessage"),
                        t("requiredValidationMessage"),
                    ),
                ),
                country: resolveDeletedValidation(
                    Yup.string().required(t("requiredValidationMessage")),
                ),
            }),
        ),
    });
};
