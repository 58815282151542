/**
 * Serialize backend response
 *
 * This is for use for converting/transforming backend responses that are in
 * `snake_case` to `camelCase` and vice versa.
 *
 * NOTE: the type of object will have to be determined explicitly, this just
 * transforms any type of object.
 *
 * @example
 *
 * // An Axios request function to transform (serialize) the data
 * //
 * // data comes in as `snake_case` and we want it in `camelCase` and any data
 * // sent back to the server should be converted from `camelCase` back into
 * // `snake_case`.
 * function transformData(merchantId: string): AxiosRequestConfig {
 *      return {
 *          transformResponse: [
 *              (data: any): any => {
 *                  return serializeToCamelCase(JSON.parse(data));
 *              },
 *          ],
 *          transformRequest: [
 *              (data: any): any => {
 *                  return JSON.stringify(serializeToSnakeCase(data));
 *              },
 *          ],
 *      };
 * }
 *
 * then in your actual axios call
 *
 * // My api request
 * return axios
 *      .post<IDeliveryServiceEstimate>(
 *          '/v1/my/api',
 *          transformData(),
 *       );
 */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import camelCase from "lodash.camelcase";
import cloneDeep from "lodash.clonedeep";
import snakeCase from "lodash.snakecase";

/**
 * Recursively modify keys in an object
 *  @param {Object|Array} item - an object or an array to modify
 *  @param {Function} func - a function that modifys the key
 *  @returns {Object|Array} an object with the modified keys
 *
 * WARNING: modifies the object
 */
function modifyKeys(item: any, modifier: any): any {
    if (Array.isArray(item)) {
        return item.map((value) => modifyKeys(value, modifier));
    } else if (
        item !== null &&
        item !== undefined &&
        item.constructor === Object
    ) {
        return Object.keys(item).reduce((acc, key) => {
            return {
                ...acc,
                [modifier(key)]: modifyKeys(item[key], modifier),
            };
        }, {});
    }

    return item;
}

/**
 * Convert an objects keys to camelCase
 *  @param {Object|Array} unserializedObject - an object to convert
 *  @returns {Object|Array} returns a copy of the unserialized object with keys
 *   in camel case
 */
export function serializeToCamelCase(unserializedObject: any): any {
    const object = cloneDeep(unserializedObject);
    return modifyKeys(object, camelCase);
}

/**
 * Convert an objects keys to snake_case
 *  @param {Object|Array} serializedObject - an object to convert
 *  @returns {Object|Array} returns a copy of the deserialized object with keys
 *   in snake case
 */
export function serializeToSnakeCase(serializedObject: any): any {
    // Exceptions to the rule
    function convertToSnakeCase(item: string): string {
        const custom: { [key: string]: string } = {};

        if (custom[item] !== undefined) {
            return custom[item];
        }

        return snakeCase(item);
    }

    const object = cloneDeep(serializedObject);
    return modifyKeys(object, convertToSnakeCase);
}

/**
 * Convert a number to money format
 *  @param {number} value - a number to format
 *  @param {string} placeholder - placeholder for null/undefined values,
 *   defaults to empty string
 *  @returns {string} returns a string with the money format defined by
 *   toLocaleString rounded to 2 decimal places
 */
export function moneyFormat(
    value: number | null | undefined,
    placeholder = "",
) {
    if (value === null || value === undefined) return placeholder;
    return `$${value.toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
}

export function percentFormat(value: number, placeholder = "") {
    if (value === null || value === undefined) return placeholder;
    return `${value.toLocaleString("en-US", { minimumFractionDigits: 2 })}%`;
}

/**
 * Convert a string to EIN format
 *  @param {string} value - a string to format
 *  @returns {string} returns a string with XX-XXXXXXX format
 */
export const einFormat = (value: string) => {
    const numbersOnly = value.replace(/[^0-9*]/g, "");
    const stripped = numbersOnly.trim().split("-").join("");
    const firstChunk = stripped.slice(0, 2);
    const secondChunk = stripped.slice(2, 9);
    return [firstChunk, secondChunk].filter((chunk) => !!chunk).join("-");
};

/**
 * Convert a string to SSN format
 *  @param {string} value - a string to format
 *  @returns {string} returns a string with XXX-XX-XXXX format
 */
export const ssnAndItinFormat = (value: string) => {
    const numbersOnly = value.replace(/[^0-9*]/g, "");
    const stripped = numbersOnly.trim().split("-").join("");
    const firstChunk = stripped.slice(0, 3);
    const secondChunk = stripped.slice(3, 5);
    const thirdChunk = stripped.slice(5, 9);
    return [firstChunk, secondChunk, thirdChunk]
        .filter((chunk) => !!chunk)
        .join("-");
};
