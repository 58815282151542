/* eslint-disable @typescript-eslint/naming-convention */
import { AxiosError, AxiosInstance } from "axios";
import { StatusCodes } from "http-status-codes";

import { TranslationKeys } from "i18nConfig";
import { t } from "i18nConfig/i18n.config";

import { displayErrorCard } from "../axiosDisplayErrorCard.utils";
import { ErrorHandlerRegistry } from "./axiosErrorRegistry";

export const defaultErrorRegistry = new ErrorHandlerRegistry(undefined, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "Network Error": async (error, axiosInstance) =>
        axiosInstance &&
        displayErrorCard(error, axiosInstance, {
            allowRetry: false,
            variant: "danger",
            content: t("oopsNetworkError"),
        }),
});

const onError = <T>(
    error: AxiosError<T>,
    axios: AxiosInstance,
    allowRetry: boolean,
) => {
    return displayErrorCard(error, axios, {
        allowRetry,
        variant: "danger",
        content: t("oopsErrorText"),
    });
};

const onValidationError = <T>(
    error: AxiosError<T>,
    axios: AxiosInstance,
    allowRetry: boolean,
) => {
    const fields = error.response?.data as
        | Record<string, Array<string>>
        | unknown;
    if (fields && typeof fields === "object") {
        Object.entries(fields).forEach(async ([key, message]) => {
            displayErrorCard(error, axios, {
                allowRetry,
                variant: "danger",
                content: `${t(key as TranslationKeys)}: ${message.join(", ")}`,
            });
        });
    }
};

export const coreErrorRegistry = new ErrorHandlerRegistry(
    defaultErrorRegistry,
    {
        all: async (e, axios) => axios && onError(e, axios, false),
        [StatusCodes.BAD_REQUEST]: async (e, axios) =>
            axios && onValidationError(e, axios, false),
        [StatusCodes.UNAUTHORIZED]: async (e) => Promise.reject(e),
    },
);
