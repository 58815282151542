import {
    IBusinessStep,
    IOwnerType,
    IShippingStep,
} from "features/application-wizard";
import { StepName, StepStatus } from "features/common";
import {
    API_ENDPOINTS,
    convertPhoneNumberToUsFormat,
    httpCore,
} from "features/utils";

import { IAppStateType } from "../AppStore.types";
import { defaultReducer, defaultShippingStep } from "../defaultState";
import { getStepperState } from "../helpers";

const prepareShippingData = (
    data: IShippingStep,
    applicant: IOwnerType,
    businessStep: IBusinessStep,
) => {
    if (data.isShippingSameAsBusiness) {
        const { address1, address2, city, state, zip, country } =
            businessStep.businessAddress;
        data.shippingAddress = {
            ...data.shippingAddress,
            address1,
            address2,
            city,
            state,
            zip,
            country,
        };
    }
    if ("installationContact" in data) {
        data.installationContact = {
            ...data.installationContact,
            phone:
                convertPhoneNumberToUsFormat(data.installationContact.phone) ||
                "",
        };
    }
    /**
     * We provide additionalTaxes via separate API call
     */
    data.additionalTaxes = [];
    // provide max five points after decimal of sales tax rate
    if (data.salesTaxRate !== undefined) {
        data.salesTaxRate = Number(data.salesTaxRate.toFixed(5));
    }
    return data;
};

export const shippingStepReducers = {};

export const shippingStepAsyncReducers = {
    async getShippingStep(currentState: IAppStateType) {
        const response = await httpCore.get(
            API_ENDPOINTS.signup.ShippingInfoStep,
        );
        if (response.status !== 200) return defaultReducer;

        const { installationTimeframe } = response.data;
        const defaultValues = defaultShippingStep();

        return (state: IAppStateType) => ({
            ...state,
            shippingStep: {
                ...state.shippingStep,
                ...response.data,
                installationTimeframe:
                    installationTimeframe ||
                    defaultValues.installationTimeframe,
            },
        });
    },

    async updateShippingStep(currentState: IAppStateType, data: IShippingStep) {
        const preparedData = prepareShippingData(
            data,
            currentState.applicantStep.applicant,
            currentState.businessStep,
        );
        try {
            const response = await httpCore.patch(
                API_ENDPOINTS.signup.ShippingInfoStep,
                {
                    ...preparedData,
                    stepName: StepName.Shipping,
                    stepStatus: StepStatus.Complete,
                },
            );
            const { stepper, ...shippingData } = response.data;

            return (state: IAppStateType) => ({
                ...state,
                ...getStepperState({ state, stepper }),
                shippingStep: {
                    ...state.shippingStep,
                    ...shippingData,
                },
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
};
