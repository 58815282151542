import React, { ReactNode } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { LoadingOverlay } from "spoton-lib";

type LoaderFallbackProps = {
    children: ReactNode;
    promiseKey: string;
};

export const LoaderFallback = (props: LoaderFallbackProps) => {
    const { children, promiseKey } = props;
    const data = useLoaderData() as { [key: string]: unknown };

    return (
        <React.Suspense fallback={<LoadingOverlay />}>
            <Await resolve={data ? data[promiseKey] : null}>{children}</Await>
        </React.Suspense>
    );
};
