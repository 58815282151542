import { IFormProps } from "features/application-wizard";

export enum FederalTaxType {
    Ein = "EIN",
    Ssn = "SSN",
    Itin = "ITIN",
}

export interface ILegalBusinessForm extends IFormProps {
    federalType?: FederalTaxType;
}

export interface ILegalForm {
    businessName: string;
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
}
