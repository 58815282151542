/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from "react";
import { FileRejection } from "react-dropzone";
import clsx from "clsx";
import { LoadingIndicator } from "spoton-lib";

import { acceptedFileTypes } from "features/utils";
import { useTranslation } from "i18nConfig";

import { ErrorCodes, FileUploadProps } from "./FileUpload.types";
import { convertToCamelCase, truncateString } from "./FileUpload.utils";
import {
    FileUploadDropzone,
    FileUploadPreview,
} from "./FileUploadSubcomponents.component";

const MAX_SIZE = 10 * 1024 * 1024;
const MAX_FILE_NAME_LENGTH = 100;

const errorHierarchy = [
    ErrorCodes.FileInvalidType,
    ErrorCodes.FileTooLarge,
    ErrorCodes.FileNameTooLong,
];

export const FileUpload = (props: FileUploadProps) => {
    const {
        file,
        onDelete,
        onChange,
        description,
        className,
        maxFileNameLength = MAX_FILE_NAME_LENGTH,
        maxSize = MAX_SIZE,
        accept = acceptedFileTypes.reduce(
            (acc, type) => ({ ...acc, [type]: [] }),
            {},
        ),
        isLoading,
    } = props;

    const { t } = useTranslation();
    const [filesRejected, setFilesRejected] = useState<FileRejection[]>([]);

    return (
        <div
            className={clsx(
                "h-full",
                "w-full",
                "flex",
                "items-start",
                "rounded",
                "gap-6",
                "flex-col",
                "tablet:flex-row",
                className,
            )}
        >
            <div
                className={clsx(
                    { "bg-dashed": !file && !filesRejected.length },
                    { "bg-dashed-error": !file && filesRejected.length },
                    { "border border-base-base-20": file },
                    {
                        "h-[148px] tablet:max-w-[172px] tablet:h-[148px]":
                            !!description,
                    },
                    { "h-full": !description },
                    "w-full",
                    "flex",
                    "items-center",
                    "rounded",
                    "order-2 tablet:order-1",
                    "overflow-hidden",
                )}
            >
                {isLoading ? (
                    <div className="h-full w-full flex items-center justify-center bg-gray-300 rounded">
                        <LoadingIndicator
                            size="sm"
                            className="h-full [&>.lf-player-container]:h-full [&>.lf-player-container>#lottie]:h-full"
                        />
                    </div>
                ) : (
                    <FileUploadDropzone
                        file={file}
                        onChange={onChange}
                        dropzoneProps={{
                            maxSize,
                            accept,
                            validator: (file) => {
                                if (file.name?.length > maxFileNameLength) {
                                    return {
                                        code: ErrorCodes.FileNameTooLong,
                                        message: "",
                                    };
                                }
                                return null;
                            },
                            onDropRejected: setFilesRejected,
                            onDropAccepted: () => setFilesRejected([]),
                        }}
                        isDescription={!!description}
                        renderPreview={({ open, file }) => (
                            <FileUploadPreview
                                isDescription={!!description}
                                onEdit={() => open()}
                                onDelete={onDelete}
                                file={file}
                            />
                        )}
                    />
                )}
            </div>
            <div className="order-1 tablet:order-2 tablet:w-auto w-full flex flex-col gap-2">
                {description}
                <span className="w-full flex justify-start flex-col">
                    {!!filesRejected.length &&
                        filesRejected[0].errors
                            .sort(
                                (a, b) =>
                                    errorHierarchy.indexOf(
                                        a.code as ErrorCodes,
                                    ) -
                                    errorHierarchy.indexOf(
                                        b.code as ErrorCodes,
                                    ),
                            )
                            .map((error) => (
                                <span
                                    key={error.code}
                                    className="text-danger-danger-70 text-xs text-left"
                                >
                                    {t(convertToCamelCase(error.code), {
                                        fileName: truncateString(
                                            filesRejected[0].file.name,
                                            20,
                                        ),
                                        maxFileNameLength: maxFileNameLength,
                                        maxSize: maxSize / 1024 / 1024,
                                        defaultValue: "",
                                    })}
                                </span>
                            ))}
                </span>
            </div>
        </div>
    );
};
