import React, { useState } from "react";
import clsx from "clsx";

import photo1 from "./images/022223__CasualDining_6133_with-screen.webp";
import photo2 from "./images/022223__CounterService_3333.webp";
import photo3 from "./images/IMG_5043_with-screen.webp";

import styles from "./FasterFundingImages.module.scss";

interface IImageProps {
    src: string;
    alt: string;
    className: string;
}

const Image = ({
    src,
    alt,
    className,
    ...restProps
}: IImageProps & Record<string, unknown>) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    return (
        <img
            src={src}
            alt={alt}
            className={className}
            style={{ display: isLoaded ? "block" : "none" }}
            onLoad={handleImageLoad}
            {...restProps}
        />
    );
};

export const FasterFundingImages = () => {
    return (
        <div className={styles.ImagesWrapper}>
            <div className={styles.ImagesWrapper_verticalImages}>
                <Image
                    src={photo1}
                    className={clsx(styles.ImageLeftTop)}
                    alt="photo1"
                />
                <Image
                    src={photo2}
                    className={clsx(styles.ImageLeftBottom)}
                    alt="photo2"
                />
            </div>
            <Image
                src={photo3}
                className={clsx(styles.ImageRight)}
                alt="photo3"
            />
        </div>
    );
};
