/* eslint-disable @typescript-eslint/naming-convention */
import { AxiosResponse } from "axios";
import { showToast } from "spoton-lib";

import { t } from "i18nConfig/i18n.config";

export const onFileChangeSuccess = <T>(value: AxiosResponse<T>) => {
    switch (value.config.method) {
        case "post":
        case "POST":
            showToast({
                title: t("fileUploadSuccessMessageTitle"),
                variant: "success",
                content: t("fileUploadSuccessMessageContent"),
            });
            break;
        case "delete":
        case "DELETE":
            showToast({
                title: t("fileDeleteSuccessMessageTitle"),
                variant: "success",
                content: t("fileDeleteSuccessMessageContent"),
            });
            break;
        case "get":
            break;
        default:
            showToast({
                title: t("fileUploadSuccessMessageTitle"),
                variant: "success",
                content: t("fileUploadSuccessMessageContent"),
            });
    }
    return value;
};
