/* eslint-disable @typescript-eslint/naming-convention */
import { IProgressBlock } from "app";
import {
    IApplicantStep,
    IBillingStep,
    IBusinessStep,
    IFasterFundingStep,
    IOwnersStep,
    IPlatformStep,
    IShippingStep,
    ISignatureStep,
    ITermsAndConditionsStep,
} from "features/application-wizard";
import { IProfileType } from "features/auth";
import { InactiveStepName, StepName, StepStatus } from "features/common";

export type IGenericStep<IsActive = true> = {
    stepName: IsActive extends true ? StepName : InactiveStepName;
    stepStatus: StepStatus;
};

export enum BusinessRebookAction {
    OwnershipChange = "ownership_change",
    StructureChanged = "structure_change",
}

export type BusinessRebookActionType = BusinessRebookAction | "";

export enum OpportunityType {
    Payments = "payments",
    Restaurant = "restaurant",
    Software = "software",
}

// Config type is global and should be available here
export interface IConfigType {
    isConfigLoaded: boolean;
    opportunityType?: OpportunityType;
    opportunityId?: string;
    isPayments: boolean | undefined;
    isRestaurant: boolean | undefined;
    isSubmitted: boolean;
    businessName: string;
    businessRebookAction: BusinessRebookActionType;
    clickToSign: boolean;
}

export interface IAppStateType {
    config: IConfigType;
    profile: IProfileType;
    progressBlock: IProgressBlock;
    platformStep: IPlatformStep;
    businessStep: IBusinessStep;
    shippingStep: IShippingStep;
    almostDoneStep: IGenericStep;
    billingStep: IBillingStep;
    applicantStep: IApplicantStep;
    ownersStep: IOwnersStep;
    signatureStep: ISignatureStep;
    fasterFundingStep: IFasterFundingStep;
    termsAndConditionsStep: ITermsAndConditionsStep;
}

export enum BillingViews {
    Root = "root",
    Photo = "photo",
}
