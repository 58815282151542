import React, { SyntheticEvent, useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import { FormikProps, useField } from "formik";
import { colors, Icon, IconButton, Text } from "spoton-lib";

import { formatSSNAndItin, ssnRegex } from "features/application-wizard";
import {
    DateFormat,
    DateInputField,
    ISelectValue,
    NumberField,
    SelectField,
    TextField,
    ZipCodeField,
} from "features/common";
import { PhoneInputField } from "features/common";
import { CheckboxField } from "features/common/components/CheckboxField";
import { countriesAndStates, positionTitles } from "features/common/constants";
import { useCountrySelect } from "features/common/hooks";
import { ssnAndItinFormat } from "features/utils";
import { useTranslation } from "i18nConfig";

import { IOwnersStep, ISubmitValues } from "../OwnersForm.types";

import styles from "./OwnerItem.module.scss";

interface IOwnerItemProps {
    i: number;
    sequenceNumber: number;
    formik: FormikProps<IOwnersStep>;
    onRemoveOwner: (index: number) => void;
    isDeletable: boolean;
    ssnSubmitValues: ISubmitValues[];
    setSsnSubmitValues: (item: ISubmitValues[]) => void;
}

export const OwnerItem = ({
    i,
    sequenceNumber,
    formik,
    onRemoveOwner,
    isDeletable,
    ssnSubmitValues,
    setSsnSubmitValues,
}: IOwnerItemProps) => {
    const { t } = useTranslation();
    const [stateOptions, onCountryChange] = useCountrySelect({
        value: formik.values.owners[i]?.address?.country || "US",
        label: "",
    });
    const [isOpen, setIsOpen] = useState(true);
    const [ownershipPercentageField, , ownershipPercentageHelpers] = useField(
        `owners[${i}].ownershipPercentage`,
    );

    const onClick = (): void => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (ownershipPercentageField.value)
            ownershipPercentageHelpers.setTouched(true);
    }, []);

    const getOwnerLabel = () => {
        const owner = formik.values?.owners[i]?.contact;

        if (owner) {
            const { firstName, lastName } = owner;
            const firstAndLastName = `${firstName || ""} ${
                lastName || ""
            }`.trim();
            return `${t("ownerSubTitle")} ${sequenceNumber.toString()}${
                firstAndLastName ? ` (${firstAndLastName})` : ""
            }`;
        }
    };

    const renderOwnerItemForm = () => {
        return (
            <>
                <div className={clsx(styles.OwnerItem_row)}>
                    <TextField
                        className={clsx(styles.OwnerItem_input)}
                        formik={formik}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `owners[${i}].contact.firstName` as keyof IOwnersStep
                            // `applicant.firstName` as keyof IApplicantStep
                        }
                        label={t("firstNameLabel")}
                        value={formik.values.owners[i]?.contact?.firstName}
                    />
                    <TextField
                        className={clsx(styles.OwnerItem_input)}
                        formik={formik}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `owners[${i}].contact.lastName` as keyof IOwnersStep
                        }
                        label={t("lastNameLabel")}
                        value={formik.values.owners[i]?.contact?.lastName}
                    />
                </div>
                <div className={clsx(styles.OwnerItem_row)}>
                    <NumberField
                        className={clsx(styles.OwnerItem_input)}
                        formik={formik}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `owners[${i}].ownershipPercentage` as keyof IOwnersStep
                        }
                        label={t("ownershipPercentageLabel")}
                        value={formik.values.owners[i]?.ownershipPercentage}
                        placeholder="%"
                        allowNegative={false}
                        decimalScale={0}
                    />
                    <SelectField
                        formik={formik}
                        className={clsx(styles.OwnerItem_input)}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `owners[${i}].title` as keyof IOwnersStep
                        }
                        label={t("titleLabel")}
                        options={positionTitles}
                        value={formik.values.owners[i]?.title || ""}
                        onChange={(e: ISelectValue) => {
                            formik.setFieldValue(`owners[${i}].title`, e.value);
                        }}
                        placeholder="Select title"
                    />
                </div>
                <div className={clsx(styles.OwnerItem_row)}>
                    <TextField
                        dataDdPrivacy="mask-user-input"
                        className={clsx(styles.OwnerItem_input)}
                        formik={formik}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `owners[${i}].ssn` as keyof IOwnersStep
                        }
                        label={t("ssnLabel")}
                        value={formik.values.owners[i]?.ssn}
                        isClearable={formik.values.owners[i]?.ssn.includes("*")}
                        placeholder="XXX-XX-XXXX"
                        onChange={(event) => {
                            const value = event.target?.value;
                            formik.setFieldValue(
                                `owners[${i}].ssn`,
                                ssnAndItinFormat(value),
                            );
                            if (value.includes("*")) {
                                formik.setFieldValue(`owners[${i}].ssn`, "");
                            }
                        }}
                        onBlur={() => {
                            const formattedValueRegex =
                                /^\*{3}-\*{2}-[0-9]{4}$/;
                            const ssn = formik.values.owners[i].ssn;
                            const submitValues: ISubmitValues[] = [
                                ...ssnSubmitValues,
                                { value: ssn, index: i },
                            ];
                            setSsnSubmitValues(
                                submitValues.filter(
                                    (item) => !item.value.includes("*"),
                                ),
                            );

                            if (ssn.match(formattedValueRegex)) {
                                return;
                            }

                            if (ssn.match(ssnRegex)) {
                                const formattedSSN = formatSSNAndItin(ssn);

                                formik.setFieldValue(
                                    `owners[${i}].ssn`,
                                    ssnAndItinFormat(formattedSSN),
                                );
                            }
                        }}
                        inputMode="numeric"
                    />
                    <DateInputField
                        dataDdPrivacy="mask-user-input"
                        className={clsx(styles.OwnerItem_input)}
                        formik={formik}
                        name={`owners[${i}].dob` as keyof IOwnersStep}
                        label={t("dateOfBirthLabel")}
                        value={formik.values.owners[i]?.dob}
                        onChange={(e) => {
                            formik.setFieldValue(
                                `owners[${i}].dob`,
                                e.target.value,
                            );
                        }}
                        placeholder={DateFormat.MM_DD_YYYY}
                    />
                </div>
                <div className={clsx(styles.OwnerItem_row)}>
                    <TextField
                        className={clsx(styles.OwnerItem_input)}
                        formik={formik}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `owners[${i}].address.address1` as keyof IOwnersStep
                        }
                        label={t("address1Label")}
                        value={formik.values.owners[i]?.address?.address1}
                    />
                    <TextField
                        className={clsx(styles.OwnerItem_input)}
                        formik={formik}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `owners[${i}].address.address2` as keyof IOwnersStep
                        }
                        label={t("address2Label")}
                        value={formik.values.owners[i]?.address?.address2 || ""}
                        primaryCondition={`(${t("optionalLabel")})`}
                    />
                </div>
                <div className={clsx(styles.OwnerItem_row)}>
                    <div
                        className={clsx(
                            styles.OwnerItem_row,
                            styles.OwnerItem_row___subRow,
                            styles.OwnerItem_row___fullWidthM,
                        )}
                    >
                        <TextField
                            className={clsx(
                                styles.OwnerItem_input,
                                styles.OwnerItem_input___subRow,
                                styles.OwnerItem_input___fullWidthM,
                            )}
                            formik={formik}
                            name={
                                // @todo improve the typing below so that we don't need to cast
                                `owners[${i}].address.city` as keyof IOwnersStep
                            }
                            label={t("cityLabel")}
                            value={formik.values.owners[i]?.address?.city}
                        />
                        <SelectField
                            formik={formik}
                            className={clsx(
                                styles.OwnerItem_input,
                                styles.OwnerItem_input___subRow,
                                styles.OwnerItem_input___fullWidthM,
                            )}
                            name={
                                // @todo improve the typing below so that we don't need to cast
                                `owners[${i}].address.state` as keyof IOwnersStep
                            }
                            label={t("stateLabel")}
                            options={stateOptions}
                            value={
                                formik.values.owners[i]?.address?.state || ""
                            }
                            onChange={(e: ISelectValue) => {
                                formik.setFieldValue(
                                    `owners[${i}].address.state`,
                                    e.value,
                                );
                            }}
                            placeholder={t("statePlaceholder")}
                        />
                    </div>
                    <div
                        className={clsx(
                            styles.OwnerItem_row,
                            styles.OwnerItem_row___smallSubRow,
                        )}
                    >
                        <ZipCodeField
                            formik={formik}
                            className={clsx(
                                styles.OwnerItem_input,
                                styles.OwnerItem_input___subRow,
                            )}
                            name={
                                // @todo improve the typing below so that we don't need to cast
                                `owners[${i}].address.zip` as keyof IOwnersStep
                            }
                            label={t("zipLabel")}
                            value={formik.values.owners[i]?.address?.zip}
                        />
                        <SelectField
                            formik={formik}
                            className={clsx(
                                styles.OwnerItem_input,
                                styles.OwnerItem_input___subRow,
                            )}
                            name={
                                // @todo improve the typing below so that we don't need to cast
                                `owners[${i}].address.country` as keyof IOwnersStep
                            }
                            label={t("countryLabel")}
                            options={countriesAndStates}
                            value={
                                formik.values.owners[i]?.address?.country ||
                                "US"
                            }
                            onChange={(e: ISelectValue) => {
                                onCountryChange(e);
                                formik.setFieldValue(
                                    `owners[${i}].address.country`,
                                    e.value,
                                );
                                if (
                                    e.value !==
                                    formik.values.owners[i]?.address?.country
                                ) {
                                    formik.setFieldValue(
                                        `owners[${i}].address.state`,
                                        "",
                                    );
                                }
                            }}
                            isDisabled={true}
                        />
                    </div>
                </div>
                <div className={clsx(styles.OwnerItem_row)}>
                    <TextField
                        className={clsx(styles.OwnerItem_input)}
                        formik={formik}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `owners[${i}].contact.email` as keyof IOwnersStep
                        }
                        label={t("emailLabel")}
                        value={formik.values.owners[i]?.contact?.email}
                        inputMode="email"
                        autoCapitalize="off"
                    />
                    <PhoneInputField
                        className={clsx(styles.OwnerItem_input)}
                        formik={formik}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `owners[${i}].contact.phone` as keyof IOwnersStep
                        }
                        label={t("phoneLabel")}
                        value={formik.values.owners[i]?.contact?.phone}
                    />
                </div>
                <div className={clsx(styles.OwnerItem_row)}>
                    <CheckboxField
                        className="text-left text-sm pl-1 !pt-2 pb-3 [&>label]:items-start [&>label>.^CheckboxLabel_text]: pt-1"
                        formik={formik}
                        name={
                            // @todo improve the typing below so that we don't need to cast
                            `owners[${i}].isResponsibleParty` as keyof IOwnersStep
                        }
                        label={t("isResponsiblePartyLabel")}
                        value={
                            formik.values.owners[i]?.isResponsibleParty
                                ? "yes"
                                : "no"
                        }
                        checked={!!formik.values.owners[i]?.isResponsibleParty}
                        onChange={(
                            event: SyntheticEvent<HTMLInputElement, Event>,
                        ) =>
                            formik.setFieldValue(
                                `owners[${i}].isResponsibleParty`,
                                event.currentTarget.checked,
                            )
                        }
                    />
                </div>
            </>
        );
    };

    return isMobile ? (
        <Collapsible
            open={isOpen}
            handleTriggerClick={onClick}
            transitionTime={250}
            className={
                sequenceNumber > 1 ? styles.OwnerItem_collapsibleItem : ""
            }
            openedClassName={
                sequenceNumber > 1 ? styles.OwnerItem_collapsibleItem : ""
            }
            data-testid={`collapse-owner-${i}-section`}
            trigger={
                <div>
                    <div className={styles.OwnerItem_collapsibleHeaderWrapper}>
                        <div
                            className={styles.OwnerItem_collapsibleHeaderTitle}
                        >
                            <Icon
                                data-testid={`collapse-owner-${i}-icon`}
                                className={clsx(
                                    styles.MobileTable_titleIcon,
                                    styles.Icon,
                                    { [styles.Icon_rotated]: !isOpen },
                                )}
                                name="ChevronUpIcon"
                                size={24}
                                color={colors.black}
                            />
                            <Text
                                type="p"
                                color={colors.primary80}
                                isBold
                                className={
                                    styles.OwnerItem_collapsibleHeaderTitleOwnerNo
                                }
                            >
                                {getOwnerLabel()}
                            </Text>
                        </div>
                        {isDeletable && (
                            <IconButton
                                alt="bin"
                                size={24}
                                disableBorder
                                variant="secondary"
                                name="DeleteIcon"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onRemoveOwner(i);
                                }}
                                buttonProps={{
                                    type: "button",
                                }}
                                selectedName="DeleteOwner"
                                color={colors.danger70}
                                data-testid={`remove-additional-owner-${i}`}
                            />
                        )}
                    </div>
                </div>
            }
        >
            {renderOwnerItemForm()}
        </Collapsible>
    ) : (
        <div key={`owners-form-${i}`} className={clsx(styles.SectionWrapper)}>
            <div className={styles.OwnerItem_headerWrapper}>
                <div className={styles.OwnerItem_headerTitle}>
                    <Text type="p" color={colors.gray90} isBold>
                        {t("ownerSubTitle")}
                        {` ${sequenceNumber}`}
                    </Text>
                </div>
                {isDeletable && (
                    <IconButton
                        className={styles.BillingView_removeLinkedAccountBtn}
                        alt="bin"
                        size={24}
                        disableBorder
                        variant="secondary"
                        name="DeleteIcon"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onRemoveOwner(i);
                        }}
                        buttonProps={{
                            type: "button",
                        }}
                        selectedName="DeleteOwner"
                        color={colors.danger70}
                        data-testid={`remove-additional-owner-${i}`}
                    />
                )}
            </div>
            {renderOwnerItemForm()}
        </div>
    );
};

export default OwnerItem;
