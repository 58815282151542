/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import clsx from "clsx";
import { useField } from "formik";
import { Checkbox, colors, Text } from "spoton-lib";

import { ICheckboxFieldProps } from "./CheckboxField.types";

import styles from "./CheckboxField.module.scss";

export function CheckboxField<T>(props: ICheckboxFieldProps<T>) {
    const {
        id,
        name,
        value,
        label,
        labelComponent,
        className,
        onChange,
        checked,
        size,
        formik: { setFieldTouched, setFieldValue },
        isDisabled = false,
        customContainerClassName,
    } = props;
    const [field, meta] = useField(name);

    return (
        <div
            className={clsx(
                customContainerClassName || styles.CheckboxField_container,
            )}
        >
            <Checkbox
                {...field}
                data-testid={id || `${name}-field`}
                className={className}
                disabled={isDisabled}
                value={value}
                label={labelComponent ? "" : label}
                onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    setFieldValue(name, target?.value);
                    setFieldTouched(name);
                    if (onChange) {
                        onChange(event);
                    }
                }}
                checked={checked}
                size={size || "small"}
            />
            <div className={clsx(styles.CheckboxField_labelAndError)}>
                {labelComponent}
                <div
                    className={clsx(
                        styles.CheckboxField_errorContainer,
                        customContainerClassName && "pt-0",
                    )}
                >
                    <Text type="p" color={colors.danger70}>
                        {(meta.touched && meta.error) || " "}
                    </Text>
                </div>
            </div>
        </div>
    );
}

export default CheckboxField;
